import React, { useContext, useEffect, useState } from "react";
import IisVisitingSatisfactionPanelComponent from "./iisVisitingSatisfactionPanel.component";
import { useDispatch, useSelector } from "react-redux";
import { DashboardContext } from "modules/dashboard/pages/dashboard/dashboard.container";
import moment from "moment";
import { addAlert } from "modules/notification";
import dashboardApi from "app/api/dashboard";
import { getApiLang } from "app/feature/constants";

function IisVisitingSatisfactionPanelContainer() {
  const { currentFilter, activeIndex } = useContext(DashboardContext);
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const [rank, setRank] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter.dateRange]);

  const getData = () => {
    setIsLoading(true);
    setIsError(false);

    let param = {
      start_date: moment(currentFilter.dateRange[0]).format("YYYY-MM-DD"),
      end_date: moment(currentFilter.dateRange[1]).format("YYYY-MM-DD"),
      is_today: activeIndex === 1 ? 1 : 0,
      is_month: activeIndex === 2 || activeIndex === 3 ? 1 : 0,
    };

    setIsLoading(false);
    dashboardApi
      .getIisVistingSatisfaction(param)
      .then((res) => {
        let data = res.data;
        if (data && Object.keys(data).length > 0) {
          setRank(data);
        } else {
          setRank(null);
        }
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <IisVisitingSatisfactionPanelComponent
      rank={rank}
      isLoading={isLoading}
      lang={lang}
      isError={isError}
      handleReload={getData}
      language={language}
    />
  );
}

export default IisVisitingSatisfactionPanelContainer;
