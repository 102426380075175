import React from "react";
import { IconButton, InputAdornment } from "@material-ui/core";
import ColorizeRoundedIcon from "@material-ui/icons/ColorizeRounded";
import InputTextField from "components/input/inputTextField";

export default function SingleColorPickerSelectComponent({
  handleClick,
  value,
  styles,
  inputStyles,
  placeholder,
}) {
  return (
    <InputTextField
      type="text"
      readOnly
      size="small"
      value={value}
      fullWidth
      margin="dense"
      variant="filled"
      placeholder={placeholder}
      InputProps={{
        disableUnderline: true,
        style: {
          padding: "5px",
          backgroundColor: "#ECEFF0",
          borderRadius: "4px",
          ...inputStyles,
        },
        endAdornment: (
          <InputAdornment position="end">
            {!!value && (
              <div
                style={{
                  width: 20,
                  height: 20,
                  minWidth: 20,
                  borderRadius: "50%",
                  backgroundColor: value,
                }}
              />
            )}

            <IconButton
              aria-label="picker-primary"
              edge="end"
              onClick={handleClick}
              style={{ paddingLeft: "8px" }}
              size="small"
            >
              <ColorizeRoundedIcon style={{ fontSize: 22 }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      style={styles}
    />
  );
}
