import React from "react";
import { Box, Paper, Typography, makeStyles, Divider } from "@material-ui/core";
import AntSwitch from "components/switch/switch";
import { Skeleton } from "@material-ui/lab";
import { getLang } from "app/feature/constants";
import { surveyQuestionType } from "modules/survey/constants";
import OptionSelect from "../../select/optionSelect";

const useStyle = makeStyles(() => ({
  container: {
    boxShadow:
      "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);",
    borderRadius: 6,
    padding: "12px",
  },
}));

export default function SelectedQuestionCardComponent({
  selectedQuestion,
  isShowAnswer,
  handleShowAnswer,
  isQuestionFetching,
  totalResponse,
  totalAnswer,
  totalSkip,
  lang,
  optionUuid,
  setOptionUuid,
}) {
  const classes = useStyle();

  let questionType = "";

  switch (selectedQuestion.type) {
    case surveyQuestionType.text:
      questionType = getLang(lang, "label.TEXT_FIELD");
      break;
    case surveyQuestionType.selection:
      questionType = getLang(lang, "label.MULTIPLE_CHOICE");
      break;
    case surveyQuestionType.dropdownlist:
      questionType = getLang(lang, "label.DROPDOWN_LIST");
      break;
    case surveyQuestionType.multipleChoiceMultiSelect:
      questionType = getLang(lang, "label.MULTIPLE_CHOICE_MULTI_SELECT");
      break;
    case surveyQuestionType.multiStarRating:
      questionType = getLang(lang, "label.MULTI_STAR_RATING");
      break;
    case surveyQuestionType.starRating:
      questionType = getLang(lang, "label.STAR_RATING");
      break;
    default:
      break;
  }

  return (
    <Paper className={classes.container} variant="outlined">
      <Box style={{ margin: "12px" }}>
        <Box>
          <Typography
            variant="body2"
            style={{ color: "#98A2B3", fontSize: 12 }}
          >
            {isQuestionFetching ? (
              <Skeleton variant="text" width={217} />
            ) : (
              `${getLang(lang, "label.QUESTION")} ${
                selectedQuestion.index
              } - ${questionType}`
            )}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body2"
            style={{ color: "#344054", fontSize: 16, fontWeight: 500 }}
          >
            {isQuestionFetching ? (
              <Skeleton variant="text" width={400} />
            ) : (
              selectedQuestion.question
            )}
          </Typography>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <Box display="flex">
              <Typography
                variant="caption"
                style={{ marginRight: 4 }}
                color="textSecondary"
              >
                {getLang(lang, "label.RESPONSES")}: {totalResponse}
              </Typography>
              <Typography
                variant="caption"
                style={{ marginRight: 4 }}
                color="textSecondary"
              >
                {getLang(lang, "label.ANSWER")}: {totalAnswer}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {getLang(lang, "label.SKIPPED")}: {totalSkip}
              </Typography>
            </Box>
            {totalResponse !== 0 && (
              <Box display="flex" alignItems="center">
                <AntSwitch
                  disabled={false}
                  checked={isShowAnswer}
                  onChange={(e) => handleShowAnswer(e.target.checked)}
                />
                <Typography variant="caption" style={{ marginLeft: 4 }}>
                  {getLang(lang, "label.SHOW_ANSWER_ONLY")}
                </Typography>
              </Box>
            )}
          </Box>
          <Divider />
          {selectedQuestion &&
            selectedQuestion.type === surveyQuestionType.multiStarRating && (
              <Box mt={2} display="flex" alignItems="center">
                <Box mr={2}>
                  <Typography variant="body2">
                    <b>{getLang(lang, "label.VIEW_BY_OPTION")}</b>
                  </Typography>
                </Box>
                <Box flex={1}>
                  <OptionSelect
                    textFieldProps={{
                      required: false,
                      variant: "filled",
                      size: "small",
                      margin: "dense",
                    }}
                    formProps={{
                      value: optionUuid
                        ? selectedQuestion.choices.find(
                            (choice) => choice.id === optionUuid
                          )
                        : "",
                      onChange: (e, newValue) => {
                        setOptionUuid(newValue.id);
                      },
                      disableClearable: true,
                      options: selectedQuestion.choices,
                    }}
                  />
                </Box>
              </Box>
            )}
        </Box>
      </Box>
    </Paper>
  );
}
