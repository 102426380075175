import React from "react";
import { makeStyles, Box } from "@material-ui/core";

const useStyle = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2, 2),
    borderRadius: 10,
    overflow: "hidden",
    minWidth: "fit-content",
    "& iframe": {
      border: "none",
      overflow: "hidden"
    },
    maxHeight: 640,
    padding: 0,
  }
}));

export default function YoutubeComponent({ url }) {
  const classes = useStyle();

  const autoplayUrl = new URL(url);
  autoplayUrl.searchParams.set('autoplay', '1');
  autoplayUrl.searchParams.set('mute', '1');

  const [iframeHeight, setIframeHeight] = React.useState("240px");

  React.useEffect(() => {
    const handleResize = () => {
      const width = document.getElementById("iframe-youtube").offsetWidth;
      const height = (width * 9) / 16; // 16:9 aspect ratio
      setIframeHeight(`${height}px`);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial call to set the height based on initial width

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box p={2} className={classes.root}>
      <iframe
        id="iframe-youtube"
        title="iframe-youtube"
        width={"100%"}
        height={iframeHeight}
        src={url}
        frameBorder={0}
        allow="autoplay; encrypted-media"
        style={{ minHeight: iframeHeight }}
      ></iframe>
    </Box>
  );
}
