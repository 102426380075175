import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import {
  SurveyQuestionListingCard,
  SelectedQuestionCard,
  SurveyAnswerCard,
  SurveyChoiceOverviewCard,
} from "../../card";
import { surveyQuestionType } from "modules/survey/constants";

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: "grid",
    gridGap: 16,
    gridTemplateColumns: "3fr 7fr",
  },
}));

export default function SurveyResultPanelComponent({
  data,
  selectedQuestion,
  handleSelectedQuestion,
  isShowAnswer,
  handleShowAnswer,
  isQuestionFetching,
  totalResponse,
  totalAnswer,
  totalSkip,
  isAnswerFetching,
  handleSearch,
  answerList,
  handleFilter,
  isAnswerFilter,
  choicesCount,
  language,
  handleFetchMoreAnswerListing,
  totalFilter,
  copyLink,
  optionUuid,
  setOptionUuid,
  lang,
}) {
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box>
        <SurveyQuestionListingCard
          data={data}
          selected={selectedQuestion}
          handleSelectedQuestion={handleSelectedQuestion}
          isQuestionFetching={isQuestionFetching}
        />
      </Box>
      <Box>
        <Box mb={2}>
          <SelectedQuestionCard
            selectedQuestion={selectedQuestion}
            isShowAnswer={isShowAnswer}
            handleShowAnswer={handleShowAnswer}
            isQuestionFetching={isQuestionFetching}
            totalResponse={totalResponse}
            totalAnswer={totalAnswer}
            totalSkip={totalSkip}
            isAnswerFetching={isAnswerFetching}
            optionUuid={optionUuid}
            setOptionUuid={setOptionUuid}
          />
        </Box>
        <Box mb={2}>
          <SurveyAnswerCard
            answerList={answerList}
            isShowAnswer={isShowAnswer}
            handleSearch={handleSearch}
            isAnswerFetching={isAnswerFetching}
            isAnswerFilter={isAnswerFilter}
            handleFilter={handleFilter}
            handleFetchMoreAnswerListing={handleFetchMoreAnswerListing}
            language={language}
            totalFilter={totalFilter}
            copyLink={copyLink}
            selectedQuestion={selectedQuestion}
            optionUuid={optionUuid}
          />
        </Box>
        {(selectedQuestion.type === surveyQuestionType.selection ||
          selectedQuestion.type === surveyQuestionType.dropdownlist ||
          selectedQuestion.type === surveyQuestionType.starRating ||
          selectedQuestion.type === surveyQuestionType.multiStarRating) && (
          <Box>
            <SurveyChoiceOverviewCard
              data={choicesCount.data}
              labels={choicesCount.label}
              type={selectedQuestion.type}
              countResult={choicesCount.countResult}
              optionUuid={optionUuid}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
