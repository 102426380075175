import React, { useContext, useEffect, useState } from "react";
import TcgSummaryCardComponent from "./tcgSummaryCard.component";
import { useDispatch, useSelector } from "react-redux";
import { DashboardContext } from "modules/dashboard/pages/dashboard/dashboard.container";
import moment from "moment";
import { addAlert } from "modules/notification";
import dashboardApi from "app/api/dashboard";
import { getApiLang } from "app/feature/constants";
import { summaryCardType } from "modules/dashboard/constants";

function TcgSummaryCardContainer({ type }) {
  const { currentFilter, activeIndex } = useContext(DashboardContext);
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const [summary, setSummary] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter.dateRange]);

  const getData = () => {
    setIsLoading(true);
    setSummary({
      ...summary,
      isError: false,
    });

    let param = {
      start_date: moment(currentFilter.dateRange[0]).format("YYYY-MM-DD"),
      end_date: moment(currentFilter.dateRange[1]).format("YYYY-MM-DD"),
      is_today: activeIndex === 1 ? 1 : 0,
      is_month: activeIndex === 2 || activeIndex === 3 ? 1 : 0,
    };

    switch (type) {
      case summaryCardType.antiCounterfeitTotalScan:
        dashboardApi
          .getAntiCounterfeitTotalScan(param)
          .then((res) => {
            setSummary(res.data.anti_counterfeit_total_scan);
          })
          .catch((error) => {
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, error.code),
              })
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case summaryCardType.totalValidScan:
        dashboardApi
          .getTotalValidScan(param)
          .then((res) => {
            setSummary(res.data.total_valid_scan);
          })
          .catch((error) => {
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, error.code),
              })
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case summaryCardType.totalSuspendedScan:
        dashboardApi
          .getTotalSuspendedScan(param)
          .then((res) => {
            setSummary(res.data.total_suspended_scan);
          })
          .catch((error) => {
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, error.code),
              })
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case summaryCardType.totalWarrantyScan:
        dashboardApi
          .getTotalWarrantyScan(param)
          .then((res) => {
            setSummary(res.data.total_warranty_scan);
          })
          .catch((error) => {
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, error.code),
              })
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case summaryCardType.totalLoyaltyPointsScan:
        dashboardApi
          .getTotalLoyaltyPointsScan(param)
          .then((res) => {
            setSummary(res.data.total_loyalty_points_scan);
          })
          .catch((error) => {
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, error.code),
              })
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case summaryCardType.totalLuckyDrawScan:
        dashboardApi
          .getTotalLuckyDrawScan(param)
          .then((res) => {
            setSummary(res.data.total_lucky_draw_scan);
          })
          .catch((error) => {
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, error.code),
              })
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case summaryCardType.totalSurveyReceived:
        setIsLoading(false);
        dashboardApi
          .getTotalSurveyReceived(param)
          .then((res) => {
            setSummary(res.data.total_survey_received);
          })
          .catch((error) => {
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, error.code),
              })
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      default:
        break;
    }
  };

  return (
    <TcgSummaryCardComponent
      language={language}
      data={summary}
      loading={isLoading}
      lang={lang}
      type={type}
    />
  );
}

export default TcgSummaryCardContainer;
