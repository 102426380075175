import React, { useEffect, useState } from "react";
import LuckyDrawDetailPreviewPanelComponent from "./luckyDrawDetailPreviewPanel.component";

function LuckyDrawDetailPreviewPanelContainer({ luckyDraw, lang }) {
  const [prizes, setPrizes] = useState([]);

  useEffect(() => {
    let prizeList = { 0: [], 1: [], 2: [], 3: [], 4: [], 5: [] };
    if (luckyDraw)
      luckyDraw.prizeInfo.forEach((rankPrizes) => {
        rankPrizes.forEach((prize) => {
          prizeList[Number(prize.rank_position)].push(prize);
        });
      });
    setPrizes(prizeList);
  }, [luckyDraw]);

  return (
    <LuckyDrawDetailPreviewPanelComponent
      luckyDraw={luckyDraw}
      lang={lang}
      prizes={prizes}
    />
  );
}

export default LuckyDrawDetailPreviewPanelContainer;
