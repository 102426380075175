import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Button,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import LiveDrawBgTop from "assets/img/liveDrawBgTop.png";
import LiveDrawBgBottom from "assets/img/liveDrawBgBottom.png";
import {
  NavigateNextRounded as NavigateNextRoundedIcon,
  NavigateBeforeRounded as NavigateBeforeRoundedIcon,
  Fullscreen as FullScreenIcon,
  FullscreenExit as FullScreenExitIcon,
} from "@material-ui/icons";
import { getLang } from "app/feature/constants";
import DefaultImg from "assets/img/img-default.png";
import DrawText from "modules/lucky-draw-v2/components/transition/drawText";
import PrizeRankIcon from "assets/img/prizeRankIcon.png";
import confetti from "assets/img/confetti.gif";
import { PRIZE_DRAW_ONE_BY_ONE_CAROUSEL } from "modules/lucky-draw-v2/constants";

const useStyle = makeStyles((theme) => ({
  root: {
    background: "#1A385E",
    position: "relative",
    minHeight: "100dvh",
    minWidth: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 24,
  },
  topBG: {
    position: "absolute",
    left: 0,
    top: 0,
    maxWidth: "40dvh",
    maxHeight: "30dvh",
  },
  bottomBG: {
    position: "absolute",
    right: 0,
    bottom: 0,
    maxWidth: "30dvh",
    maxHeight: "30dvh",
  },
  arrowButton: {
    backgroundColor: "#9C9C9C80",
    borderRadius: 50,
    height: 40,
    width: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      height: 30,
      width: 30,
    },
  },
  actionButton: {
    backgroundColor: "white",
    padding: 8,
    boxShadow: "0px 4px 0px 0px #A28EB7",
    borderRadius: 28,
    textTransform: "capitalize",
    maxWidth: "70dvw",
    width: 560,
    fontSize: 20,
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "white",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "60dvw",
    },
  },
  card: {
    background: "white",
    borderRadius: 8,
    maxWidth: "70dvw",
    width: 560,
    overflow: "hidden",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "60dvw",
    },
  },
  prizeImage: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  prizeImageContainer: {
    maxWidth: "90%",
    height: 250,
    border: "2px solid #E2E2E2",
    backgroundColor: "white",
    borderRadius: 16,
    margin: "auto",
    marginTop: 12,
    marginBottom: 12,
    overflow: "hidden",
  },
  prizeSmallImage: {
    width: "35%",
    aspectRatio: "3 / 2",
    objectFit: "cover",
    border: "2px solid #e6e6e6",
    borderRadius: 8,
    marginRight: 16,
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "3.5fr 8.5fr",
    alignItems: "center",
  },
  prizeContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: 16,
  },
  smallCard: {
    background: "white",
    boxShadow: "0px 2px 2px 0px #00000026",
    borderRadius: 8,
  },
  winnerList: {
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "rgba(255,255,255,1)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "2px",
      backgroundColor: "#b8b8b8",
    },
  },
  field: {
    backgroundColor: "#FFFFFF",
    border: "2px solid #E2E2E2",
    padding: 8,
    height: 40,
    borderRadius: 4,
    overflow: "hidden",

    "& > p": {
      whiteSpace: "nowrap",
      letterSpacing: 1,
    },
  },
  winnerContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "85dvw",
    width: 1200,
  },
  prizeCard: {
    background: "white",
    borderRadius: 4,
    position: "relative",
    padding: 16,
    display: "flex",
    maxWidth: "600px",
    margin: "16px 10px",
  },
  prizeRank: {
    position: "absolute",
    top: 0,
    left: 0,
    backgroundImage: `url(${PrizeRankIcon})`,
    backgroundSize: "contain",
    height: 48,
    width: 48,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  prizeLabel: {
    backgroundColor: "#9A6CE6",
    padding: "0px 6px",
    position: "absolute",
    top: 10,
    left: 0,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    color: "white",
  },
  confetti: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    margin: "auto",
    height: "100%",
    display: "none",
    // animation: "$hideImage 8.1s forwards",
    pointerEvents: "none",
  },
  "@keyframes hideImage": {
    "0%": {
      display: "block",
    },
    "100%": {
      display: "none",
    },
  },
  fullScreenBtnContainer: {
    position: "absolute",
    top: "10px",
    right: "10px",
    transition: "transform 100ms linear",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  indexContainer: {
    backgroundColor: "#9A6CE6",
    color: "white",
    borderRadius: 50,
    width: "fit-content",
    aspectRatio: "1/1",
    minWidth: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "fit-content",
  },
}));

export default function TypewriterFieldPanelComponent({
  prizes,
  luckyDraw,
  lang,
  currentIndex,
  isLoading,
  isDrawing,
  handleButtonAction,
  handleNextPage,
  handlePreviousPage,
  handleFullScreen,
  isFullScreen,
  showResult,
  hasRankedPrizes,
  companyId,
  totalDraw,
}) {
  const classes = useStyle();
  const rankName = {
    1: getLang(lang, "label.GRAND_PRIZE"),
    2: getLang(lang, "label.SECOND_PRIZE"),
    3: getLang(lang, "label.THIRD_PRIZE"),
    4: getLang(lang, "label.FOURTH_PRIZE"),
    5: getLang(lang, "label.FIFTH_PRIZE"),
  };

  let prize = prizes.length ? prizes[currentIndex] : null;
  let quantity = prize ? Number(prize?.quantity) : 0;

  // const generateWinnerName = (text) => {
  //   let tempText = text;
  //   if (tempText)
  //     if (tempText.length <= 1) {
  //       // tempText = text
  //     } else if (tempText.length < 4) {
  //       tempText = tempText.slice(0, -1) + "*";
  //     } else {
  //       tempText = tempText.slice(0, -4) + "****";
  //     }

  //   return tempText;
  // };

  const namePostFix = {
    152: [
      "14497e12976a44c8b14fbd45d58c5c5a",
      "1a8d1fb30feb4ca1a45086b6811359bb",
      "30055fba371e4201a541ae074cc1812d",
    ],
    146: [
      "6dddb4dc49d04caea9e5b72d1dfdcb5e",
      "c58dac4972e7422bae613922a0df3b6f",
    ],
  };

  return (
    <Box className={classes.root}>
      <img
        src={LiveDrawBgTop}
        alt="top"
        width="100%"
        className={classes.topBG}
      />
      <img
        src={LiveDrawBgBottom}
        alt="bottom"
        width="100%"
        className={classes.bottomBG}
      />
      <Box className={classes.fullScreenBtnContainer}>
        <IconButton onClick={handleFullScreen}>
          {isFullScreen ? (
            <FullScreenExitIcon style={{ color: "#FFFFFF" }} />
          ) : (
            <FullScreenIcon style={{ color: "#FFFFFF" }} />
          )}
        </IconButton>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        {isLoading ? (
          <CircularProgress style={{ color: "white" }} />
        ) : luckyDraw ? (
          <>
            <Box mb={2}>
              <Typography variant="h4" style={{ color: "white" }}>
                <b>{luckyDraw?.title || getLang(lang, "label.LUCKY_DRAW")}</b>
              </Typography>
            </Box>
            {showResult ? (
              <>
                <Box className={classes.winnerContainer} my={2}>
                  {prizes.map((p, i) =>
                    p.winners.map((winner, j) => {
                      let postFix =
                        companyId === 152 || companyId === 146
                          ? winner.form_values.find(
                              (item) =>
                                namePostFix[companyId] &&
                                namePostFix[companyId].includes(item.uuid)
                            )?.value ?? null
                          : null;
                      return (
                        <Box
                          index={`${i}_${j}`}
                          className={classes.prizeCard}
                          style={{ flex: "45%" }}
                        >
                          <img
                            src={p.image_url || DefaultImg}
                            alt="prizeImg"
                            className={classes.prizeSmallImage}
                          />
                          <Box>
                            <Box mb={1}>
                              <Typography variant="h5">
                                <b>
                                  {winner.index}. {p.name}
                                </b>
                              </Typography>
                            </Box>
                            <Box>
                              <Typography
                                variant="body1"
                                style={{ color: "#757575" }}
                              >
                                <b>{getLang(lang, "label.SERIAL_NO")} :</b>{" "}
                                {winner.serial_number}
                              </Typography>
                              <Typography
                                variant="body1"
                                style={{ color: "#757575" }}
                              >
                                <b>{getLang(lang, "label.NAME")} :</b>{" "}
                                {winner.name}
                                {postFix ? ` (${postFix})` : ""}
                              </Typography>
                              <Typography
                                variant="body1"
                                style={{ color: "#757575" }}
                              >
                                <b>{getLang(lang, "label.CONTACT")} :</b>{" "}
                                {winner.contact_no ?? "-"}
                              </Typography>
                            </Box>
                          </Box>
                          {p.rank_position && !!Number(p.rank_position) ? (
                            <Box className={classes.prizeRank}>
                              <Typography
                                variant="body1"
                                style={{ marginTop: -7 }}
                              >
                                <b>{p.rank_position}</b>
                              </Typography>
                            </Box>
                          ) : (
                            <Box className={classes.prizeLabel}>
                              <Typography variant="caption">
                                <b>
                                  {getLang(
                                    lang,
                                    hasRankedPrizes
                                      ? "label.OTHER_PRIZE"
                                      : "label.OUR_PRIZE"
                                  )}
                                </b>
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      );
                    })
                  )}
                </Box>
                <Box mt={4}>
                  <Button
                    className={classes.actionButton}
                    startIcon={
                      isDrawing ? (
                        <CircularProgress style={{ width: 20, height: 20 }} />
                      ) : null
                    }
                    onClick={() => handleButtonAction(prize)}
                  >
                    {getLang(lang, "label.BACK_TO_HOME")}
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    className={classes.arrowButton}
                    mr={3}
                    onClick={() => {
                      handlePreviousPage(prize);
                    }}
                    style={{ opacity: currentIndex === 0 ? 0.5 : 1 }}
                  >
                    <NavigateBeforeRoundedIcon
                      style={{ fontSize: 37, color: "white" }}
                    />
                  </Box>
                  {Number(prize?.quantity) > 1 ? (
                    <Box className={classes.prizeContainer}>
                      <Box
                        className={classes.card}
                        p={2}
                        style={{ maxWidth: "35dvw", background: "#F7F7F7" }}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography variant="h6">
                          <b>{getLang(lang, "label.NOW_DRAWING")}</b>
                        </Typography>
                        <Box className={classes.prizeImageContainer}>
                          <img
                            src={prize?.image_url || DefaultImg}
                            className={classes.prizeImage}
                            alt="prize"
                          />
                        </Box>
                        <Typography variant="body1">
                          {prize?.rank_position &&
                          !!Number(prize?.rank_position)
                            ? rankName[prize?.rank_position]
                            : hasRankedPrizes
                            ? getLang(lang, "label.OTHER_PRIZE")
                            : getLang(lang, "label.OUR_PRIZE")}
                          :
                        </Typography>
                        <Box display="flex" justifyContent="center" mt={2}>
                          <Typography
                            variant="h6"
                            style={{ textAlign: "center" }}
                          >
                            <b>
                              {prize?.name}{" "}
                              {Number(prize.draw_type) ===
                              PRIZE_DRAW_ONE_BY_ONE_CAROUSEL
                                ? ""
                                : `(${prize?.quantity})`}
                            </b>
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        className={classes.card}
                        style={{ maxWidth: "35dvw", background: "#F7F7F7" }}
                      >
                        <Box p={2}>
                          <Typography variant="h6">
                            <b>{getLang(lang, "label.WINNERS")}</b>
                          </Typography>
                        </Box>
                        <Box
                          overflow="auto"
                          px={2}
                          pb={2}
                          className={classes.winnerList}
                          style={{ maxHeight: "55vh" }}
                        >
                          {[...new Array(quantity)].map((v, index) => (
                            <Box
                              key={index}
                              className={classes.smallCard}
                              p={2}
                              mt={index ? 2 : 0}
                            >
                              <Box className={classes.grid}>
                                <Box>
                                  <Typography variant="body1">
                                    {getLang(lang, "label.SERIAL_NUMBER")}
                                  </Typography>
                                </Box>
                                <Box className={classes.field}>
                                  <Typography variant="body1">
                                    <b>
                                      {prize?.winners.length &&
                                      prize.winners[index] ? (
                                        <DrawText
                                          index={index}
                                          text={
                                            prize.winners[index].serial_number
                                          }
                                          isNewDraw={
                                            prize.winners[index]?.is_new_draw ||
                                            false
                                          }
                                          drawTime={
                                            Number(prize.draw_duration) * 1000
                                          }
                                        />
                                      ) : null}
                                    </b>
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className={classes.grid} mt={2}>
                                <Box>
                                  <Typography variant="body1">
                                    {getLang(lang, "label.NAME")}
                                  </Typography>
                                </Box>
                                <Box className={classes.field}>
                                  <Typography variant="body1">
                                    <b>
                                      {prize?.winners.length &&
                                      prize.winners[index] ? (
                                        <DrawText
                                          index={index}
                                          text={prize.winners[index].name}
                                          isNewDraw={
                                            prize.winners[index]?.is_new_draw ||
                                            false
                                          }
                                          drawTime={
                                            Number(prize.draw_duration) * 1000
                                          }
                                          postfix={
                                            companyId === 152 ||
                                            companyId === 146
                                              ? prize.winners[
                                                  index
                                                ]?.form_values?.find(
                                                  (item) =>
                                                    namePostFix[companyId] &&
                                                    namePostFix[
                                                      companyId
                                                    ].includes(item.uuid)
                                                )?.value ?? null
                                              : ""
                                          }
                                        />
                                      ) : null}
                                    </b>
                                  </Typography>
                                </Box>
                              </Box>
                              <Box className={classes.grid} mt={2}>
                                <Box>
                                  <Typography variant="body1">
                                    {getLang(lang, "label.CONTACT")}
                                  </Typography>
                                </Box>
                                <Box className={classes.field}>
                                  <Typography variant="body1">
                                    <b>
                                      {prize?.winners.length &&
                                      prize.winners[index] ? (
                                        <DrawText
                                          index={index}
                                          text={prize.winners[index].contact_no}
                                          isNewDraw={
                                            prize.winners[index]?.is_new_draw ||
                                            false
                                          }
                                          drawTime={
                                            Number(prize.draw_duration) * 1000
                                          }
                                        />
                                      ) : null}
                                    </b>
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <Box className={classes.card}>
                      <Box
                        style={{ background: "#F7F7F7" }}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        p={2}
                      >
                        <Typography variant="h6">
                          <b>{getLang(lang, "label.NOW_DRAWING")}</b>
                        </Typography>
                        <Box className={classes.prizeImageContainer}>
                          <img
                            src={prize?.image_url || DefaultImg}
                            className={classes.prizeImage}
                            alt="prize"
                          />
                        </Box>
                        <Typography variant="body1">
                          {prize?.rank_position &&
                          !!Number(prize?.rank_position)
                            ? rankName[prize?.rank_position]
                            : hasRankedPrizes
                            ? getLang(lang, "label.OTHER_PRIZE")
                            : getLang(lang, "label.OUR_PRIZE")}
                          :
                        </Typography>
                        <Box display="flex" justifyContent="center" mt={2}>
                          <Typography
                            variant="h6"
                            style={{ textAlign: "center" }}
                          >
                            <b>
                              {prize?.name}{" "}
                              {Number(prize.draw_type) ===
                              PRIZE_DRAW_ONE_BY_ONE_CAROUSEL
                                ? ""
                                : `(${prize?.quantity})`}
                            </b>
                          </Typography>
                        </Box>
                      </Box>
                      <Box p={3}>
                        <Box className={classes.grid}>
                          <Box>
                            <Typography variant="body1">
                              {getLang(lang, "label.SERIAL_NUMBER")}
                            </Typography>
                          </Box>
                          <Box className={classes.field}>
                            <Typography variant="body1">
                              <b>
                                {prize?.winners.length && prize.winners[0] ? (
                                  <DrawText
                                    text={prize.winners[0].serial_number}
                                    index={0}
                                    isNewDraw={
                                      prize.winners[0]?.is_new_draw || false
                                    }
                                    drawTime={
                                      Number(prize.draw_duration) * 1000
                                    }
                                  />
                                ) : null}
                              </b>
                            </Typography>
                          </Box>
                        </Box>
                        <Box className={classes.grid} mt={2}>
                          <Box>
                            <Typography variant="body1">
                              {getLang(lang, "label.NAME")}
                            </Typography>
                          </Box>
                          <Box className={classes.field}>
                            <Typography variant="body1">
                              <b>
                                {prize?.winners.length && prize.winners[0] ? (
                                  <DrawText
                                    index={0}
                                    text={prize.winners[0].name}
                                    isNewDraw={
                                      prize.winners[0]?.is_new_draw || false
                                    }
                                    drawTime={
                                      Number(prize.draw_duration) * 1000
                                    }
                                    needCensor={false}
                                    postfix={
                                      companyId === 152 || companyId === 146
                                        ? prize.winners[0]?.form_values?.find(
                                            (item) =>
                                              namePostFix[companyId] &&
                                              namePostFix[companyId].includes(
                                                item.uuid
                                              )
                                          )?.value ?? null
                                        : ""
                                    }
                                  />
                                ) : null}
                              </b>
                            </Typography>
                          </Box>
                        </Box>
                        <Box className={classes.grid} mt={2}>
                          <Box>
                            <Typography variant="body1">
                              {getLang(lang, "label.CONTACT")}
                            </Typography>
                          </Box>
                          <Box className={classes.field}>
                            <Typography variant="body1">
                              <b>
                                {prize?.winners.length && prize.winners[0] ? (
                                  <DrawText
                                    index={0}
                                    text={prize.winners[0].contact_no}
                                    isNewDraw={
                                      prize.winners[0]?.is_new_draw || false
                                    }
                                    drawTime={
                                      Number(prize.draw_duration) * 1000
                                    }
                                  />
                                ) : null}
                              </b>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  <Box
                    className={classes.arrowButton}
                    ml={3}
                    onClick={() => {
                      handleNextPage(prize);
                    }}
                    style={{
                      opacity: currentIndex === prizes.length - 1 ? 0.5 : 1,
                    }}
                  >
                    <NavigateNextRoundedIcon
                      style={{ fontSize: 37, color: "white" }}
                    />
                  </Box>
                </Box>
                {(process.env.REACT_APP_ENV !== "PRODUCTION" ||
                  companyId !== 152 ||
                  totalDraw < 279) && (
                  <Box mt={4}>
                    <Button
                      className={classes.actionButton}
                      startIcon={
                        isDrawing ? (
                          <CircularProgress style={{ width: 20, height: 20 }} />
                        ) : null
                      }
                      onClick={() => handleButtonAction(prize)}
                      disabled={isDrawing}
                    >
                      {isDrawing
                        ? getLang(lang, "label.DRAW_PRIZE")
                        : prize?.winners.length === Number(prize?.quantity) &&
                          currentIndex === prizes.length - 1
                        ? getLang(lang, "label.VIEW_RESULT")
                        : prize?.winners.length === 0
                        ? getLang(lang, "label.DRAW_PRIZE")
                        : getLang(lang, "label.DRAW_NEXT_PRIZE")}
                    </Button>
                  </Box>
                )}
              </>
            )}
          </>
        ) : null}
        <img
          src={confetti}
          alt="confetti"
          id="confetti"
          className={classes.confetti}
        />
      </Box>
    </Box>
  );
}
