import React from "react";
import WebVideoComponent from "./webVideo.component";
import Skeleton from "@material-ui/lab/Skeleton";
import PlayCircleFilledRoundedIcon from "@material-ui/icons/PlayCircleFilledRounded";
import { useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { getLang } from "app/feature/constants";

function SkeletonFrame({ error = false, lang }) {
  return (
    <div
      style={{
        backgroundColor: "white",
        border: "1px solid lightgrey",
        borderRadius: 10,
        marginRight: 16,
        marginLeft: 16,
        minHeight: "max-content",
        overflow: "hidden"
      }}
    >
      {error && (
        <div style={{ padding: 8 }}>
          <Alert severity="error">{getLang(lang,"message.error.WEB_VIDEO_URL_INVALID")}</Alert>
        </div>
      )}
      <Skeleton
        variant="rect"
        height={200}
        animation={false}
        style={{ position: "relative" }}
      >
        <PlayCircleFilledRoundedIcon
          fontSize="large"
          color="disabled"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
          }}
        />
      </Skeleton>
    </div>
  );
}

export default function WebVideoContainer({ id, isTemplateEditor }) {
  const {
    isVisible,
    content: { URL, enableAutoplay }
  } = useSelector(state => isTemplateEditor
    ? state.products.template.workspaceSetting.setting.components[id]
    : state.productPage.components[id]);
  const lang = useSelector(state => state.constant.languages);

  if (!URL)
    return <SkeletonFrame error={true} lang={lang}/>
  else
    return isVisible && <WebVideoComponent url={URL} enableAutoplay={enableAutoplay} />
}
