import React from "react";
import SurveyAnswerCardComponent from "./surveyAnswerCard.component";
import { useSelector } from "react-redux";

export default function SurveyAnswerCardContainer({
  answerList,
  isShowAnswer,
  handleSearch,
  isAnswerFetching,
  isAnswerFilter,
  language,
  handleFilter,
  handleFetchMoreAnswerListing,
  totalFilter,
  copyLink,
  selectedQuestion,
  optionUuid,
}) {
  const lang = useSelector((state) => state.constant.languages);

  return (
    <SurveyAnswerCardComponent
      isShowAnswer={isShowAnswer}
      language={language}
      answerList={answerList}
      handleSearch={handleSearch}
      isAnswerFetching={isAnswerFetching}
      isAnswerFilter={isAnswerFilter}
      handleFilter={handleFilter}
      handleFetchMoreAnswerListing={handleFetchMoreAnswerListing}
      totalFilter={totalFilter}
      copyLink={copyLink}
      lang={lang}
      selectedQuestion={selectedQuestion}
      optionUuid={optionUuid}
    />
  );
}
