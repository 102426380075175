import React, { useState } from "react";
import InteractiveEditorComponent from "./interactiveEditor.component";
import Authentication from "../content-template/authentication";
import Facebook from "../content-template/facebook";
import Gallery from "../content-template/gallery";
import Instagram from "../content-template/instagram";
import Website from "../content-template/website";
import Video from "../content-template/video";
import Tiktok from "../content-template/tiktok";
import Text from "../content-template/text";
import Marketplace from "../content-template/marketplace";
import { connect } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";
import { reorderComponent } from "../../redux/slice/productPage.slice";
import ComponentActionPanel from "../panel/componentActionPanel";
import { useSelector } from "react-redux";
import Warranty from "../content-template/warranty";
import AdditionalInfo from "../content-template/additionalInfo";
import LoyaltyPoint from "../content-template/loyaltyPoint";
import Survey from "../content-template/survey";
import LuckyDraw from "../content-template/luckyDraw";
import InstantWin from "../content-template/instantWin";
import Co2Footprint from "../content-template/co2Footprint";
import WebVideo from "../content-template/webVideo";

function InteractiveEditorContainer({
  componentIds,
  reorderComponent,
  previewMode,
  companyLogo,
  components,
}) {
  const uiSetting = useSelector((state) => state.setting.uiSetting);
  const currentLanguage = useSelector(
    (state) => state.productPage.pageWorkspaceLanguage
  );
  const [isAnyComponentHidden, setIsAnyComponentHidden] = useState(false);

  const populateComponents = () => {
    let isAnyComponentHidden = false;

    let componentsToRender = componentIds.map((id, index) => {
      const type = id.split("-")[0];
      var component = null;

      switch (type) {
        case "authentication":
          component = (
            <Authentication id={id} currentLanguage={currentLanguage} />
          );
          break;
        case "additional information":
          component = <AdditionalInfo id={id} />;
          break;
        case "warranty":
          component = <Warranty id={id} />;
          break;
        case "facebook":
          component = <Facebook id={id} />;
          break;
        case "gallery":
          component = <Gallery id={id} />;
          break;
        case "instagram":
          component = <Instagram id={id} />;
          break;
        case "website":
          component = <Website id={id} currentLanguage={currentLanguage} />;
          break;
        case "video":
          component = <Video id={id} />;
          break;
        case "tiktok":
          component = <Tiktok id={id} />;
          break;
        case "text":
          component = <Text id={id} currentLanguage={currentLanguage} />;
          break;
        case "marketplace":
          component = <Marketplace id={id} />;
          break;
        case "loyalty point":
          component = <LoyaltyPoint id={id} />;
          break;
        case "survey":
          component = <Survey id={id} />;
          break;
        case "lucky draw":
          component = <LuckyDraw id={id} />;
          break;
        case "instant win":
          component = <InstantWin id={id} />;
          break;
        case "co2 footprint":
          component = <Co2Footprint id={id} />;
          break;
        case "web video":
          component = <WebVideo id={id} />;
          break;
        default:
          break;
      }

      if (!components[id].isVisible) {
        isAnyComponentHidden = true;
      }

      return (
        <ComponentActionPanel
          id={id}
          key={id}
          draggableIndex={index}
          previewMode={previewMode}
          type={type}
        >
          {component}
        </ComponentActionPanel>
      );
    });

    setIsAnyComponentHidden(isAnyComponentHidden);

    return componentsToRender;
  };

  const handleDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    reorderComponent({
      id: draggableId,
      sourceIndex: source.index,
      destinationIndex: destination.index,
    });
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <InteractiveEditorComponent
        populateComponents={populateComponents}
        previewMode={previewMode}
        companyLogo={companyLogo}
        isAnyComponentHidden={isAnyComponentHidden}
        uiSetting={uiSetting}
      />
    </DragDropContext>
  );
}

const mapStateToProps = (state) => ({
  componentIds: state.productPage.columns.compIds,
  companyLogo: state.account.logoImg,
  components: state.productPage.components,
});

const mapDispatchToProps = (dispatch) => ({
  reorderComponent: (payload) => dispatch(reorderComponent(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InteractiveEditorContainer);
