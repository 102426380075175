/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SmtpSettingPanelComponent from "./smtpSettingPanel.component";
import { useHistory } from "react-router-dom";
import { DASHBOARD } from "modules/dashboard/constants";
import accountApi from "app/api/account";
import { addAlert } from "modules/notification";
import { getApiLang, getLang } from "app/feature/constants";
import { COMPANY_SMTP_SETTINGS_EDIT } from "lib/constants/accessRights";

export default function SmtpSettingPanelContainer() {
  const dispatch = useDispatch();
  const history = useHistory;
  const lang = useSelector((state) => state.constant.languages);

  // const hasSMTPViewAccessRight = useSelector((state) =>
  //   state.profile.accessRights.includes(DKSH_INTEGRATION_SETTING_VIEW)
  // );
  // const hasSmtpViewAccessRight = true;
  const hasSmtpEditAccessRight = useSelector((state) =>
    state.profile.accessRights.includes(COMPANY_SMTP_SETTINGS_EDIT)
  );

  const [isLoading, setIsLoading] = useState(false);
  const [setting, setSetting] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    if (hasSmtpEditAccessRight) {
      getData();
    } else {
      history.push(DASHBOARD);
    }
  }, [history, dispatch, hasSmtpEditAccessRight]);

  const getData = () => {
    setIsError(false);
    setIsLoading(true);
    accountApi
      .getSmtpSetting()
      .then((res) => {
        setSetting({
          smtp_host: res.data.smtp_host,
          port: res.data.port_number,
          username: res.data.username,
          password: res.data.password,
          from_email: res.data.from_email_address,
          from_name: res.data.from_name,
          tls_encryption: res.data.tls_encryption,
          is_setup: res.data.is_setup,
        });
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
      })
      .finally(() => {
        if (isMounted.current) {
          setIsLoading(false);
        }
      });
  };

  const handleUpdate = (values) => {
    setIsSubmitting(true);
    accountApi
      .updateSmtpSetting({
        smtp_host: values.smtpHost,
        port_number: values.port,
        username: values.username,
        password: values.password,
        from_email_address: values.fromEmail,
        from_name: values.fromName,
        tls_encryption: values.tlsEncryption,
        is_setup: values.isDelete ? 0 : 1,
      })
      .then(() => {
        dispatch(
          addAlert({
            severity: "success",
            message: values.isDelete
              ? getLang(lang, "message.success.SUCCESS_DELETE_SMTP_SETTINGS")
              : getLang(lang, "message.success.SUCCESS_UPDATE_SMTP_SETTINGS"),
          })
        );

        setIsEdit(false);
        getData();
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
      })
      .finally(() => {
        if (isMounted.current) {
          setIsSubmitting(false);
        }
      });
  };

  return (
    <SmtpSettingPanelComponent
      hasSmtpEditAccessRight={hasSmtpEditAccessRight}
      isLoading={isLoading}
      setting={setting}
      handleUpdate={handleUpdate}
      lang={lang}
      isSubmitting={isSubmitting}
      setIsEdit={setIsEdit}
      isEdit={isEdit}
      isError={isError}
      handleReload={getData}
    />
  );
}
