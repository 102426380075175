import React from "react";
import { Typography, Box, Switch } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { TYPES } from "./webVideoForm.container";
import { ModalFooter, ContentWrapper } from "../../modal";
import Input from "components/input/inputTextField";
import { getLang } from "app/feature/constants";

const validateURL = (url) => {
  const urlPattern = /\.(mp4|webm|ogg)$/;
  let test1 = urlPattern.test(url);
  try {
    new URL(url);
    return test1;
  } catch (_) {
    return false;
  }
};

export default function WebVideoFormComponent({
  type,
  initialValues,
  handleSubmit,
  handleDelete,
  lang,
  handleClose,
}) {
  return (
    <Formik
      initialValues={{
        URL: initialValues.URL,
        enableAutoplay: initialValues.enableAutoplay ?? false,
      }}
      validationSchema={Yup.object({
        URL: Yup.string()
          .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
          .test(
            "is-valid-url",
            getLang(lang, "message.error.FORM_VALIDATE_WEB_VIDEO_URL"),
            (value) => validateURL(value)
          ),
      })}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <ContentWrapper>
            <Typography variant="body2" gutterBottom>
              {getLang(lang, "label.INSERT_WEB_VIDEO_URL")}{" "}
              <Typography style={{ color: "#DE350B", display: "inline-block" }}>
                *
              </Typography>
            </Typography>
            <Box alignItems="center" mb={3}>
              <Box>
                <Input
                  required
                  hiddenLabel
                  variant="filled"
                  size="small"
                  margin="normal"
                  placeholder={getLang(lang, "placeholder.ENTER_URL_HERE")}
                  fullWidth
                  {...formik.getFieldProps("URL")}
                  value={
                    type === TYPES[2] ? initialValues.URL : formik.values.URL
                  }
                  error={formik.touched.URL && formik.errors.URL ? true : false}
                  helperText={ErrorMessage({ name: "URL" })}
                  inputProps={{ readOnly: type === TYPES[2] }}
                  onChange={(e) => {
                    formik.setFieldValue("URL", e.target.value);
                  }}
                />
                <Typography variant="body2">
                  <span style={{ color: "gray" }}>
                    {getLang(lang, "label.EXAMPLE")}&nbsp;
                  </span>
                  https://www.mywebsite.com/video.mp4
                </Typography>
              </Box>
            </Box>
            <Typography variant="caption" color="textSecondary">
              <Typography style={{ color: "#DE350B", display: "inline-block" }}>
                *
              </Typography>{" "}
              {getLang(lang, "label.REQUIRED_FIELD")}
            </Typography>
          </ContentWrapper>
          <Box
            px={2}
            py={1}
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Typography variant="subtitle1">
              {getLang(lang, "label.ENABLE_AUTOPLAY")}
            </Typography>
            <Switch
              color="primary"
              checked={formik.values.enableAutoplay}
              onChange={() =>
                formik.setFieldValue(
                  "enableAutoplay",
                  !formik.values.enableAutoplay
                )
              }
            />
          </Box>
          <ModalFooter
            handleDelete={handleDelete}
            showDelete={type !== TYPES[0]}
            handleClose={handleClose}
          />
        </form>
      )}
    </Formik>
  );
}
