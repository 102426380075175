import React from "react";
import { Box, Button, Popover } from "@material-ui/core";
import { ChromePicker } from "react-color";
import { getLang } from "app/feature/constants";

const style = (theme) => ({
  root: {
    display: "flex",
  },
  colorDot: {
    height: 30,
    width: 30,
    borderRadius: "50%",
  },
});

export default function SingleColorPickerSelectPanelComponent({
  anchorEl,
  open,
  handleClose,
  bufferColor,
  handleColorChange,
  lang,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  handleSave,
  handleCancel,
}) {
  return (
    <Popover
      open={open}
      style={style}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={2}
      PaperProps={PaperProps}
    >
      <Box display="flex" flexDirection="column" alignItems="center" p={2}>
        <ChromePicker color={bufferColor} onChange={handleColorChange} />

        <Box mt={2}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            disableElevation
            onClick={handleSave}
          >
            {getLang(lang, "label.SELECT_COLOR")}
          </Button>
          &ensp;
          <Button
            variant="outlined"
            color="primary"
            size="small"
            disableElevation
            onClick={handleCancel}
          >
            {getLang(lang, "label.CANCEL")}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
}
