export function datetimeValidation(date_start, date_end) {
  let valid = true;
  let dateStart = new Date(date_start);
  let dateEnd = new Date(date_end);

  if (dateStart > dateEnd) valid = false;

  if (isNaN(dateStart.getTime()) || isNaN(dateEnd.getTime())) valid = false;

  // if (dateStart > new Date(Date.now()) || dateEnd > new Date(Date.now()))
  //   valid = false;

  if (!valid) {
    dateStart = new Date(Date.now() - 13 * 24 * 60 * 60 * 1000);
    dateEnd = new Date(Date.now());
  }

  return [dateStart, dateEnd];
}

export function setChartColors(data) {
  // default colors
  let colors = {
    1: "#FF5733",
    2: "#33FF57",
    3: "#3357FF",
    4: "#FF33A1",
    5: "#33FFF5",
    6: "#F5FF33",
    7: "#8D33FF",
    8: "#FF8633",
    9: "#33FF86",
    10: "#FF3386",
    11: "#3386FF",
    12: "#86FF33",
    13: "#FF3386",
    14: "#33A1FF",
    15: "#FFC733",
    16: "#A133FF",
    17: "#FF33C7",
    18: "#33FFC7",
    19: "#C733FF",
    20: "#57FF33",
    21: "#FF3357",
    22: "#33FF33",
  };

  let colorPayload = [];
  for (let i = 0; i <= data.length - 1; i++) {
    colorPayload.push(colors[data[i].industry] ?? colors[1]);
  }

  if (colorPayload.length === 0) colorPayload.push(colors[1]);

  return colorPayload;
}

export function setIndustryChartColors(data, colorsSet) {
  let colorPayload = [];
  for (let i = 0; i <= data.length - 1; i++) {
    colorPayload.push(colorsSet[data[i].industry] ?? colorsSet[1]);
  }

  if (colorPayload.length === 0) colorPayload.push(colorsSet[1]);

  return colorPayload;
}

export function setRoleChartColors(data, colorsSet) {
  let colorPayload = [];
  for (let i = 0; i <= data.length - 1; i++) {
    colorPayload.push(colorsSet[data[i].role] ?? colorsSet[1]);
  }

  if (colorPayload.length === 0) colorPayload.push(colorsSet[1]);

  return colorPayload;
}
