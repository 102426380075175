/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import SurveyChoiceOverviewCardComponent from "./surveyChoiceOverviewCard.component";
import { useSelector } from "react-redux";
import { surveyQuestionType } from "modules/survey/constants";
import { getLang } from "app/feature/constants";

export default function SurveyChoiceOverviewCardContainer({
  data,
  labels,
  type,
  countResult,
  optionUuid,
}) {
  const lang = useSelector((state) => state.constant.languages);

  const [labelList, setLabelList] = useState([]);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    if (type === surveyQuestionType.multiStarRating) {
      let index = countResult.findIndex((item) => item.label.id === optionUuid);

      let labelArray = [];
      let valueArray = [];

      if (index >= 0) {
        let rating = countResult[index]?.rating ?? [];
        rating.forEach((rating) => {
          labelArray.push(`${rating.star}/5 ${getLang(lang, "label.STAR")}`);
          valueArray.push(rating.quantity);
        });
        labelArray.push(getLang(lang, "label.SKIP_SMALL_CAP"));
        valueArray.push(countResult[index]?.Skip);
        setLabelList(labelArray);
        setDataList(valueArray);
      }
    } else {
      const newLabels = labels.map(value => value === 'Skip' ? getLang(lang, "label.SKIP_SMALL_CAP") : value);
      setLabelList(newLabels);
      setDataList(data);
    }
  }, [optionUuid, countResult, type]);

  return (
    <SurveyChoiceOverviewCardComponent
      data={dataList}
      labels={labelList}
      lang={lang}
      type={type}
    />
  );
}
