import React from "react";
import clsx from "clsx";
import {
  makeStyles,
  Box,
  ButtonBase,
  IconButton,
  Typography,
  Tooltip,
} from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import VisibilityOffRoundedIcon from "@material-ui/icons/VisibilityOffRounded";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MultipleVisibleIcon from "../../icon/multipleVisible.icon";
import DraggableCard from "../../product-component/draggableCard";
import { yellow } from "@material-ui/core/colors";
import { getLang } from "app/feature/constants";
import { renameComponentTitle } from "modules/product-mgmt/utils/productPage.util";

const useStyle = makeStyles((theme) => ({
  componentContainer: {
    padding: theme.spacing(2, 0),
  },
  hidden: {
    display: "none",
  },
  actionModal: {
    width: "100%",
    height: "100%",
    borderRadius: theme.spacing(3),
    backgroundColor: "rgba(255,255,255,0.65)",
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
    border: "1px solid #00A9FF",
    boxSizing: "border-box",
    transition: theme.transitions.create("display", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.short,
    }),
    padding: theme.spacing(2),
    flexDirection: "column",
  },
  dFlex: {
    display: "flex",
  },
  btnDelete: {
    width: 50,
    height: 50,
    borderRadius: 10,
    backgroundColor: "#EB2E3C",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.common.white,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
  },
  actionContainer: {
    height: 50,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
  },
  iconSelect: {
    color: theme.palette.common.white,
  },
  showComponentWrapper: {
    position: "absolute",
    width: 230,
    height: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    top: -25,
    left: -246,
  },
  btnShowComponent: {
    width: 50,
    height: 50,
    borderRadius: 10,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  hiddenComponentPointer: {
    width: 7,
    height: 7,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    marginLeft: theme.spacing(2),
    position: "relative",
    "&::before": {
      content: "''",
      width: theme.spacing(2),
      height: 2,
      backgroundColor: theme.palette.primary.main,
      position: "absolute",
      top: 2.5,
      left: -theme.spacing(2),
    },
  },
  draggedActionModal: {
    backgroundColor: "rgba(255,255,255,0.85)",
    justifyContent: "center",
    alignItems: "center",
    borderColor: yellow[300],
  },
}));

export default function ComponentActionPanelComponent({
  id,
  children,
  orderIndex,
  orderLength,
  handleReorderBackward,
  handleReorderForward,
  isShowingAction,
  handleShowAction,
  handleHideAction,
  handleDelete,
  handleHideComponent,
  handleShowComponent,
  isVisible,
  hideShowButton,
  showMultipleComponentsButton,
  draggableIndex,
  handleOpenModal,
  isEditorMode,
  previewMode,
  lang,
  type,
  hasAdtInfoViewAccessRight,
}) {
  const classes = useStyle();

  const isFirstOrder = orderIndex === 0;
  const isLastOrder = orderIndex === orderLength - 1;
  const componentType = id.split("-")[0];

  return (
    <DraggableCard id={id} index={draggableIndex}>
      {({ dragHandleProps, isDragging }) => (
        <Box
          position="relative"
          className={clsx(isVisible && classes.componentContainer)}
          onMouseEnter={handleShowAction}
          onMouseLeave={handleHideAction}
        >
          {/* show hidden button start */}
          {!isVisible && !hideShowButton && (
            <Box className={classes.showComponentWrapper}>
              <Typography variant="body2" color="primary">
                {showMultipleComponentsButton
                  ? getLang(lang, "label.MULTIPLE_COMPONENTS")
                  : renameComponentTitle(lang, componentType)}
                &emsp;
              </Typography>
              <Tooltip
                title={
                  showMultipleComponentsButton
                    ? getLang(lang, "tooltips.SHOW_MULTIPLE_COMPONENTS")
                    : getLang(lang, "tooltips.SHOW_MULTIPLE_COMPONENT", {
                        isMultiple: renameComponentTitle(lang, componentType),
                      })
                }
              >
                <ButtonBase
                  className={classes.btnShowComponent}
                  onClick={handleShowComponent}
                >
                  {showMultipleComponentsButton ? (
                    <MultipleVisibleIcon color="inherit" />
                  ) : (
                    <VisibilityIcon color="inherit" />
                  )}
                </ButtonBase>
              </Tooltip>
              <Box className={classes.hiddenComponentPointer} />
            </Box>
          )}
          {/* show hidden button end */}
          <Box
            className={clsx(classes.actionModal, {
              [classes.hidden]:
                !isShowingAction || !isVisible || !isEditorMode || previewMode,
              [classes.dFlex]: isShowingAction || isDragging,
              [classes.draggedActionModal]: isDragging,
            })}
            {...dragHandleProps}
          >
            {!isDragging && (
              <Box display="flex" justifyContent="space-between" mt="auto">
                <ButtonBase
                  className={classes.btnDelete}
                  onClick={handleDelete}
                >
                  <DeleteOutlinedIcon color="inherit" />
                </ButtonBase>
                <Box className={classes.actionContainer}>
                  {((type !== "warranty" &&
                    type !== "co2 footprint" &&
                    type !== "loyalty point" &&
                    type !== "additional information") ||
                    (type === "additional information" &&
                      hasAdtInfoViewAccessRight)) && (
                    <IconButton color="inherit" onClick={handleOpenModal}>
                      <EditRoundedIcon color="inherit" />
                    </IconButton>
                  )}
                  {!isFirstOrder && (
                    <IconButton color="inherit" onClick={handleReorderBackward}>
                      <ArrowUpwardRoundedIcon color="inherit" />
                    </IconButton>
                  )}
                  {!isLastOrder && (
                    <IconButton color="inherit" onClick={handleReorderForward}>
                      <ArrowDownwardRoundedIcon color="inherit" />
                    </IconButton>
                  )}
                  <IconButton color="inherit" onClick={handleHideComponent}>
                    <VisibilityOffRoundedIcon color="inherit" />
                  </IconButton>
                </Box>
              </Box>
            )}
            {isDragging && (
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography variant="body2">
                  {getLang(lang, "paragraph.DROP_COMPONENT_TO_REORDER")}
                </Typography>
              </Box>
            )}
          </Box>
          {children}
        </Box>
      )}
    </DraggableCard>
  );
}
