/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  fetchRespondentQuestionListing,
  fetchRespondentAnswerListing,
  fetchResponseByChoiceCount,
} from "modules/survey/redux/action";
import {
  resetAnswerListing,
  setSelectedQuestion,
} from "modules/survey/redux/slice";
import { selectLoading } from "modules/notification";
import SurveyResultPanelComponent from "./surveyResultPanel.component";
import { addAlert } from "modules/notification";
import { getLang } from "app/feature/constants";
import { surveyQuestionType } from "modules/survey/constants";

const SurveyResultPanelContainer = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const surveyUuid = match.params.surveyUuid;
  const isQuestionFetching = useSelector((state) =>
    selectLoading(state, fetchRespondentQuestionListing.typePrefix)
  );
  const isAnswerFetching = useSelector((state) =>
    selectLoading(state, fetchRespondentAnswerListing.typePrefix)
  );
  const questionList = useSelector((state) => state.survey.questionList);
  const selectedQuestion = useSelector(
    (state) => state.survey.selectedQuestion
  );
  const answerList = useSelector((state) => state.survey.answerList);
  const choicesCount = useSelector(
    (state) => state.survey.surveyAnalytic.choicesCount
  );
  const [isShowAnswer, setIsShowAnswer] = useState(false);
  const [search, setSearch] = useState("");
  const [productSelect, setProductSelect] = useState("");
  const [isAnswerFilter, setIsAnswerFilter] = useState(false);
  const [questionUUID, setQuestionUUID] = useState("");
  const [optionUuid, setOptionUuid] = useState("");
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const CONSUMER_HOST = process.env.REACT_APP_CONSUMER_PORTAL_URL;

  function copyLink() {
    navigator.clipboard.writeText(`${CONSUMER_HOST}/survey/${surveyUuid}`);
    dispatch(
      addAlert({
        severity: "success",
        message: getLang(
          lang,
          "message.success.SUCCESS_COPY_LINK_TO_CLIPBOARD"
        ),
      })
    );
  }

  useEffect(() => {
    dispatch(fetchRespondentQuestionListing({ uuid: surveyUuid }))
      .then(unwrapResult)
      .then((response) => {
        setQuestionUUID(response.data.questions_answers[0].id);

        setSelectedQuestion(response.data.questions_answers[0]);
      });
  }, [surveyUuid]);

  useEffect(() => {
    if (
      selectedQuestion.type === surveyQuestionType.multiStarRating &&
      selectedQuestion.choices.length
    ) {
      setOptionUuid(selectedQuestion.choices[0].id);
    }
  }, [selectedQuestion]);

  useEffect(() => {
    if (questionUUID) {
      getAnswerListing(questionUUID, 0);
    }
  }, [search, questionUUID, productSelect]);

  useEffect(() => {
    getChoiceCount();
  }, [selectedQuestion]);

  const getChoiceCount = () => {
    if (
      selectedQuestion.type === surveyQuestionType.selection ||
      selectedQuestion.type === surveyQuestionType.dropdownlist ||
      selectedQuestion.type === surveyQuestionType.starRating ||
      selectedQuestion.type === surveyQuestionType.multiStarRating
    ) {
      dispatch(
        fetchResponseByChoiceCount({
          surveyUuid: surveyUuid,
          questionUuid: selectedQuestion.uuid,
        })
      );
    }
  };

  const getAnswerListing = (questionUuid, start) => {
    if (start === 0) dispatch(resetAnswerListing());
    dispatch(
      fetchRespondentAnswerListing({
        survey_uuid: surveyUuid,
        question_uuid: questionUuid,
        search: search,
        productSelect: productSelect,
        start: start,
      })
    );
  };

  const handleSelectedQuestion = (question, index) => {
    const payload = {
      ...question,
      index,
    };
    setQuestionUUID(question.id);
    dispatch(setSelectedQuestion(payload));
  };

  const handleFetchMoreAnswerListing = () => {
    getAnswerListing(questionUUID, answerList.data.length);
  };

  const handleShowAnswer = (value) => {
    setIsShowAnswer(value);
  };

  const handleSearch = (value) => {
    setSearch(value.search);
    setProductSelect(value.productSelect);
  };

  const handleFilter = (value) => {
    setIsAnswerFilter(value);
  };

  return (
    <SurveyResultPanelComponent
      data={questionList}
      selectedQuestion={selectedQuestion}
      handleSelectedQuestion={handleSelectedQuestion}
      isShowAnswer={isShowAnswer}
      handleShowAnswer={handleShowAnswer}
      isQuestionFetching={isQuestionFetching}
      language={language}
      totalResponse={answerList.recordTotal}
      totalAnswer={answerList.recordsAnswer}
      totalSkip={answerList.recordsSkip}
      totalFilter={answerList.recordsFiltered}
      answerList={answerList.data}
      handleSearch={handleSearch}
      isAnswerFetching={isAnswerFetching}
      handleFilter={handleFilter}
      isAnswerFilter={isAnswerFilter}
      choicesCount={choicesCount}
      handleFetchMoreAnswerListing={handleFetchMoreAnswerListing}
      copyLink={copyLink}
      setOptionUuid={setOptionUuid}
      optionUuid={optionUuid}
      lang={lang}
    />
  );
};

export default SurveyResultPanelContainer;
