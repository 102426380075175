import axios from "axios";

export default function updateSmtpSetting(parameters) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/account/api/v1/smtp-settings/update`, {
        smtp_host: parameters.smtp_host,
        port_number: parameters.port_number,
        username: parameters.username,
        password: parameters.password,
        from_email_address: parameters.from_email_address,
        from_name: parameters.from_name,
        tls_encryption: parameters.tls_encryption,
        is_setup: parameters.is_setup,
      })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
}
