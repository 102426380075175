import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles((theme) => ({
  inputRoot: {
    paddingTop: "8px !important",
    paddingBottom: "7px !important",
  },
  option: {
    "& > span": {
      flex: 1,
    },
  },
  label: {
    fontSize: "smaller",
  },
}));

const filterOptions = () =>
  createFilterOptions({
    stringify: (option) => (option ? option.title : ""),
  });

export default function OptionSelectComponent({
  textFieldProps,
  formProps,
  lang,
}) {
  const classes = useStyle();
  return (
    <Autocomplete
      {...formProps}
      fullWidth={textFieldProps.fullWidth || false}
      options={formProps.options ? [...formProps.options] : []}
      disableClearable
      noOptionsText={getLang(lang, "label.NO_RESULT_FOUND")}
      classes={{
        option: classes.option,
        inputRoot: clsx({
          [classes.inputRoot]: textFieldProps.variant === "filled",
        }),
      }}
      autoHighlight
      filterOptions={filterOptions()}
      getOptionLabel={(option) => (option ? option.title : "-")}
      renderOption={(option) => (
        <React.Fragment>
          <span>{option ? option.title : "-"}</span>
        </React.Fragment>
      )}
      renderInput={(params) => {
        return (
          <TextField
            name="option"
            placeholder={getLang(lang, "placeholder.OPTION")}
            {...params}
            {...textFieldProps}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              style: {
                padding: "5px",
                borderRadius: "4px",
                backgroundColor:
                  textFieldProps.variant === "filled" ? "#ECEFF0" : null,
              },
            }}
            inputProps={{
              ...params.inputProps,
              style: {
                paddingLeft: "8px",
              },
              autoComplete: "off", // disable autocomplete and autofill
            }}
          />
        );
      }}
    />
  );
}
