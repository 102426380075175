import React from "react";
import ProfilePanelComponent from "./profilePanel.component";
import { useSelector } from "react-redux";

function ProfilePanelContainer({ consumer, lang, language }) {
  const companyId = useSelector((state) => state.profile.companyID);

  return (
    <>
      <ProfilePanelComponent
        consumer={consumer}
        lang={lang}
        language={language}
        companyId={companyId}
      />
    </>
  );
}

export default ProfilePanelContainer;
