import React from "react";
import {
  makeStyles,
  Box,
  Typography,
  Divider,
  Button,
  CircularProgress,
  TextField,
  Paper,
} from "@material-ui/core";
import { ErrorMessage, Formik, setNestedObjectValues } from "formik";
import { getLang } from "app/feature/constants";
import GeneralDialog from "components/dialog/generalDialog";
import AlertIcon from "assets/svg/alert.svg";
import * as Yup from "yup";
import { InfoRounded as InfoRoundedIcon } from "@material-ui/icons";
import IntegrationPathSettingCard from "../integrationPathSettingCard";
import parse from "html-react-parser";
import { Skeleton } from "@material-ui/lab";

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 3, 2, 3),
    borderRadius: "4px",
  },
  button: {
    "&:not(:last-child)": {
      marginLeft: "10px",
    },
  },
  contentContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "25px",
    [theme.breakpoints.down("sm")]: {
      rowGap: "25px",
      gridTemplateColumns: "1fr",
    },
  },
  textFieldInput: {
    padding: "7px",
    paddingTop: "8px",
    border: "2px solid #e1e1e1",
    backgroundColor: "#f7f7f7",
    borderRadius: 4,
  },
  inputContainer: {
    display: "grid",
    gridTemplateColumns: "2.5fr 7.5fr",
    columnGap: "20px",
    margin: "8px 0",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "3fr 7fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      row: "4px",
    },
  },
  mappingContainer: {
    display: "grid",
    gridTemplateColumns: "5fr 1fr 3fr",
  },
}));

export default function IntegrationSettingCardComponent({
  integration,
  lang,
  isSubmitting,
  handleSubmit,
  hasIntegrationEditAccessRight,
  pathSettings,
  isFetching,
  additionalFields,
  isEdit,
  setIsEdit,
}) {
  const classes = useStyle();

  const sessionName = {
    DKSH_SAP: "DKSH_SAP_INTEGRATION_SETTINGS",
  };

  return (
    integration && (
      <Paper elevation={3} className={classes.container}>
        <Formik
          initialValues={{
            settings: integration?.settings ?? [],
            pathSettings: pathSettings ?? [],
            selectedIds: pathSettings.length
              ? pathSettings.reduce((selected, setting) => {
                  if (setting.mapping_type === 1) {
                    let adtUuids = setting.settings.reduce((list, s) => {
                      if (s.additional_info) {
                        list.push(s.additional_info.id);
                      }
                      return list;
                    }, []);
                    selected = selected.concat(adtUuids);
                  }
                  return selected;
                }, [])
              : [],
            showConfirmDialog: false,
            isSetup: integration.is_setup,
          }}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={Yup.object({
            settings: Yup.array().of(
              Yup.object({
                settings: Yup.array().of(
                  Yup.object({
                    value: Yup.string()
                      .nullable()
                      .when("required", {
                        is: (value) => !!value,
                        then: Yup.string().required(
                          getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                        ),
                        otherwise: Yup.string().nullable(),
                      }),
                  })
                ),
              })
            ),
            pathSettings: Yup.array().of(
              Yup.object({
                mapping_type: Yup.number(),
                settings: Yup.array().when("mapping_type", {
                  is: 1,
                  then: Yup.array().of(
                    Yup.object({
                      path: Yup.string().required(
                        getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                      ),
                      additional_info: Yup.object()
                        .nullable()
                        .required(
                          getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")
                        ),
                    })
                  ),
                  otherwise: Yup.array().of(
                    Yup.object({
                      value: Yup.string()
                        .nullable()
                        .when("required", {
                          is: (required) => !!required,
                          then: Yup.string().required(
                            getLang(
                              lang,
                              "message.error.FORM_VALIDATE_REQUIRED"
                            )
                          ),
                          otherwise: Yup.string().nullable(),
                        }),
                    })
                  ),
                }),
              })
            ),
          })}
        >
          {(formik) => (
            <form>
              <Box>
                <Typography
                  variant="h6"
                  color="primary"
                  style={{ marginBottom: 8 }}
                >
                  <b>
                    {getLang(lang, `label.${sessionName[integration.type]}`)}
                  </b>
                </Typography>
                <Divider />
                {formik.values.isSetup ? (
                  <Box display="flex" flexDirection="column">
                    <Box mt={2} mb={1} className={classes.contentContainer}>
                      <Box>
                        {formik.values.settings
                          .slice(
                            0,
                            Math.ceil(formik.values.settings.length / 2)
                          )
                          .map((settingGroup, groupIndex) => (
                            <Box key={groupIndex} mt={groupIndex ? 3 : 0}>
                              <Box>
                                <Typography variant="body2">
                                  <b>
                                    {getLang(
                                      lang,
                                      `label.${settingGroup.group}`
                                    )}
                                  </b>
                                </Typography>
                              </Box>
                              {settingGroup.settings.map(
                                (setting, settingIndex) => (
                                  <Box
                                    key={settingIndex}
                                    className={classes.inputContainer}
                                  >
                                    <Box mt={1}>
                                      <Typography
                                        variant="body2"
                                        style={{ fontWeight: 500 }}
                                      >
                                        {getLang(lang, `label.${setting.key}`)}
                                        {setting.required && (
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        )}
                                      </Typography>
                                    </Box>
                                    <Box
                                      flex={1}
                                      display="flex"
                                      alignItems="center"
                                      minHeight={38}
                                    >
                                      {isEdit ? (
                                        <TextField
                                          {...formik.getFieldProps(
                                            `settings[${groupIndex}].settings[${settingIndex}].value`
                                          )}
                                          fullWidth
                                          size="small"
                                          margin="none"
                                          placeholder={getLang(
                                            lang,
                                            `label.${setting.key}`
                                          )}
                                          InputProps={{
                                            disableUnderline: true,
                                            margin: "none",
                                          }}
                                          disabled={
                                            !hasIntegrationEditAccessRight ||
                                            isSubmitting
                                          }
                                          inputProps={{
                                            className: classes.textFieldInput,
                                          }}
                                          value={setting.value || ""}
                                          error={
                                            formik.errors.settings?.[groupIndex]
                                              ?.settings?.[settingIndex]
                                              ?.value &&
                                            formik.touched.settings?.[
                                              groupIndex
                                            ]?.settings?.[settingIndex]?.value
                                          }
                                          helperText={ErrorMessage({
                                            name: `settings[${groupIndex}].settings[${settingIndex}].value`,
                                          })}
                                        />
                                      ) : (
                                        <Typography variant="body2">
                                          {setting.value || "-"}
                                        </Typography>
                                      )}
                                    </Box>
                                  </Box>
                                )
                              )}
                            </Box>
                          ))}
                      </Box>
                      {formik.values.settings.length >= 2 && (
                        <Box>
                          {formik.values.settings
                            .slice(Math.ceil(formik.values.settings.length / 2))
                            .map((settingGroup, groupIndex) => (
                              <Box key={groupIndex} mt={groupIndex ? 3 : 0}>
                                <Box>
                                  <Typography variant="body2">
                                    <b>
                                      {getLang(
                                        lang,
                                        `label.${settingGroup.group}`
                                      )}
                                    </b>
                                  </Typography>
                                </Box>
                                {settingGroup.settings.map(
                                  (setting, settingIndex) => (
                                    <Box
                                      key={settingIndex}
                                      className={classes.inputContainer}
                                    >
                                      <Box mt={1}>
                                        <Typography
                                          variant="body2"
                                          style={{ fontWeight: 500 }}
                                        >
                                          {getLang(
                                            lang,
                                            `label.${setting.key}`
                                          )}
                                          {setting.required && (
                                            <span style={{ color: "red" }}>
                                              {" "}
                                              *
                                            </span>
                                          )}
                                        </Typography>
                                      </Box>
                                      <Box
                                        flex={1}
                                        display="flex"
                                        alignItems="center"
                                        minHeight={38}
                                      >
                                        {isEdit ? (
                                          <TextField
                                            {...formik.getFieldProps(
                                              `settings[${
                                                Math.ceil(
                                                  formik.values.settings
                                                    .length / 2
                                                ) + groupIndex
                                              }].settings[${settingIndex}].value`
                                            )}
                                            fullWidth
                                            size="small"
                                            margin="none"
                                            placeholder={getLang(
                                              lang,
                                              `label.${setting.key}`
                                            )}
                                            InputProps={{
                                              disableUnderline: true,
                                              margin: "none",
                                            }}
                                            inputProps={{
                                              className: classes.textFieldInput,
                                            }}
                                            value={setting.value || ""}
                                            disabled={
                                              !hasIntegrationEditAccessRight ||
                                              isSubmitting
                                            }
                                            error={
                                              formik.errors.settings?.[
                                                Math.ceil(
                                                  formik.values.settings
                                                    .length / 2
                                                ) + groupIndex
                                              ]?.settings?.[settingIndex]
                                                ?.value &&
                                              formik.touched.settings?.[
                                                Math.ceil(
                                                  formik.values.settings
                                                    .length / 2
                                                ) + groupIndex
                                              ]?.settings?.[settingIndex]?.value
                                            }
                                            helperText={ErrorMessage({
                                              name: `settings[${
                                                Math.ceil(
                                                  formik.values.settings
                                                    .length / 2
                                                ) + groupIndex
                                              }].settings[${settingIndex}].value`,
                                            })}
                                          />
                                        ) : (
                                          <Typography variant="body2">
                                            {setting.value || "-"}
                                          </Typography>
                                        )}
                                      </Box>
                                    </Box>
                                  )
                                )}
                              </Box>
                            ))}
                        </Box>
                      )}
                    </Box>
                    <Divider />
                    <Box mt={2}>
                      <Box>
                        <Typography variant="body2">
                          <b>{getLang(lang, "label.API_PATH_SETTINGS")}</b>
                        </Typography>
                      </Box>
                      <Box>
                        {isFetching ? (
                          <Box mt={2}>
                            <Skeleton
                              variant="text"
                              animation="wave"
                              height={25}
                            />
                            <Skeleton
                              variant="text"
                              animation="wave"
                              height={25}
                            />
                            <Skeleton
                              variant="text"
                              animation="wave"
                              height={25}
                            />
                            <Skeleton
                              variant="text"
                              animation="wave"
                              height={25}
                            />
                          </Box>
                        ) : formik.values.pathSettings.length ? (
                          formik.values.pathSettings.map(
                            (setting, phaseIndex) => (
                              <IntegrationPathSettingCard
                                key={phaseIndex}
                                setting={setting}
                                phaseIndex={phaseIndex}
                                formik={formik}
                                isEdit={isEdit}
                              />
                            )
                          )
                        ) : (
                          <Paper elevation={3} style={{ marginTop: 16 }}>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              height={50}
                            >
                              <Typography variant="body2">
                                {getLang(lang, "label.NO_DATA_AVAILABLE")}
                              </Typography>
                            </Box>
                          </Paper>
                        )}
                      </Box>
                    </Box>
                    {hasIntegrationEditAccessRight && (
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        mt={3}
                        style={{ gap: 16 }}
                      >
                        {isEdit && (
                          <Button
                            variant="outlined"
                            disableElevation
                            color="primary"
                            onClick={async () => {
                              setIsEdit(false);
                              formik.resetForm();
                            }}
                            className={classes.button}
                            disabled={isSubmitting}
                          >
                            <Typography variant="body2">
                              {getLang(lang, "label.CANCEL")}
                            </Typography>
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          disableElevation
                          color={isEdit ? "secondary" : "primary"}
                          onClick={async () => {
                            if (isEdit) {
                              const errors = await formik.validateForm();

                              if (Object.keys(errors).length === 0) {
                                formik.setFieldValue("showConfirmDialog", true);
                              } else {
                                formik.setTouched(
                                  setNestedObjectValues(errors, true)
                                );
                              }
                            } else {
                              setIsEdit(true);
                            }
                          }}
                          className={classes.button}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <CircularProgress
                              size={20}
                              style={{ color: "white" }}
                            />
                          ) : (
                            <Typography variant="body2">
                              {getLang(
                                lang,
                                isEdit ? "label.UPDATE" : "label.EDIT"
                              )}
                            </Typography>
                          )}
                        </Button>
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box
                    p={3}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ gap: 8 }}
                      mr={1}
                    >
                      <InfoRoundedIcon />
                      <Typography variant="body2">
                        {hasIntegrationEditAccessRight
                          ? parse(
                              getLang(lang, "message.info.API_NOT_CONFIGURED", {
                                button: `<b>${getLang(
                                  lang,
                                  "label.SETUP_NOW"
                                ).toUpperCase()}</b>`,
                              })
                            )
                          : getLang(
                              lang,
                              "message.info.API_NOT_CONFIGURED_ADMIN"
                            )}
                      </Typography>
                    </Box>
                    {hasIntegrationEditAccessRight && (
                      <Button
                        variant="contained"
                        disableElevation
                        color={"secondary"}
                        onClick={async () => {
                          formik.setFieldValue("isSetup", true);
                          setIsEdit(true);
                        }}
                        className={classes.button}
                        disabled={isSubmitting}
                      >
                        <Typography variant="body2">
                          {getLang(lang, "label.SETUP_NOW")}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
              <GeneralDialog
                isOpen={formik.values.showConfirmDialog}
                handleClose={() =>
                  formik.setFieldValue("showConfirmDialog", false)
                }
                handleProceed={() => {
                  formik.setFieldValue("showConfirmDialog", false);
                  formik.handleSubmit();
                }}
                isLoading={isSubmitting}
                type="danger"
                description={getLang(
                  lang,
                  "paragraph.UPDATE_INTEGRATION_SETTING_CONFIRMATION"
                )}
                title={getLang(lang, "label.CONFIRMATION_NEEDED")}
                icon={<img src={AlertIcon} alt="alert" />}
              />
            </form>
          )}
        </Formik>
      </Paper>
    )
  );
}
