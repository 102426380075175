import React from "react";
import {
  Box,
  Paper,
  Typography,
  makeStyles,
  Divider,
} from "@material-ui/core";
import { Skeleton } from '@material-ui/lab';
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import { getLang } from "app/feature/constants";
import { surveyQuestionType } from "modules/survey/constants";

const useStyle = makeStyles((theme) => ({
  container: {
    boxShadow: "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);",
    borderRadius: 6,
    padding: theme.spacing(3, 2),
  },
  perfectScrollbar: {
    height: 400,
    marginTop: theme.spacing(2),
    padding: theme.spacing(1)
  },
  questionContainer: {
    padding: 12,
    border: "1px solid #D0D5DD",
    borderRadius: 6,
    marginBottom: 12,
    cursor: "pointer",
  },
  selected: {
    border: "1px solid #6AAF68",
    boxShadow: "0px 0px 0px 4px #E5F1E4",
    cursor: "pointer",
  }
}));

export default function SurveyQuestionListingCardComponent({
  data,
  selected,
  handleSelectedQuestion,
  isQuestionFetching,
  lang
}) {
  const classes = useStyle();

  const displayQuestionType = (type) => {
    let questionType = "";

    switch (type) {
      case surveyQuestionType.text:
        questionType = getLang(lang, 'label.TEXT_FIELD')
        break;
      case surveyQuestionType.selection:
        questionType = getLang(lang, 'label.MULTIPLE_CHOICE')
        break;
      case surveyQuestionType.dropdownlist:
        questionType = getLang(lang, 'label.DROPDOWN_LIST')
        break;
      case surveyQuestionType.multipleChoiceMultiSelect:
        questionType = getLang(lang, "label.MULTIPLE_CHOICE_MULTI_SELECT")
        break;
      case surveyQuestionType.multiStarRating:
        questionType = getLang(lang, "label.MULTI_STAR_RATING")
        break;
      case surveyQuestionType.starRating:
        questionType = getLang(lang, "label.STAR_RATING")
        break;
      default:
        break;
    }

    return questionType
  }

  return (
    <Paper variant='outlined' className={classes.container}>
      <Box style={{ marginBottom: 12 }}>
        <Typography variant="subtitle1" color='textPrimary' style={{ fontWeight: "500" }}>{getLang(lang, 'label.RESULT')}</Typography>
      </Box>
      <Divider />
      <PerfectScrollbar className={classes.perfectScrollbar}>
        {isQuestionFetching ? (
          <>
            <Skeleton variant='rect' animation="wave" height={64} style={{ marginBottom: 12 }} />
            <Skeleton variant='rect' animation="wave" height={64} style={{ marginBottom: 12 }} />
            <Skeleton variant='rect' animation="wave" height={64} style={{ marginBottom: 12 }} />
            <Skeleton variant='rect' animation="wave" height={64} style={{ marginBottom: 12 }} />
            <Skeleton variant='rect' animation="wave" height={64} style={{ marginBottom: 12 }} />
          </>
        ) : data.map((row, index) => (
          <Box
            key={row + index}
            className={clsx(classes.questionContainer, {
              [classes.selected]: selected.uuid === row.id
            })}
            onClick={() => handleSelectedQuestion(row, index + 1)}
          >
            <Box>
              <Typography variant='body2' style={{ color: "#98A2B3", fontSize: 12 }}>
                {getLang(lang, 'label.QUESTION')} {index + 1} - {displayQuestionType(row.type)}
              </Typography>
            </Box>
            <Box>
              <Typography variant='body2' style={{ color: "#344054", fontSize: 14, fontWeight: 500 }}>{row.title}</Typography>
            </Box>
          </Box>
        ))}
      </PerfectScrollbar>
    </Paper>
  )
}
