import React, { useContext } from "react";
import DefaultDashboardPanelComponent from "./defaultDashboardPanel.page";
import { useSelector } from "react-redux";
import { DashboardContext } from "modules/dashboard/pages/dashboard/dashboard.container";

function DefaultDashboardPanelContainer({ isCollapsible }) {
  const lang = useSelector((state) => state.constant.languages);
  const { currentFilter, activeIndex, showPopup, closePopup, popupIsShow } =
    useContext(DashboardContext);

  return (
    <DefaultDashboardPanelComponent
      currentFilter={currentFilter}
      lang={lang}
      activeIndex={activeIndex}
      showPopup={showPopup}
      closePopup={closePopup}
      popupIsShow={popupIsShow}
      isCollapsible={isCollapsible}
    />
  );
}

export default DefaultDashboardPanelContainer;
