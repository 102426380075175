import React, { useEffect, useState } from "react";
import Co2FootprintComponent from "./co2Footprint.component";
import { useSelector } from "react-redux";

export default function Co2FootprintContainer({ id, isTemplateEditor }) {
  const { isVisible } = useSelector((state) =>
    isTemplateEditor
      ? state.products.template.workspaceSetting.setting.components[id]
      : state.productPage.components[id]
  );
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const customFields = useSelector((state) => state.productPage.customFields);

  const [value, setValue] = useState(0);

  useEffect(() => {
    if (customFields) {
      const customField = customFields.find(
        (field) => field.name?.EN === "CO₂ Footprint (gCO₂e)"
      );
      if (customField) {
        setValue(customField.value);
      }
    }
  }, [customFields]);

  return (
    isVisible && (
      <Co2FootprintComponent lang={lang} value={value} language={language} />
    )
  );
}
