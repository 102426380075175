import React from "react";
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { getLang } from "app/feature/constants";
import { Skeleton } from "@material-ui/lab";
import StarRatingBackground from "assets/img/starRatingBackground.png";
import RefreshTable from "components/table/refreshTable";

const useStyles = makeStyles((_) => ({
  panel: {
    borderRadius: 12,
    background: "white",
    border: "1px solid #D0D5DD",
    padding: 16,
    paddingRight: 0,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  listContainer: {
    height: 250,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "0.3em",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "rgba(255,255,255,1)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 4,
      backgroundColor: "rgba(0, 0, 0, .5)",
    },
  },
  star: {
    backgroundImage: `url(${StarRatingBackground})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    height: 42,
    width: 38,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    "& th, td": {
      padding: 8,
      borderBottom: "none",
    },
  },
}));

export default function MostInterestedPackagingFeaturePanelComponent({
  rankList,
  isLoading,
  lang,
  isError,
  handleReload,
  language
}) {
  const classes = useStyles();

  const SkeletonTableRow = () => {
    return (
      <TableRow>
        {[...new Array(7)].map((id) => (
          <TableCell key={id}>
            <Skeleton variant="text" animation="wave" />
          </TableCell>
        ))}
      </TableRow>
    );
  };

  return (
    <Box className={classes.panel}>
      <TableContainer className={classes.table}>
        {isError ? (
          <RefreshTable handleReload={handleReload} />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {getLang(
                    lang,
                    "label.MOST_INTERESTED_CONNECTED_PACKAGING_FEATURES"
                  )}
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" justifyContent="center">
                    <Box className={classes.star}>
                      <b>1</b>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" justifyContent="center">
                    <Box className={classes.star}>
                      <b>2</b>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" justifyContent="center">
                    <Box className={classes.star}>
                      <b>3</b>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" justifyContent="center">
                    <Box className={classes.star}>
                      <b>4</b>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" justifyContent="center">
                    <Box className={classes.star}>
                      <b>5</b>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <b>{getLang(lang, "label.MEAN_STARS")}</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <>
                  <SkeletonTableRow />
                  <SkeletonTableRow />
                  <SkeletonTableRow />
                  <SkeletonTableRow />
                  <SkeletonTableRow />
                </>
              ) : rankList.length ? (
                rankList.map((row, index) => {
                  let highestCount = row.rating.count_1;
                  if (row.rating.count_2 > highestCount) {
                    highestCount = row.rating.count_2;
                  }

                  if (row.rating.count_3 > highestCount) {
                    highestCount = row.rating.count_3;
                  }

                  if (row.rating.count_4 > highestCount) {
                    highestCount = row.rating.count_4;
                  }

                  if (row.rating.count_5 > highestCount) {
                    highestCount = row.rating.count_5;
                  }

                  return (
                    <TableRow key={index}>
                      <TableCell>
                        {language === "TH" ? row.name[1] : row.name[0]}
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="body2"
                          style={{
                            color:
                              highestCount <= row.rating.count_1
                                ? "#29CC6A"
                                : "black",
                          }}
                        >
                          <b>{row.rating.count_1}</b>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="body2"
                          style={{
                            color:
                              highestCount <= row.rating.count_2
                                ? "#29CC6A"
                                : "black",
                          }}
                        >
                          <b>{row.rating.count_2}</b>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="body2"
                          style={{
                            color:
                              highestCount <= row.rating.count_3
                                ? "#29CC6A"
                                : "black",
                          }}
                        >
                          <b>{row.rating.count_3}</b>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="body2"
                          style={{
                            color:
                              highestCount <= row.rating.count_4
                                ? "#29CC6A"
                                : "black",
                          }}
                        >
                          <b>{row.rating.count_4}</b>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="body2"
                          style={{
                            color:
                              highestCount <= row.rating.count_5
                                ? "#29CC6A"
                                : "black",
                          }}
                        >
                          <b>{row.rating.count_5}</b>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography
                          variant="body2"
                          style={{
                            color: !index ? "#29CC6A" : "black",
                          }}
                        >
                          <b>{row.rating.mean}</b>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableCell colSpan={7}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    height={150}
                  >
                    <Typography
                      variant="body2"
                      color="primary"
                      style={{ fontWeight: "bold" }}
                    >
                      {getLang(lang, "label.NO_DATA_AVAILABLE")}
                    </Typography>
                  </Box>
                </TableCell>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </Box>
  );
}
