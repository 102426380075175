import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import CO2Icon from "../../../utils/img/co2Icon.png";

const useStyle = makeStyles((theme) => ({
  container: {
    boxShadow: "0px 3px 7px 0px #00000029",
    borderRadius: 16,
    backgroundColor: "white",
    padding: 20,
  },
  icon: {
    width: "30%",
    "& img": {
      width: "100%",
    },
  },
}));

export default function Co2FootprintComponent({ lang, value, language }) {
  const classes = useStyle();

  return (
    <Box p={2}>
      <Box className={classes.container}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          mb={1.5}
        >
          <Box className={classes.icon}>
            <img src={CO2Icon} alt="icon" />
          </Box>
          <Box display="flex" alignItems="flex-end" style={{ gap: 12 }}>
            <Typography
              variant="body2"
              style={{
                fontFamily: language === "TH" ? "Kanit" : "Inter",
                color: "#596780",
                fontSize: 38,
              }}
            >
              <b>{value}</b>
            </Typography>
            <Typography
              variant="body2"
              style={{
                fontFamily: language === "TH" ? "Kanit" : "Inter",
                color: "#596780",
                fontSize: 24,
                marginBottom: 4,
              }}
            >
              <b>
                gCO<sub>2</sub>e
              </b>
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="body2"
          style={{
            fontFamily: language === "TH" ? "Kanit" : "Inter",
            color: "#596780",
          }}
          align="center"
        >
          {getLang(lang, "paragraph.CFP_CERTIFIED_SCGP")}
        </Typography>
      </Box>
    </Box>
  );
}
