import React, { useContext } from "react";
import TcgDashboardPanelComponent from "./tcgDashboardPanel.page";
import { useSelector } from "react-redux";
import { DashboardContext } from "modules/dashboard/pages/dashboard/dashboard.container";

function TcgDashboardPanelContainer() {
  const lang = useSelector((state) => state.constant.languages);
  const { currentFilter, activeIndex } = useContext(DashboardContext);
  const [expandRegistrationPanel, setExpandRegistrationPanel] = React.useState(true);
  const [expandSolutionPanel, setExpandSolutionPanel] = React.useState(true);
  const [expandMockupPanel, setExpandMockupPanel] = React.useState(true);
  const [expandSurveyPanel, setExpandSurveyPanel] = React.useState(true);

  const handleExpandRegistrationPanel = () => {
    setExpandRegistrationPanel(!expandRegistrationPanel);
  };

  const handleExpandSolutionPanel = () => {
    setExpandSolutionPanel(!expandSolutionPanel);
  };

  const handleExpandMockupPanel = () => {
    setExpandMockupPanel(!expandMockupPanel);
  };

  const handleExpandSurveyPanel = () => {
    setExpandSurveyPanel(!expandSurveyPanel);
  };

  return (
    <TcgDashboardPanelComponent
      currentFilter={currentFilter}
      lang={lang}
      activeIndex={activeIndex}
      expandRegistrationPanel={expandRegistrationPanel}
      handleExpandRegistrationPanel={handleExpandRegistrationPanel}
      expandSolutionPanel={expandSolutionPanel}
      handleExpandSolutionPanel={handleExpandSolutionPanel}
      expandMockupPanel={expandMockupPanel}
      handleExpandMockupPanel={handleExpandMockupPanel}
      expandSurveyPanel={expandSurveyPanel}
      handleExpandSurveyPanel={handleExpandSurveyPanel}
    />
  );
}

export default TcgDashboardPanelContainer;
