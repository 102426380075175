import React from "react";
import WebVideoFormComponent from "./webVideoForm.component";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const TYPES = ["new", "edit", "readonly"];

function WebVideoLinkContainer({ type, handleResult, initialValues, handleDelete, handleClose }) {

  const lang = useSelector(state => state.constant.languages)

  return (
    <WebVideoFormComponent
      type={type}
      handleSubmit={handleResult}
      handleDelete={handleDelete}
      initialValues={initialValues}
      lang={lang}
      handleClose={handleClose}
    />
  );
}

WebVideoLinkContainer.defaultProps = {
  type: TYPES[0],
  initialValues: { URL: "" }
};

WebVideoLinkContainer.propTypes = {
  type: PropTypes.oneOf(TYPES).isRequired,
  handleResult: PropTypes.func,
  handleDelete: PropTypes.func,
  initialValues: PropTypes.object
};

export { TYPES };

export default WebVideoLinkContainer;
