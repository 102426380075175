import React, { useState } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import TrendingSummaryPanel from "modules/dashboard/components/panel/trendingSummaryPanel";
import ScanAgainstSuspendPanel from "modules/dashboard/components/panel/scanAgainstSuspendPanel";
import TopScanCitiesPanel from "modules/dashboard/components/panel/topScanCitiesPanel";
import TopScanProductsPanel from "modules/dashboard/components/panel/topScanProductsPanel";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from "@material-ui/icons";
import moment from "moment/moment";
import SerialNumberFirstScanPopUp from "../../dialog/serialNumberFirstScanPopup";
import UniqueConsumerPopUp from "../../dialog/uniqueConsumerPopup";
import NewConsumerPopUp from "../../dialog/newConsumerPopup";
import ScanMapCard from "../../card/scanMapCard";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: "bold",
  },
  panel: {
    background: "white",
    border: "1px solid #D0D5DD",
    borderRadius: 12,
    padding: 20,
  },
  tabButton: {
    textTransform: "none",
  },
  twoColumn: {
    overflow: "hidden",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      rowGap: theme.spacing(2),
    },
  },
  collapseButton: {
    backgroundColor: "#F9FAFB",
    border: "1px solid #D0D5DD",
    padding: "8px 20px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    cursor: "pointer",
  },
  datePicker: {
    "& .rs-picker-default .rs-picker-toggle.rs-btn": {
      borderRadius: 4,
    },
  },
}));

export default function DefaultDashboardPanelComponent({
  currentFilter,
  activeIndex,
  showPopup,
  closePopup,
  popupIsShow,
  isCollapsible,
  lang,
}) {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);

  return (
    <Box>
      <TrendingSummaryPanel
        dateRange={currentFilter.dateRange}
        isToday={activeIndex === 1}
        isMonth={activeIndex === 2 || activeIndex === 3}
        showPopup={(name) => showPopup(name)}
      />
      <SerialNumberFirstScanPopUp
        isOpen={popupIsShow["serialNumberFirstScan"]}
        handleClose={() => closePopup("serialNumberFirstScan")}
        startDate={moment(currentFilter.dateRange[0]).format("YYYY-MM-DD")}
        endDate={moment(currentFilter.dateRange[1]).format("YYYY-MM-DD")}
      />
      <UniqueConsumerPopUp
        isOpen={popupIsShow["uniqueConsumer"]}
        handleClose={() => closePopup("uniqueConsumer")}
        startDate={moment(currentFilter.dateRange[0]).format("YYYY-MM-DD")}
        endDate={moment(currentFilter.dateRange[1]).format("YYYY-MM-DD")}
      />
      <NewConsumerPopUp
        isOpen={popupIsShow["newConsumer"]}
        handleClose={() => closePopup("newConsumer")}
        startDate={moment(currentFilter.dateRange[0]).format("YYYY-MM-DD")}
        endDate={moment(currentFilter.dateRange[1]).format("YYYY-MM-DD")}
      />
      <Box className={classes.panel}>
        <Box mb={2}>
          <Typography variant="body1">
            <b>{getLang(lang, "label.CONSUMER_SCAN_GEOLOCATION")}</b>
          </Typography>
        </Box>
        <ScanMapCard
          lang={lang}
          startDate={currentFilter.dateRange[0]}
          endDate={currentFilter.dateRange[1]}
        />
        <Box
          className={classes.twoColumn}
          mt={2}
          id="container"
          style={{ maxHeight: isCollapsible && !expand ? 372 : "fit-content" }}
        >
          <TopScanCitiesPanel dateRange={currentFilter.dateRange} />
          <TopScanProductsPanel dateRange={currentFilter.dateRange} />
        </Box>
        {!!isCollapsible && (
          <Box
            className={classes.collapseButton}
            onClick={() => {
              setExpand((prevVal) => !prevVal);
            }}
            mt={2}
          >
            {expand ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            <Typography variant="body2">
              <b>{expand ? "Collapse" : "Expand"}</b>
            </Typography>
          </Box>
        )}
      </Box>
      <ScanAgainstSuspendPanel dateRange={currentFilter.dateRange} />
    </Box>
  );
}
