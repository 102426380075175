import React from "react";
import { Box, Paper, Typography, makeStyles, Divider } from "@material-ui/core";
import BarChart from "../../visualisation/barChart";
import { getLang } from "app/feature/constants";

const useStyle = makeStyles(() => ({
  container: {
    boxShadow:
      "0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);",
    borderRadius: 6,
    padding: "12px",
  },
  optionContainer: {
    padding: 16,
    borderRadius: 8,
    backgroundColor: "#f5f5f5",
    display: "flex",
    gap: 8,
  },
}));

export default function SurveyChoiceOverviewCardComponent({
  data,
  labels,
  lang,
  type,
}) {
  const classes = useStyle();

  return (
    <Paper className={classes.container} variant="outlined">
      <Box style={{ margin: "12px" }}>
        <Box style={{ marginBottom: "12px" }}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            style={{ fontWeight: "400" }}
          >
            {getLang(lang, "label.BAR_CHART")}
          </Typography>
        </Box>
        <Divider />
        <Box style={{ paddingTop: 16 }}>
          <BarChart data={data} labels={labels} />
        </Box>
      </Box>
    </Paper>
  );
}
