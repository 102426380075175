import React, { useContext, useEffect, useState } from "react";
import TcgTrendingSummaryCardComponent from "./tcgTrendingSummaryCard.component";
import { useDispatch, useSelector } from "react-redux";
import { DashboardContext } from "modules/dashboard/pages/dashboard/dashboard.container";
import moment from "moment";
import { addAlert } from "modules/notification";
import dashboardApi from "app/api/dashboard";
import { getApiLang } from "app/feature/constants";
import { trendingSummaryCardType } from "modules/dashboard/constants";

function TcgTrendingSummaryCardContainer({ type }) {
  const { currentFilter, activeIndex } = useContext(DashboardContext);
  const dispatch = useDispatch();

  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const [summary, setSummary] = useState({ data: null, isError: false });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilter.dateRange]);

  const getData = () => {
    setIsLoading(true);
    setSummary({
      ...summary,
      isError: false,
    });

    let param = {
      start_date: moment(currentFilter.dateRange[0]).format("YYYY-MM-DD"),
      end_date: moment(currentFilter.dateRange[1]).format("YYYY-MM-DD"),
      is_today: activeIndex === 1 ? 1 : 0,
      is_month: activeIndex === 2 || activeIndex === 3 ? 1 : 0,
    };

    switch (type) {
      case trendingSummaryCardType.consumerScanned:
        dashboardApi
          .getConsumerScannedSummary(param)
          .then((res) => {
            setSummary({
              data: res.data,
              isError: false,
            });
          })
          .catch((error) => {
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, error.code),
              })
            );
            setSummary({
              ...summary,
              isError: true,
            });
          })
          .finally(() => {
            setIsLoading(false);
          });

        break;
      case trendingSummaryCardType.suspendSn:
        dashboardApi
          .getSuspendSnSummary(param)
          .then((res) => {
            setSummary({
              data: res.data,
              isError: false,
            });
          })
          .catch((error) => {
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, error.code),
              })
            );
            setSummary({
              ...summary,
              isError: true,
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case trendingSummaryCardType.counterfeitReport:
        dashboardApi
          .getCounterfeitReportSummary(param)
          .then((res) => {
            setSummary({
              data: res.data,
              isError: false,
            });
          })
          .catch((error) => {
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, error.code),
              })
            );
            setSummary({
              ...summary,
              isError: true,
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case trendingSummaryCardType.totalRegistration:
        dashboardApi
          .getTotalRegistration(param)
          .then((res) => {
            setSummary({
              data: res.data,
              isError: false,
            });
          })
          .catch((error) => {
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, error.code),
              })
            );
            setSummary({
              ...summary,
              isError: true,
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case trendingSummaryCardType.cumulativeRegistration:
        dashboardApi
          .getCumulativeRegistration(param)
          .then((res) => {
            setSummary({
              data: res.data,
              isError: false,
            });
          })
          .catch((error) => {
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, error.code),
              })
            );
            setSummary({
              ...summary,
              isError: true,
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case trendingSummaryCardType.totalScannedSolutionHighlight:
        dashboardApi
          .getTotalScannedSolutionHighlight(param)
          .then((res) => {
            setSummary({
              data: res.data,
              isError: false,
            });
          })
          .catch((error) => {
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, error.code),
              })
            );
            setSummary({
              ...summary,
              isError: true,
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case trendingSummaryCardType.counterfeitReportReceived:
        dashboardApi
          .getCounterfeitReportSummary(param)
          .then((res) => {
            setSummary({
              data: res.data,
              isError: false,
            });
          })
          .catch((error) => {
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, error.code),
              })
            );
            setSummary({
              ...summary,
              isError: true,
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      case trendingSummaryCardType.totalMockupScan:
        dashboardApi
          .getTotalMockupScan(param)
          .then((res) => {
            setSummary({
              data: res.data,
              isError: false,
            });
          })
          .catch((error) => {
            dispatch(
              addAlert({
                severity: "error",
                message: getApiLang(lang, error.code),
              })
            );
            setSummary({
              ...summary,
              isError: true,
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
        break;
      default:
        break;
    }
  };

  return (
    <TcgTrendingSummaryCardComponent
      language={language}
      data={summary.data}
      loading={isLoading}
      lang={lang}
      type={type}
      startDate={moment(currentFilter?.dateRange[0]).format("YYYY-MM-DD")}
      endDate={moment(currentFilter?.dateRange[1]).format("YYYY-MM-DD")}
    />
  );
}

export default TcgTrendingSummaryCardContainer;
