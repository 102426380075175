import React from "react";
import OptionSelectComponent from "./optionSelect.component";
import { useSelector } from "react-redux";

function OptionSelectContainer({ textFieldProps, formProps }) {
  const lang = useSelector((state) => state.constant.languages);

  return (
    <OptionSelectComponent
      textFieldProps={textFieldProps}
      formProps={formProps}
      lang={lang}
    />
  );
}

export default OptionSelectContainer;
