import React, { useEffect, useState } from "react";
import { makeStyles, Box, Typography, Divider, Tabs } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import CustomTab from "components/tab";
import { DateRangePicker } from "rsuite";
import { Alert } from "@material-ui/lab";
import DefaultDashboardPanel from "modules/dashboard/components/panel/defaultDashboardPanel";
import TcgDashboardPanel from "modules/dashboard/components/panel/tcgDashboardPanel";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: "bold",
  },
  panel: {
    background: "white",
    border: "1px solid #D0D5DD",
    borderRadius: 12,
    padding: 20,
  },
  tabButton: {
    textTransform: "none",
  },
  twoColumn: {
    overflow: "hidden",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      rowGap: theme.spacing(2),
    },
  },
  collapseButton: {
    backgroundColor: "#F9FAFB",
    border: "1px solid #D0D5DD",
    padding: "8px 20px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    cursor: "pointer",
  },
  datePicker: {
    "& .rs-picker-default .rs-picker-toggle.rs-btn": {
      borderRadius: 4,
    },
  },
}));

export default function MainPageComponent({
  currentFilter,
  updateFilterDate,
  lang,
  activeIndex,
  setActiveIndex,
  showMaintenanceAlert,
  companyId,
}) {
  const classes = useStyles();
  const [isCollapsible, setIsCollapsible] = useState(false);

  const options = [
    getLang(lang, "label.LAST_COUNT_DAYS", { day: 14 }),
    getLang(lang, "label.TODAY"),
    getLang(lang, "label.THIS_MONTH"),
    getLang(lang, "label.LAST_MONTH"),
    getLang(lang, "label.CUSTOM_DATE"),
  ];

  function a11yProps(index, item) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tab-${index}`,
      label: (
        <Typography
          variant="button"
          classes={{ button: classes.tabButton }}
          children={item}
        />
      ),
    };
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(onResize);
    resizeObserver.observe(document.getElementById("container"));
  }, []);

  const onResize = (entries) => {
    const div = entries[0];
    if (div.contentRect.height > 372) {
      setIsCollapsible(true);
    }
  };

  const isTcg = process.env.REACT_APP_ENV === "PRODUCTION" && companyId === 183;

  return (
    <Box>
      {showMaintenanceAlert && (
        <Box mb={2}>
          <Alert severity="warning">
            {getLang(lang, "paragraph.MAINTENANCE_ALERT")}
          </Alert>
        </Box>
      )}
      <Box mb={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box mr={2}>
            <Typography color="primary" className={classes.title}>
              <b>{getLang(lang, "label.DASHBOARD")}</b>
            </Typography>
          </Box>
          <Tabs
            value={activeIndex}
            onChange={(e, index) => setActiveIndex(index)}
            aria-label="scrollable auto tabs example"
            textColor="primary"
            variant="scrollable"
            indicatorColor="primary"
            classes={{
              root: classes.tab,
              indicator: classes.indicator,
              scrollButtons: classes.scroll,
            }}
          >
            {options.map((item, index) => (
              <CustomTab
                className={classes.tab}
                key={index}
                {...a11yProps(index, item)}
              />
            ))}
          </Tabs>
        </Box>
        <Divider />
        <Box
          display="flex"
          alignContent="center"
          flexWrap="wrap"
          justifyContent="flex-end"
          mb={1}
          mt={1.5}
          className={classes.datePicker}
        >
          <DateRangePicker
            character=" - "
            cleanable={false}
            placeholder={getLang(lang, "placeholder.SELECT_DATE_RANGE")}
            format="dd MMM yyyy"
            placement="auto"
            disabledDate={(date) => date.getTime() > Date.now()}
            editable={false}
            onChange={(value) => {
              updateFilterDate(value[0], value[1]);
            }}
            value={currentFilter.dateRange}
            ranges={[]}
          />
        </Box>
      </Box>
      {isTcg ? (
        <TcgDashboardPanel isCollapsible={isCollapsible} />
      ) : (
        <DefaultDashboardPanel isCollapsible={isCollapsible} />
      )}
    </Box>
  );
}
