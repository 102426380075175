import React, { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import { StyledTableCell, StyledTableRow } from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import { ActionButton } from "components/button";
import { TablePaginationActions } from "components/tablePagination";
import Alert from "@material-ui/lab/Alert";
import { progressStatus } from "lib/constants/luckyDrawProgress";
import { actionList } from "./luckyDrawProgress";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import StickyLeftTableCell from "components/table/stickyLeftTableCell";
import StickyRightTableCell from "components/table/stickyRightTableCell";
import ProductCountDetailPopover from "../../../../../components/popover/productCountDetailPopover";
import moment from "moment";
import "moment/min/locales.min";
import DefaultImg from "assets/img/img-default.png";
import {
  TYPE_TRADITIONAL_RAFFLE,
  TYPE_INSTANT_WIN,
  TYPE_INTERVAL_RAFFLE,
} from "../../../constants";

const useStyle = makeStyles((theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto",
  },
  emptyContainer: {
    textAlign: "center",
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1),
  },
  iconBtn: {
    borderRadius: theme.spacing(0.5),
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    padding: "3px 5px",
  },
  attributeText: {
    color: "#b2b2b2",
    fontSize: 12,
    whiteSpace: "break-spaces",
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export default function LuckyDrawListingTableComponent({
  hasLuckyDrawEditAccessRight,
  data,
  isFetching,
  page,
  search,
  rowsPerPage,
  totalRecords,
  handleChangePage,
  handleChangeRowsPerPage,
  handleViewParticipants,
  handleView,
  handleStatusChange,
  handleViewPrize,
  language,
  handleViewWinner,
  handleLiveDraw,
  handleDownloadWinner,
  handleFetchLuckyDrawList,
  isLoadingLuckyDrawError,
  paginationOptions,
  lang,
  handleAddLuckyDraw,
  handleAddInstantWin,
  handleBinding,
  handlePublished,
  handleEnd,
  handleJoinClose,
  companyId,
}) {
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openImage, setOpenImage] = useState(false);
  const [imgSrc, setImgSrc] = useState(null);
  const open = Boolean(anchorEl);

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  const getProgress = (ld) => {
    let progress = getLang(lang, "label.DRAFT");
    let color = "#B0B0B0";

    if (ld.progress === progressStatus.published) {
      if (Number(ld.type) === 1) {
        if (ld.end_at && moment.utc().isAfter(moment.utc(ld.end_at))) {
          progress = getLang(lang, "label.EVENT_CONCLUDED");
          color = "#3A4D54";
        } else if (
          ld.draw_end_at &&
          moment.utc().isAfter(moment.utc(ld.draw_end_at))
        ) {
          progress = getLang(lang, "label.LIVE_DRAW_COMPLETED");
          color = "#8E6BB3";
        } else if (
          ld.draw_start_at &&
          moment.utc().isAfter(moment.utc(ld.draw_start_at))
        ) {
          progress = getLang(lang, "label.LIVE_DRAW_IN_PROGRESS");
          color = "#8E6BB3";
        } else if (
          ld.join_close_at &&
          moment.utc().isAfter(moment.utc(ld.join_close_at))
        ) {
          progress = getLang(lang, "label.JOIN_PERIOD_CLOSED");
          color = "#FF1D1D";
        } else if (ld.join_open_at) {
          if (moment.utc().isAfter(moment.utc(ld.join_open_at))) {
            progress = getLang(lang, "label.JOIN_PERIOD_OPEN");
            color = "#4BCB0F";
          } else if (ld.pre_start_at) {
            if (moment.utc().isAfter(moment.utc(ld.pre_start_at))) {
              progress = getLang(lang, "label.AWAITING_START");
              color = "#FFB74D";
            } else {
              progress = getLang(lang, "label.AWAITING_START");
              color = "#FFB74D";
            }
          } else {
            progress = getLang(lang, "label.AWAITING_START");
            color = "#FFB74D";
          }
        } else {
          progress = getLang(lang, "label.AWAITING_START");
          color = "#FFB74D";
        }
      } else if (Number(ld.type) === 2) {
        if (ld.end_at && moment.utc().isAfter(moment.utc(ld.end_at))) {
          progress = getLang(lang, "label.EVENT_CONCLUDED");
          color = "#3A4D54";
        } else if (
          ld.join_close_at &&
          moment.utc().isAfter(moment.utc(ld.join_close_at))
        ) {
          progress = getLang(lang, "label.JOIN_PERIOD_CLOSED");
          color = "#FF1D1D";
        } else if (ld.join_open_at) {
          if (moment.utc().isAfter(moment.utc(ld.join_open_at))) {
            progress = getLang(lang, "label.JOIN_PERIOD_OPEN");
            color = "#4BCB0F";
          } else if (ld.pre_start_at) {
            if (moment.utc().isAfter(moment.utc(ld.pre_start_at))) {
              progress = getLang(lang, "label.AWAITING_START");
              color = "#FFB74D";
            } else {
              progress = getLang(lang, "label.AWAITING_START");
              color = "#FFB74D";
            }
          } else {
            progress = getLang(lang, "label.AWAITING_START");
            color = "#FFB74D";
          }
        } else {
          progress = getLang(lang, "label.AWAITING_START");
          color = "#FFB74D";
        }
      }
    } else if (ld.progress === progressStatus.setProduct && ld.prize_quantity) {
      progress = getLang(lang, "label.READY_TO_PUBLISH");
      color = "#4BCB0F";
    }

    const stats = [
      progressStatus.setBasic,
      progressStatus.setPeriod,
      progressStatus.setPrize,
      progressStatus.setParticipant,
      progressStatus.setProduct,
    ];
    const statsText = [
      getLang(lang, "label.BASIC"),
      getLang(lang, "label.PERIOD"),
      getLang(lang, "label.PRIZE"),
      getLang(lang, "label.PARTICIPANT"),
      getLang(lang, "label.PRODUCT"),
    ];
    const progressIndex = stats.indexOf(ld.progress);
    const showTooltip =
      progressIndex > -1 &&
      (![progressStatus.setProduct, progressStatus.published].includes(
        ld.progress
      ) ||
        !ld.prize_quantity);

    return (
      <HtmlTooltip
        title={
          showTooltip ? (
            <>
              {statsText.map((st, index) => {
                const completed =
                  index === 2 ? ld.prize_quantity : index <= progressIndex;
                const checkOrCross = completed ? "✔" : "✘";
                const color = completed ? "#4BCB0F" : "#FF1D1D";

                return (
                  <Box key={index}>
                    <Typography style={{ fontSize: "12px" }}>
                      <span style={{ color }}>{checkOrCross}</span> {st}
                    </Typography>
                  </Box>
                );
              })}
            </>
          ) : (
            ""
          )
        }
      >
        <Typography variant="caption" style={{ color: color }}>
          {progress}
        </Typography>
      </HtmlTooltip>
    );
  };

  return (
    <>
      {(data ?? []).length > 0 || isFetching ? (
        <TableContainer>
          <Table className={classes.table} size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <Box px={2} display="flex" justifyContent="cenyer">
                    {getLang(lang, "label.NUM")}
                  </Box>
                </StickyLeftTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.LUCKY_DRAW_NAME")}
                </StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.TYPE")}</StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.IMAGE")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.DATE_CREATED")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PRODUCTS")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.PARTICIPANTS")}
                </StyledTableCell>
                {/* <StyledTableCell>
                  {getLang(lang, "label.WINNER_RESULT")}
                </StyledTableCell> */}
                <StyledTableCell>
                  {getLang(lang, "label.PROGRESS")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.START_PERIOD")}
                </StyledTableCell>
                <StyledTableCell>
                  {getLang(lang, "label.END_PERIOD")}
                </StyledTableCell>
                {/* <StyledTableCell>
                  {getLang(lang, "label.STATUS")}
                </StyledTableCell> */}
                <StickyRightTableCell>
                  <Box px={2} display="flex" justifyContent="center">
                    {getLang(lang, "label.ACTION")}
                  </Box>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((v, index) => (
                    <SkeletonTableRow key={index} columnCount={11} />
                  ))
                : data.map((item, index) => {
                    let actionItems = actionList({
                      luckyDraw: item,
                      hasLuckyDrawEditAccessRight,
                      lang,
                      handleView,
                      handleBinding,
                      handleViewPrize,
                      handleViewParticipants,
                      handleViewWinner,
                      handleDownloadWinner,
                      handleLiveDraw,
                      handlePublished,
                      handleEnd,
                      handleJoinClose,
                      companyId,
                    });

                    return (
                      <StyledTableRow key={index} hover tabIndex={-1}>
                        <StickyLeftTableCell>
                          <Box px={2} display="flex" justifyContent="cenyer">
                            {index + 1 + rowsPerPage * page}
                          </Box>
                        </StickyLeftTableCell>
                        <StyledTableCell>{item.title}</StyledTableCell>
                        <StyledTableCell>
                          {Number(item.type) === TYPE_TRADITIONAL_RAFFLE &&
                            getLang(lang, "label.TRADITIONAL_RAFFLE")}
                          {Number(item.type) === TYPE_INSTANT_WIN &&
                            getLang(lang, "label.INSTANT_WIN")}
                          {Number(item.type) === TYPE_INTERVAL_RAFFLE &&
                            getLang(lang, "label.INTERVAL_RAFFLE")}
                        </StyledTableCell>
                        <StyledTableCell>
                          <img
                            onClick={() => {
                              setImgSrc(item.image_url);
                              setOpenImage(true);
                            }}
                            src={item.image_url || DefaultImg}
                            style={{
                              width: "30px",
                              height: "30px",
                              cursor: "pointer",
                            }}
                            alt="poster"
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          {moment(item.created_at).format("ll")}
                        </StyledTableCell>
                        <ProductCountDetailPopover
                          products={item.products}
                          itemTranslator={(product) => ({
                            id: product.productId,
                            name: product.name,
                            imageUrl: product.picture,
                            attributes: product.attributes,
                            nodes: product.nodes,
                          })}
                        />
                        <StyledTableCell>
                          {item.participants_count > 0 ? ( 
                            <Link
                              color="secondary"
                              href="#"
                              onClick={(event) => {
                                event.preventDefault();
                                handleViewParticipants(item.uuid);
                              }}
                            >
                              {item.participants_count.toLocaleString("en-US")}
                            </Link>
                          ) : (
                            <Box>
                              {item.participants_count.toLocaleString("en-US")}
                            </Box>
                          )}
                        </StyledTableCell>
                        <StyledTableCell>{getProgress(item)}</StyledTableCell>
                        <StyledTableCell>
                          {item.join_open_at
                            ? moment
                                .utc(item.join_open_at)
                                .local()
                                .format("lll")
                            : "-"}
                        </StyledTableCell>
                        <StyledTableCell>
                          {item.join_close_at
                            ? moment
                                .utc(item.join_close_at)
                                .local()
                                .format("lll")
                            : "-"}
                        </StyledTableCell>
                        {/* <StyledTableCell>
                          <AntSwitch
                            checked={item.status === "active"}
                            disabled={
                              !hasLuckyDrawActivateOrDeactivateAccessRight
                            }
                            onChange={() =>
                              handleStatusChange({
                                currentStatus: item.status,
                                uuid: item.uuid,
                              })
                            }
                          />
                        </StyledTableCell> */}
                        <StickyRightTableCell>
                          <Box px={2} display="flex" justifyContent="center">
                            <ActionButton
                              actionItems={actionItems}
                              popperStyle={{ zIndex: 999 }}
                            />
                          </Box>
                        </StickyRightTableCell>
                      </StyledTableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        !search &&
        !isLoadingLuckyDrawError && (
          <Box style={{ overflowX: "auto", backgroundColor: "#fff" }}>
            <Box py={9} className={classes.emptyContainer}>
              <Typography variant="body1">
                {getLang(lang, "paragraph.NO_LD")}
              </Typography>
              {hasLuckyDrawEditAccessRight ? (
                <Box mt={2}>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    variant="contained"
                    disableElevation
                    color="primary"
                  >
                    {getLang(lang, "label.ADD_LUCKY_DRAW")}
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleAddLuckyDraw}>
                      {getLang(lang, "label.CREATE_TRADITIONAL_RAFFLE")}
                    </MenuItem>
                    {/* <MenuItem onClick={handleAddLuckyDraw}>Create Interval Raffle</MenuItem> */}
                    <MenuItem onClick={handleAddInstantWin}>
                      {getLang(lang, "label.CREATE_INSTANT_WIN")}
                    </MenuItem>
                  </Menu>
                </Box>
              ) : null}
            </Box>
          </Box>
        )
      )}

      {!isFetching &&
        data.length === 0 &&
        search &&
        !isLoadingLuckyDrawError && (
          <Alert severity="info">
            {getLang(lang, "message.info.NO_LD_LIST")}
          </Alert>
        )}

      {!isFetching && isLoadingLuckyDrawError && (
        <RefreshTable handleReload={handleFetchLuckyDrawList} />
      )}

      {!isFetching && data.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={getLang(lang, "label.ROWS_PER_PAGE")}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${getLang(lang, "label.OF")} ${count}`
          }
        />
      )}

      <Dialog
        open={openImage}
        onClose={() => setOpenImage(false)}
        style={{ maxWidth: "100%", maxHeight: "100%" }}
      >
        <img
          style={{ maxWidth: "100%", height: "auto" }}
          src={imgSrc}
          alt="popup"
        />
      </Dialog>
    </>
  );
}
