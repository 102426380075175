import React, { memo, useState, useCallback, useRef } from "react";
import DashboardScanCountMapComponent from "./dashboardScanCountMap.component";
import { useDispatch, useSelector } from "react-redux";
import {
  MarkerClusterer,
  SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer";
import ZoomInIcon from "assets/img/zoomIn.png";
import { useEffect } from "react";
import { resetClusterScanProducts } from "modules/dashboard/redux/slice/dashboard.slice";
import { useLoadScript } from "@react-google-maps/api";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

function DashboardScanCountMapContainer({
  mapScans,
  handleClusterClick,
  handleMarkerClick,
  displayAllProduct,
  selectedProductIds,
  revealProduct,
  updateRevealProductStatus,
  isMain,
  products,
  defaultGeoLocation = {},
  defaultZoom = 5,
}) {
  const locale = useSelector((state) => state.profile.language);
  const lang = useSelector((state) => state.constant.languages);
  const dispatch = useDispatch();

  const [map, setMap] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(defaultZoom);
  const [center, setCenter] = useState(defaultGeoLocation ?? {
    lat: 3.139,
    lng: 101.6869,
  });

  const initialZoomRef = useRef(defaultZoom);
  const hasInitialZoomRef = useRef(false);
  const initialCenterRef = useRef(defaultGeoLocation ?? {
    lat: 3.139,
    lng: 101.6869,
  });
  const markerRef = useRef([]);
  const markerClusterRef = useRef(null);
  const showProductRef = useRef(false);
  const initialLoadRef = useRef(true);

  const { isLoaded } = useLoadScript({
    id: `google-map-script-${locale}`,
    googleMapsApiKey: API_KEY,
    language: locale,
  });

  useEffect(() => {
    if (!initialLoadRef.current && map) {
      showProductRef.current = revealProduct;
      markerRef.current.forEach((marker) => {
        marker.map = null;
      });
      markerRef.current = [];

      if (markerClusterRef.current) {
        markerClusterRef.current.clearMarkers(false);
        markerClusterRef.current = null;
      }

      initMap(map);
    }
    initialLoadRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revealProduct, displayAllProduct, selectedProductIds]);

  const updateZoomLevel = () => {
    if (map) {
      let tempZoom = map.getZoom();
      if (!hasInitialZoomRef.current) {
        tempZoom = tempZoom > 15 ? 15 : tempZoom;
        initialZoomRef.current = tempZoom > 15 ? 15 : tempZoom;

        let tempCenter = map.getCenter();
        initialCenterRef.current = {
          lat: tempCenter.lat(),
          lng: tempCenter.lng(),
        };
        hasInitialZoomRef.current = true;
      }
      setCenter(initialCenterRef.current);
      setZoomLevel(tempZoom);
    }
  };

  const onLoad = useCallback(async function callback(map) {
    map.setOptions({
      mapId: "898db239baad3210",
      // styles: [
      //   {
      //     featureType: "poi",
      //     elementType: "labels.icon",
      //     stylers: [
      //       {
      //         visibility: "off",
      //       },
      //     ],
      //   },
      // ],
    });
    let newBoundary = new window.google.maps.LatLngBounds();

    let hasScanHistory = false;
    mapScans.forEach((item) => {
      if (item.lat && item.lng) {
        newBoundary.extend({
          lat: parseFloat(item.lat),
          lng: parseFloat(item.lng),
        });
        hasScanHistory = true;
      }
    });

    if (hasScanHistory) {
      map.fitBounds(newBoundary);
    }
    setMap(map);
    initMap(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUnmount = useCallback(function callback(map) {
    if (markerClusterRef.current) {
      markerClusterRef.current.clearMarkers(false);
      markerClusterRef.current = null;
    }
    setMap(null);
  }, []);

  async function initMap(map) {
    const { AdvancedMarkerElement } = await window.google.maps.importLibrary(
      "marker"
    );

    let markers = mapScans.reduce((m, d) => {
      if (
        (isMain ||
          (displayAllProduct && !selectedProductIds.includes(d.productUuid)) ||
          (!displayAllProduct && selectedProductIds.includes(d.productUuid))) &&
        d.lat &&
        d.lng
      ) {
        const mark = new AdvancedMarkerElement({
          map,
          content: buildMarker(d),
          position: {
            lat: parseFloat(d.lat),
            lng: parseFloat(d.lng),
          },
        });

        mark.addListener("click", () => {
          handleMarkerClick(d.scanId);
        });

        mark.id = `${d.scanId}_${d.status}_${d.report}`;
        markerRef.current.push(mark);

        m.push(mark);
      }
      return m;
    }, []);

    if (markerClusterRef.current) {
      markerClusterRef.current.clearMarkers(false);
    }

    markerClusterRef.current = new MarkerClusterer({
      map,
      markers,
      onClusterClick: function onClusterClick(event, cluster, map) {
        dispatch(resetClusterScanProducts());
        handleClusterClick(cluster.bounds.toJSON());
      },
      algorithm: new SuperClusterAlgorithm({
        radius: 160,
        maxZoom: 30,
      }),
      renderer: {
        render: (cluster, stats) => {
          let result = cluster.markers.reduce(
            (a, b) => {
              let infoData = b.id.split("_");
              a.scanId.push(infoData[0]);

              if (infoData[1] === "active") {
                a.active += 1;
              } else {
                a.suspend += 1;
              }

              if (infoData[2] === "1") {
                a.hasReport += 1;
              }
              return a;
            },
            { active: 0, suspend: 0, hasReport: 0, scanId: [] }
          );

          return new AdvancedMarkerElement({
            map,
            content: buildClusterContent(cluster, result),
            position: cluster.position,
          });
        },
      },
    });
  }

  const buildMarker = (scan) => {
    const content = document.createElement("div");

    let picture = "";
    if (!!showProductRef.current && !isMain) {
      let prd = products.find((p) => p.uuid === scan.productUuid);
      if (prd) {
        picture = prd.picture;
      }
    }

    content.innerHTML = `
      <div class="${scan.status}Pin">
        ${
          !!showProductRef.current && !isMain && picture
            ? `<img src="${picture}" class="productPic" />`
            : `<span>1</span>`
        }
        ${!!scan.report ? `<div class="report" />` : ""}
      </div>
    `;

    return content;
  };

  const buildClusterContent = (cluster, result) => {
    const content = document.createElement("div");

    let status = "active";
    if (result.active && !result.suspend) {
      status = "active";
    } else if (result.suspend && !result.active) {
      status = "suspend";
    } else {
      status = "both";
    }

    let productPics = [];
    let productIds = [];
    if (!isMain) {
      let scans = result.scanId
        ? mapScans.filter((d) => result.scanId.includes(d.scanId.toString()))
        : [];
      if (scans.length > 0) {
        for (var i = 0; i < scans.length; i++) {
          let temp = scans[i];
          if (!productIds.includes(temp.productUuid)) {
            let prd = products.find((p) => p.uuid === temp.productUuid);
            if (prd) {
              productPics.push(prd.picture);
              productIds.push(temp.productUuid);
            }
          }
        }
      }
    }

    let total = result.active + result.suspend;
    let suspendRatio = result.suspend / total;

    content.innerHTML = `
      <div class="clusterSection">
        ${
          !isMain
            ? `
              <div class="zoomSection ${revealProduct ? "show" : ""}">
                  <img src=${ZoomInIcon} class="zoomPic" />
                </div>
                <div class="imgSection ${revealProduct ? "show" : ""}">
                  ${productPics
                    .slice(0, 3)
                    .map(
                      (p, i) =>
                        `<img src=${p} class="productPic ${
                          !i ? "" : "clusterProduct"
                        }"/>`
                    )}
                  ${
                    productPics.length > 3
                      ? `<div class="moreProduct">+${
                          productPics.length > 1000
                            ? `${Math.floor(productPics.length / 1000)}k`
                            : productPics.length
                        }</div>`
                      : ""
                  }
                </div>
              `
            : ``
        }
        <div class="${status}Cluster" style="background-image:conic-gradient(#F97066 ${
      360 * suspendRatio
    }deg, #35CA91 ${360 * suspendRatio}deg)">
          <span>${
            cluster.count > 1000
              ? `${Math.floor(cluster.count / 1000)}k`
              : cluster.count
          }</span>
          ${!!result.hasReport ? `<div class="report reportCluster" />` : ""}
        </div>
      </div>
    `;

    return content;
  };

  return (
    <DashboardScanCountMapComponent
      locale={locale}
      isLoaded={isLoaded}
      center={center}
      zoomLevel={zoomLevel}
      updateZoomLevel={updateZoomLevel}
      onLoad={onLoad}
      onUnmount={onUnmount}
      lang={lang}
    />
  );
}

DashboardScanCountMapContainer.defaultProps = {
  mapScans: [],
  isMain: true,
  displayAllProduct: true,
  selectedProductIds: [],
};

export default memo(DashboardScanCountMapContainer);
