import React from 'react'
import { Box, ClickAwayListener, Grow, ListItemIcon, ListItemText, makeStyles, MenuItem, MenuList, Paper, Popper } from '@material-ui/core'
import { surveyQuestionType } from 'modules/survey/constants'
import {
    CheckBoxRounded as CheckBoxIcon,
    ShortTextRounded as TextIcon,
    RadioButtonChecked as RadioIcon,
    ArrowDropDownCircle as ArrowDropDownCircleIcon,
    Star as StarIcon
} from '@material-ui/icons';
import { useSelector } from "react-redux";
import { getLang } from 'app/feature/constants';

const useStyle = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: "4px",
    },
    boxSubmenu: {
        "&::before": {
            content: '""',
            position: "absolute",
            width: "0",
            height: "0",
            bottom: -15,
            right: 10,
            border: "8px solid",
            borderColor: "transparent",
            borderTopColor: theme.palette.primary.main,
            zIndex: 9999
        }
    },
    smallText: {
        fontSize: 14
    }
}))

export default function AddQuestionPopperComponent({
    isOpen,
    anchorRef,
    handleClick,
    handleClose
}) {
    const classes = useStyle()
    const lang = useSelector(state => state.constant.languages);
    const companyId = useSelector(state => state.profile.companyID)

    let options = [
        {
            icon: TextIcon,
            label: getLang(lang, 'label.TEXT_FIELD'),
            value: surveyQuestionType.text
        },
        {
            icon: RadioIcon,
            label: getLang(lang, 'label.MULTIPLE_CHOICE'),
            value: surveyQuestionType.selection
        },
        {
            icon: CheckBoxIcon,
            label: getLang(lang,"label.MULTIPLE_CHOICE_MULTI_SELECT"),
            value: surveyQuestionType.multipleChoiceMultiSelect
        },
        {
            icon: ArrowDropDownCircleIcon,
            label: getLang(lang,"label.DROPDOWN_LIST"),
            value: surveyQuestionType.dropdownlist
        },
    ]

    if (process.env.REACT_APP_ENV !== "PRODUCTION" || companyId === 183) {
        options.push({
            icon: StarIcon,
            label: getLang(lang,"label.STAR_RATING"),
            value: surveyQuestionType.starRating
        })
        options.push({
            icon: StarIcon,
            label: getLang(lang,"label.MULTI_STAR_RATING"),
            value: surveyQuestionType.multiStarRating
        })
    }

    return (
        <Popper
            open={isOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            style={{
                zIndex: 9999,
            }}
            transition
            placement="top-end"
            popperOptions={{
                modifiers: {
                    offset: {
                        offset: '0,10',
                    },
                },
            }}
        >
            {({ TransitionProps }) => {
                return (
                    <Grow
                        {...TransitionProps}
                    >
                        <Box className={classes.boxSubmenu}>
                            <Paper className={classes.paper}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={isOpen}
                                        id="menu-list-grow"
                                    >
                                        {options.map((item, index) => (
                                            <MenuItem
                                                key={index}
                                                style={{ fontSize: "small" }}
                                                onClick={() => handleClick(item.value)}
                                            >
                                                <ListItemIcon style={{ minWidth: 30, color: "white" }}>
                                                    <item.icon fontSize="small" style={{ fontSize: 16 }} />
                                                </ListItemIcon>
                                                <ListItemText primary={item.label} classes={{ primary: classes.smallText }} />
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Box>
                    </Grow>
                )
            }}
        </Popper>
    )
}
