import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { getLang } from "app/feature/constants";
import moment from "moment";
import AntiCounterfeitTotalScanIcon from "assets/img/antiCounterfeitTotalScan.png";
import TotalValidScanIcon from "assets/img/totalValidScan.png";
import TotalSuspendedScanIcon from "assets/img/totalSuspendedScan.png";
import TotalWarrantyScanIcon from "assets/img/totalWarrantyScan.png";
import TotalLoyaltyPointsScanIcon from "assets/img/totalLoyaltyPointsScan.png";
import TotalLuckyDrawScanIcon from "assets/img/totalLuckyDarwScan.png";
import TotalSurveyReceivedIcon from "assets/img/totalSurveyReceived.png";
import { summaryCardType } from "modules/dashboard/constants";

const useStyles = makeStyles((_) => ({
  card: {
    borderRadius: 12,
    background: "white",
    border: "1px solid #D0D5DD",
    padding: 16,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    minHeight: 125,
  },
  icon: {
    height: 70,
    width: 70,
    objectFit: "contain",
    marginRight: 5,
    marginLeft: 8,
  },
  infoLabel: {
    color: "#32D583",
  },
  warningLabel: {
    color: "#F97066",
  },
}));

export default function TcgSummaryCardComponent({
  data,
  language,
  loading,
  lang,
  type,
}) {
  const classes = useStyles();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  const summaryList = {
    [summaryCardType.antiCounterfeitTotalScan]: {
      title: getLang(lang, "label.ANTI_COUNTERFEIT_TOTAL_SCAN"),
      icon: AntiCounterfeitTotalScanIcon,
    },
    [summaryCardType.totalValidScan]: {
      title: getLang(lang, "label.TOTAL_VALID_SCAN"),
      icon: TotalValidScanIcon,
    },
    [summaryCardType.totalSuspendedScan]: {
      title: getLang(lang, "label.TOTAL_SUSPENDED_SCAN"),
      icon: TotalSuspendedScanIcon,
    },
    [summaryCardType.totalWarrantyScan]: {
      title: getLang(lang, "label.TOTAL_WARRANTY_SCAN"),
      icon: TotalWarrantyScanIcon,
    },
    [summaryCardType.totalLoyaltyPointsScan]: {
      title: getLang(lang, "label.TOTAL_LOYALTY_POINTS_SCAN"),
      icon: TotalLoyaltyPointsScanIcon,
    },
    [summaryCardType.totalLuckyDrawScan]: {
      title: getLang(lang, "label.TOTAL_LUKCY_DRAW_SCAN"),
      icon: TotalLuckyDrawScanIcon,
    },
    [summaryCardType.totalSurveyReceived]: {
      title: getLang(lang, "label.TOTAL_SURVEY_RECEIVED"),
      icon: TotalSurveyReceivedIcon,
    },
  };

  return (
    summaryList[type] && (
      <Box className={classes.card}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <img
            src={summaryList[type].icon}
            alt="icon"
            className={classes.icon}
          />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            flex={1}
          >
            <Typography variant="body1">{summaryList[type].title}</Typography>
            {loading ? (
              <Skeleton
                variant="text"
                animation="wave"
                width="100%"
                height={30}
              />
            ) : (
              <Typography variant="h4">
                <b>{data?.toLocaleString("en-US") || 0}</b>
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    )
  );
}
