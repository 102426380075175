export const DASHBOARD = "dashboard";

export const mapInfoStep = {
  productListStep: 0,
  scanListStep: 1,
  scanDetailStep: 2,
};

export const scanLoginType = {
  all: 0,
  registered: 1,
  nonRegistered: 2,
};

export const dateType = {
  LAST_14_DAYS: "last_14_days",
  TODAY: "today",
  THIS_MONTH: "this_month",
  LAST_MONTH: "last_month",
  CUSTOM: "custom",
};

export const trendingSummaryCardType = {
  consumerScanned: "consumerScanned",
  suspendSn: "suspendSn",
  counterfeitReport: "counterfeitReport",
  totalRegistration: "totalRegistration",
  cumulativeRegistration: "cumulativeRegistration",
  totalScannedSolutionHighlight: "totalScannedSolutionHighlight",
  totalMockupScan: "totalMockupScan",
  counterfeitReportReceived: "counterfeitReportReceived",
};

export const summaryCardType = {
  antiCounterfeitTotalScan: "antiCounterfeitTotalScan",
  totalValidScan: "totalValidScan",
  totalSuspendedScan: "totalSuspendedScan",
  totalWarrantyScan: "totalWarrantyScan",
  totalLoyaltyPointsScan: "totalLoyaltyPointsScan",
  totalLuckyDrawScan: "totalLuckyDrawScan",
  totalSurveyReceived: "totalSurveyReceived",
};

export const roles = [
  {
    id: 1,
    name: "label.EXECUTIVE",
    value: "",
  },
  {
    id: 2,
    name: "label.SALES_CUSTOMER_SERVICES",
    value: "",
  },
  {
    id: 3,
    name: "label.MARKETING",
    value: "",
  },
  {
    id: 4,
    name: "label.PURCHASING_SOURCING",
    value: "",
  },
  {
    id: 5,
    name: "label.ACCOUNTING_FINANCE",
    value: "",
  },
  {
    id: 6,
    name: "label.PRODUCTION_SUPPLY_CHAIN",
    value: "",
  },
  {
    id: 7,
    name: "label.IT_DATA_SCIENTIST",
    value: "",
  },
  {
    id: 8,
    name: "label.RESEARCH_DEVELOPMENT",
    value: "",
  },
  {
    id: 9,
    name: "label.DESIGNER",
    value: "",
  },
  {
    id: 10,
    name: "label.EXTREPRENEUR_BUSINESS_OWNER",
    value: "",
  },
  {
    id: 11,
    name: "label.STUDENT_TEACHER_PROFESSOR",
    value: "",
  },
  {
    id: 12,
    name: "label.OTHERS",
    value: "",
  },
];

export const industries = [
  {
    id: 1,
    name: "label.BEVERAGE",
    value: "",
  },
  {
    id: 2,
    name: "label.FOOD",
    value: "",
  },
  {
    id: 3,
    name: "label.PET_FOOD",
    value: "",
  },
  {
    id: 4,
    name: "label.BEAUTY_PERSONAL_CARE_COSMETICS",
    value: "",
  },
  {
    id: 5,
    name: "label.HEALTHCARE_MEDICAL",
    value: "",
  },
  {
    id: 6,
    name: "label.ELECTRONICS",
    value: "",
  },
  {
    id: 7,
    name: "label.PETROLEUM_CHEMICAL",
    value: "",
  },
  {
    id: 8,
    name: "label.ECOMMERCE",
    value: "",
  },
  {
    id: 9,
    name: "label.PUBLIC_ORGANIZATION",
    value: "",
  },
  {
    id: 10,
    name: "label.OTHERS",
    value: "",
  },
];

export const industryColors = {
  1: "#FF5733",
  2: "#33FF57",
  3: "#3357FF",
  4: "#FF33A1",
  5: "#33FFF5",
  6: "#F5FF33",
  7: "#8D33FF",
  8: "#FF8633",
  9: "#33FF86",
  10: "#FF3386",
};

export const roleColors = {
  1: "#FF5733",
  2: "#33FF57",
  3: "#3357FF",
  4: "#FF33A1",
  5: "#33FFF5",
  6: "#F5FF33",
  7: "#8D33FF",
  8: "#FF8633",
  9: "#33FF86",
  10: "#FF3386",
  11: "#3386FF",
  12: "#86FF33",
};
