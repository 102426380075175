import React from "react";
import {
  makeStyles,
  Box,
  Paper,
  Typography,
  Divider,
  Button,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import LoadingComponent from "components/loading";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";
import { ErrorMessage, Formik, setNestedObjectValues } from "formik";
import { getLang } from "app/feature/constants";
import {
  InfoRounded as InfoRoundedIcon,
  VisibilityOff as VisibilityOffIcon,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import GeneralDialog from "components/dialog/generalDialog";
import AlertIcon from "assets/svg/alert.svg";
import parse from "html-react-parser";
import AntSwitch from "components/switch/switch";
import * as Yup from "yup";
import RefreshTable from "components/table/refreshTable";

const useStyle = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 3, 2, 3),
    borderRadius: "4px",
  },
  button: {
    "&:not(:last-child)": {
      marginLeft: "10px",
    },
  },
  contentContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "25px",
    [theme.breakpoints.down("sm")]: {
      rowGap: "25px",
      gridTemplateColumns: "1fr",
    },
  },
  textFieldInput: {
    padding: "7px",
    paddingTop: "8px",
    border: "2px solid #e1e1e1",
    backgroundColor: "#f7f7f7",
    borderRadius: 4,

    "& input": {
      padding: 0,
    },
  },
  inputContainer: {
    display: "grid",
    gridTemplateColumns: "2.5fr 7.5fr",
    columnGap: "20px",
    margin: "8px 0",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "3fr 7fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      row: "4px",
    },
  },
  mappingContainer: {
    display: "grid",
    gridTemplateColumns: "5fr 1fr 3fr",
  },
}));

export default function SmtpSettingPanelComponent({
  isLoading,
  setting,
  handleUpdate,
  lang,
  hasSmtpEditAccessRight,
  isSubmitting,
  isEdit,
  setIsEdit,
  isError,
  handleReload,
}) {
  const classes = useStyle();

  if (isLoading) {
    return (
      <Box
        className={classes.container}
        style={{ paddingTop: 40, paddingBottom: 40 }}
      >
        <LoadingComponent />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        className={classes.container}
        style={{ paddingTop: 40, paddingBottom: 40 }}
      >
        <RefreshTable handleReload={handleReload} />
      </Box>
    );
  }

  return (
    <Box>
      <Paper elevation={3} className={classes.container}>
        <Formik
          initialValues={{
            smtpHost: setting?.smtp_host,
            port: setting?.port,
            username: setting?.username,
            password: setting?.password,
            fromEmail: setting?.from_email,
            fromName: setting?.from_name,
            tlsEncryption: setting?.tls_encryption,
            showConfirmDialog: false,
            isDelete: false,
            isSetup: !!setting?.is_setup ?? false,
            showPassword: false,
          }}
          enableReinitialize
          onSubmit={handleUpdate}
          validationSchema={Yup.object({
            smtpHost: Yup.string()
              .nullable()
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            port: Yup.string()
              .nullable()
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            username: Yup.string()
              .nullable()
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            password: Yup.string()
              .nullable()
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            fromEmail: Yup.string()
              .nullable()
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
              .email(getLang(lang, "message.error.FORM_VALIDATE_EMAIL"))
              .max(
                255,
                getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })
              ),
            fromName: Yup.string()
              .nullable()
              .required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
          })}
        >
          {(formik) => (
            <form>
              <Box>
                <Typography
                  variant="h6"
                  color="primary"
                  style={{ marginBottom: 8 }}
                >
                  <b>{getLang(lang, `label.SMTP_SETTINGS`)}</b>
                </Typography>
                <Divider />
                {formik.values.isSetup ? (
                  <Box display="flex" flexDirection="column">
                    <Box mt={2} mb={1} className={classes.contentContainer}>
                      <Box>
                        {/* smtp host */}
                        <Box className={classes.inputContainer}>
                          <Box mt={1}>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: 500 }}
                            >
                              {getLang(lang, `label.SMTP_HOST`)}
                              <span style={{ color: "red" }}> *</span>
                            </Typography>
                          </Box>
                          <Box
                            flex={1}
                            display="flex"
                            alignItems="center"
                            minHeight={38}
                          >
                            {isEdit ? (
                              <TextField
                                {...formik.getFieldProps("smtpHost")}
                                fullWidth
                                size="small"
                                margin="none"
                                placeholder={getLang(
                                  lang,
                                  "placeholder.SMTP_HOST"
                                )}
                                InputProps={{
                                  className: classes.textFieldInput,
                                  disableUnderline: true,
                                  margin: "none",
                                }}
                                disabled={
                                  !hasSmtpEditAccessRight || isSubmitting
                                }
                                value={formik.values.smtpHost || ""}
                                error={
                                  formik.errors.smtpHost &&
                                  formik.touched.smtpHost
                                }
                                helperText={ErrorMessage({
                                  name: `smtpHost`,
                                })}
                              />
                            ) : (
                              <Typography variant="body2">
                                {formik.values.smtpHost || "-"}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        {/* port */}
                        <Box className={classes.inputContainer}>
                          <Box mt={1}>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: 500 }}
                            >
                              {getLang(lang, `label.PORT_NUMBER`)}
                              <span style={{ color: "red" }}> *</span>
                            </Typography>
                          </Box>
                          <Box
                            flex={1}
                            display="flex"
                            alignItems="center"
                            minHeight={38}
                          >
                            {isEdit ? (
                              <TextField
                                {...formik.getFieldProps("port")}
                                fullWidth
                                size="small"
                                margin="none"
                                placeholder={getLang(
                                  lang,
                                  "placeholder.PORT_NUMBER"
                                )}
                                type="number"
                                InputProps={{
                                  className: classes.textFieldInput,
                                  disableUnderline: true,
                                  margin: "none",
                                }}
                                disabled={
                                  !hasSmtpEditAccessRight || isSubmitting
                                }
                                value={formik.values.port || ""}
                                error={
                                  formik.errors.port && formik.touched.port
                                }
                                helperText={ErrorMessage({
                                  name: `port`,
                                })}
                              />
                            ) : (
                              <Typography variant="body2">
                                {formik.values.port || "-"}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        {/* username */}
                        <Box className={classes.inputContainer}>
                          <Box mt={1}>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: 500 }}
                            >
                              {getLang(lang, `label.USERNAME`)}
                              <span style={{ color: "red" }}> *</span>
                            </Typography>
                          </Box>
                          <Box
                            flex={1}
                            display="flex"
                            alignItems="center"
                            minHeight={38}
                          >
                            {isEdit ? (
                              <TextField
                                {...formik.getFieldProps("username")}
                                fullWidth
                                size="small"
                                margin="none"
                                placeholder={getLang(
                                  lang,
                                  "placeholder.USERNAME"
                                )}
                                InputProps={{
                                  className: classes.textFieldInput,
                                  disableUnderline: true,
                                  margin: "none",
                                }}
                                disabled={
                                  !hasSmtpEditAccessRight || isSubmitting
                                }
                                value={formik.values.username || ""}
                                error={
                                  formik.errors.username &&
                                  formik.touched.username
                                }
                                inputProps={{
                                  autocomplete: "new-password",
                                  form: {
                                    autocomplete: "off",
                                  },
                                }}
                                helperText={ErrorMessage({
                                  name: `username`,
                                })}
                              />
                            ) : (
                              <Typography variant="body2">
                                {formik.values.username || "-"}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        {/* password */}
                        <Box className={classes.inputContainer}>
                          <Box mt={1}>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: 500 }}
                            >
                              {getLang(lang, `label.PASSWORD`)}
                              <span style={{ color: "red" }}> *</span>
                            </Typography>
                          </Box>
                          <Box
                            flex={1}
                            display="flex"
                            alignItems="center"
                            minHeight={38}
                          >
                            {isEdit ? (
                              <TextField
                                {...formik.getFieldProps("password")}
                                fullWidth
                                size="small"
                                margin="none"
                                type={
                                  formik.values.showPassword
                                    ? "text"
                                    : "password"
                                }
                                placeholder={getLang(
                                  lang,
                                  "placeholder.PASSWORD"
                                )}
                                InputProps={{
                                  className: classes.textFieldInput,
                                  disableUnderline: true,
                                  margin: "none",
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      style={{ paddingLeft: "6px" }}
                                    >
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          formik.setFieldValue(
                                            "showPassword",
                                            !formik.values.showPassword
                                          );
                                        }}
                                      >
                                        {formik.values.showPassword ? (
                                          <VisibilityIcon />
                                        ) : (
                                          <VisibilityOffIcon />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                inputProps={{
                                  autocomplete: "new-password",
                                  form: {
                                    autocomplete: "off",
                                  },
                                }}
                                disabled={
                                  !hasSmtpEditAccessRight || isSubmitting
                                }
                                value={formik.values.password || ""}
                                error={
                                  formik.errors.password &&
                                  formik.touched.password
                                }
                                helperText={ErrorMessage({
                                  name: `password`,
                                })}
                              />
                            ) : (
                              <Box
                                display="flex"
                                alignItems="center"
                                style={{ gap: 12 }}
                              >
                                <Typography
                                  variant="body2"
                                  style={{ lineBreak: "anywhere" }}
                                >
                                  {formik.values.password
                                    ? formik.values.showPassword
                                      ? formik.values.password
                                      : "*".repeat(
                                          formik.values.password.length
                                        )
                                    : "-"}
                                </Typography>
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    formik.setFieldValue(
                                      "showPassword",
                                      !formik.values.showPassword
                                    );
                                  }}
                                >
                                  {formik.values.showPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Box>
                      <Box>
                        {/* from email address */}
                        <Box className={classes.inputContainer}>
                          <Box mt={1}>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: 500 }}
                            >
                              {getLang(lang, `label.FROM_EMAIL_ADDRESS`)}
                              <span style={{ color: "red" }}> *</span>
                            </Typography>
                          </Box>
                          <Box
                            flex={1}
                            display="flex"
                            alignItems="center"
                            minHeight={38}
                          >
                            {isEdit ? (
                              <TextField
                                {...formik.getFieldProps("fromEmail")}
                                fullWidth
                                size="small"
                                margin="none"
                                placeholder={getLang(
                                  lang,
                                  "placeholder.FROM_EMAIL_ADDRESS"
                                )}
                                InputProps={{
                                  className: classes.textFieldInput,
                                  disableUnderline: true,
                                  margin: "none",
                                }}
                                disabled={
                                  !hasSmtpEditAccessRight || isSubmitting
                                }
                                value={formik.values.fromEmail || ""}
                                error={
                                  formik.errors.fromEmail &&
                                  formik.touched.fromEmail
                                }
                                helperText={ErrorMessage({
                                  name: `fromEmail`,
                                })}
                              />
                            ) : (
                              <Typography variant="body2">
                                {formik.values.fromEmail || "-"}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        {/* from name */}
                        <Box className={classes.inputContainer}>
                          <Box mt={1}>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: 500 }}
                            >
                              {getLang(lang, `label.FROM_NAME`)}
                              <span style={{ color: "red" }}> *</span>
                            </Typography>
                          </Box>
                          <Box
                            flex={1}
                            display="flex"
                            alignItems="center"
                            minHeight={38}
                          >
                            {isEdit ? (
                              <TextField
                                {...formik.getFieldProps("fromName")}
                                fullWidth
                                size="small"
                                margin="none"
                                placeholder={getLang(
                                  lang,
                                  "placeholder.FROM_NAME"
                                )}
                                InputProps={{
                                  className: classes.textFieldInput,
                                  disableUnderline: true,
                                  margin: "none",
                                }}
                                disabled={
                                  !hasSmtpEditAccessRight || isSubmitting
                                }
                                value={formik.values.fromName || ""}
                                error={
                                  formik.errors.fromName &&
                                  formik.touched.fromName
                                }
                                helperText={ErrorMessage({
                                  name: `fromName`,
                                })}
                              />
                            ) : (
                              <Typography variant="body2">
                                {formik.values.fromName || "-"}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                        {/* tls encryption */}
                        <Box className={classes.inputContainer}>
                          <Box
                            mt={1}
                            display="flex"
                            alignItems="center"
                            style={{ gap: 5 }}
                          >
                            <Typography
                              variant="body2"
                              style={{ fontWeight: 500 }}
                            >
                              {getLang(lang, `label.TLS_ENCRYPTION`)}
                            </Typography>
                            <Tooltip
                              title={getLang(
                                lang,
                                "paragraph.TLS_ENCRYPTION_TOOLTIP"
                              )}
                              arrow
                            >
                              <HelpOutlinedIcon
                                color="primary"
                                fontSize="small"
                              />
                            </Tooltip>
                          </Box>
                          <Box
                            flex={1}
                            display="flex"
                            alignItems="center"
                            minHeight={38}
                          >
                            <AntSwitch
                              checked={formik.values.tlsEncryption}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "tlsEncryption",
                                  e.target.checked
                                );
                              }}
                              disabled={isSubmitting || !isEdit}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {hasSmtpEditAccessRight && (
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        mt={3}
                        style={{ gap: 16 }}
                      >
                        {isEdit && (
                          <Button
                            variant="outlined"
                            disableElevation
                            color="primary"
                            onClick={async () => {
                              setIsEdit(false);
                              formik.resetForm();
                            }}
                            className={classes.button}
                            disabled={isSubmitting}
                          >
                            <Typography variant="body2">
                              {getLang(lang, "label.CANCEL")}
                            </Typography>
                          </Button>
                        )}
                        {!isEdit && (
                          <Button
                            variant="contained"
                            disableElevation
                            color="error"
                            onClick={async () => {
                              formik.setFieldValue("showConfirmDialog", true);
                              formik.setFieldValue("isDelete", true);
                            }}
                            className={classes.button}
                            disabled={isSubmitting}
                          >
                            <Typography variant="body2">
                              {getLang(lang, "label.DELETE")}
                            </Typography>
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          disableElevation
                          color={isEdit ? "secondary" : "primary"}
                          onClick={async () => {
                            if (isEdit) {
                              const errors = await formik.validateForm();

                              if (Object.keys(errors).length === 0) {
                                formik.setFieldValue("showConfirmDialog", true);
                              } else {
                                formik.setTouched(
                                  setNestedObjectValues(errors, true)
                                );
                              }
                            } else {
                              setIsEdit(true);
                            }
                          }}
                          className={classes.button}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <CircularProgress
                              size={20}
                              style={{ color: "white" }}
                            />
                          ) : (
                            <Typography variant="body2">
                              {getLang(
                                lang,
                                isEdit ? "label.UPDATE" : "label.EDIT"
                              )}
                            </Typography>
                          )}
                        </Button>
                      </Box>
                    )}
                  </Box>
                ) : (
                  <Box
                    p={3}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      style={{ gap: 8 }}
                      mr={1}
                    >
                      <InfoRoundedIcon />
                      <Typography variant="body2">
                        {hasSmtpEditAccessRight
                          ? parse(
                              getLang(lang, "message.info.API_NOT_CONFIGURED", {
                                button: `<b>${getLang(
                                  lang,
                                  "label.SETUP_NOW"
                                ).toUpperCase()}</b>`,
                              })
                            )
                          : getLang(
                              lang,
                              "message.info.API_NOT_CONFIGURED_ADMIN"
                            )}
                      </Typography>
                    </Box>
                    {hasSmtpEditAccessRight && (
                      <Button
                        variant="contained"
                        disableElevation
                        color={"secondary"}
                        onClick={async () => {
                          formik.setFieldValue("isSetup", true);
                          setIsEdit(true);
                        }}
                        className={classes.button}
                        disabled={isSubmitting}
                      >
                        <Typography variant="body2">
                          {getLang(lang, "label.SETUP_NOW")}
                        </Typography>
                      </Button>
                    )}
                  </Box>
                )}
              </Box>
              <GeneralDialog
                isOpen={formik.values.showConfirmDialog}
                handleClose={() => {
                  formik.setFieldValue("showConfirmDialog", false);
                  formik.setFieldValue("isDelete", false);
                }}
                handleProceed={() => {
                  formik.setFieldValue("showConfirmDialog", false);
                  formik.setFieldValue("showPassword", false);
                  formik.handleSubmit();
                }}
                isLoading={isSubmitting}
                type="danger"
                description={
                  formik.values.isDelete
                    ? getLang(
                        lang,
                        "paragraph.DELETE_INTEGRATION_SETTING_CONFIRMATION"
                      )
                    : getLang(
                        lang,
                        "paragraph.UPDATE_INTEGRATION_SETTING_CONFIRMATION"
                      )
                }
                title={getLang(lang, "label.CONFIRMATION_NEEDED")}
                icon={<img src={AlertIcon} alt="alert" />}
              />
            </form>
          )}
        </Formik>
      </Paper>
    </Box>
  );
}
