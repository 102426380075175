import React, { useState } from "react";
import SingleColorPickerSelectPanelComponent from "./singleColorPickerSelectPanel.component";
import { useSelector } from "react-redux";
import SingleColorPickerSelectComponent from "./singleColorPickerSelect.component";

function SingleColorPickerSelectContainer({
  handleChange,
  disabled,
  styles,
  inputStyles,
  value,
  anchorOrigin,
  transformOrigin,
  PaperProps,
  placeholder,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [bufferColor, setBufferColor] = useState(value);
  const lang = useSelector((state) => state.constant.languages);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleColorChange = (color, event) => {
    setBufferColor(color.hex);
  };

  const handleSave = () => {
    handleChange(bufferColor);
    handleClose();
  };

  const handleCancel = () => {
    setBufferColor(value);
    handleClose();
  };

  return (
    <>
      <SingleColorPickerSelectComponent
        handleClick={handleClick}
        styles={styles}
        inputStyles={inputStyles}
        value={value}
        placeholder={placeholder}
      />
      <SingleColorPickerSelectPanelComponent
        anchorEl={anchorEl}
        disabled={disabled}
        open={open}
        handleClose={handleClose}
        bufferColor={bufferColor}
        handleColorChange={handleColorChange}
        lang={lang}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        PaperProps={PaperProps}
        handleSave={handleSave}
        handleCancel={handleCancel}
      />
    </>
  );
}

export default SingleColorPickerSelectContainer;
