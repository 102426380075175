export const SURVEY = "survey";

export const surveyQuestionType = {
  text: "text",
  selection: "select",
  multipleChoiceMultiSelect: "multipleChoiceMultiSelect",
  dropdownlist: "dropdownlist",
  starRating: "starRating",
  multiStarRating: "multiStarRating",
};

export const surveyTemplateType = {
  feedback: "feedback",
  followUp: "followUp",
  marketing: "marketing",
};
