import { getLang } from "app/feature/constants";
import { convertFirstLetterUppercase } from "lib/generalUtility";

export function generateId(prefix, currentIds) {
  const exeptions = [
    "additional information",
    "warranty",
    "authentication",
    "header",
    "marketplace",
    "loyalty point",
    "co2 footprint",
  ];

  if (exeptions.includes(prefix)) return prefix;

  let id = `${prefix}-1`;

  const found = currentIds.filter((el) => el.split("-")[0] === prefix);

  if (found.length === 0) {
    return id;
  }

  const indexes = [...found.map((el) => Number(el.split("-")[1]))];

  const newIndex = Math.max(...indexes) + 1;

  return `${prefix}-${newIndex}`;
}

export const createFacebookModal = () => ({
  type: "facebook",
  content: {
    pageURL: "",
    useSmallHeader: false,
    hideCoverPhoto: false,
    showFriendsFaces: false,
    tabs: {
      timeline: false,
      events: false,
      messages: false,
    },
  },
});

export const createMarketplaceContentModel = () => ({
  marketplaces: {},
  order: [],
});

export const createGalleryContentModel = () => ({
  images: {},
  order: [],
});

export const createAuthModal = () => ({
  type: "authentication",
  content: {
    init: "",
    data: {
      messages: [
        { lang: "EN", value: "Congratulation! The product is 100% genuine!" },
      ],
    },
  },
});

export function isInstagramUrlValid(url) {
  const re =
    /(?:(?:http|https):\/\/)?(?:www.)?instagram.com\/(p|reel)\/(?:(?:\w)*#!\/)?(?:\/)?(?:[?\w-]*\/)?([\w-]*)?/;
  return re.test(url);
}

export function isFacebookUrlValid(url) {
  const re =
    /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w-]*)?/;
  return re.test(url);
}

export function isUrlValid(url) {
  const re =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/;
  return re.test(url);
}

export function getYoutubeIdByUrl(url) {
  let id = url.split("youtube.com/watch?v=")[1];

  if (!id) return null;

  id = id.split("&")[0];

  if (!id) return null;

  return id;
}

export function getTiktokIdByUrl(url) {
  let id = url.split("/video/")[1];

  if (!id) return null;

  id = id.split("&")[0];
  id = id.split("/")[0];

  if (!id) return null;

  return id;
}

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function getInitialAuthenticationContent(type, lang, initialValues) {
  if (type === "new") {
    return {
      messages: lang.map((lg) => ({ lang: lg, value: initialValues.init })),
      isSnHidden: false,
    };
  } else {
    return initialValues;
  }
}

export function prepareProductUiJson(productUiData) {
  return {
    backgroundColor: productUiData.backgroundColor,
    columns: productUiData.columns,
    components: productUiData.components,
    lang: productUiData.lang,
  };
}

export function renameComponentTitle(lang, component) {
  let title = "";

  switch (component) {
    case "text":
      title = getLang(lang, "label.ARTICLE");
      break;

    case "website":
      title = getLang(lang, "label.URL_LINK");
      break;

    case "additional information":
      title = getLang(lang, "label.ADDITIONAL_INFORMATION");
      break;

    default:
      title = convertFirstLetterUppercase(component);
      if (title === "Marketplace") title = getLang(lang, "label.MARKETPLACE");
      else if (title === "Gallery") title = getLang(lang, "label.GALLERY");
      else if (title === "Video") title = getLang(lang, "label.VIDEO");
      else if (title === "Facebook") title = getLang(lang, "label.FACEBOOK");
      else if (title === "Instagram") title = getLang(lang, "label.INSTAGRAM");
      else if (title === "Tiktok") title = getLang(lang, "label.TIKTOK");
      else if (title === "Authentication")
        title = getLang(lang, "label.AUTHENTICATION");
      //  QUICK FIX FOR LANGUAGES TRANSLATION
      else break;
      break;
  }

  return title;
}
