/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { DashboardContext } from "modules/dashboard/pages/dashboard/dashboard.container";
import CumulativeSolutionHighlightChartPanelComponent from "./cumulativeSolutionHighlightChartPanel.component";
import dashboardApi from "app/api/dashboard";
import moment from "moment";
import { addAlert } from "modules/notification";
import { getApiLang } from "app/feature/constants";

export default function CumulativeSolutionHighlightChartPanelContainer() {
  const { currentFilter, activeIndex } = useContext(DashboardContext);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    getData();
  }, [currentFilter]);

  const getData = () => {
    setIsLoading(true);
    setIsError(false);
    let param = {
      start_date: moment(currentFilter.dateRange[0]).format("YYYY-MM-DD"),
      end_date: moment(currentFilter.dateRange[1]).format("YYYY-MM-DD"),
      is_today: activeIndex === 1 ? 1 : 0,
      is_month: activeIndex === 2 || activeIndex === 3 ? 1 : 0,
    };

    dashboardApi
      .getCumulativeSolutionHighlightScanChart(param)
      .then((res) => {
        let data = res.data;
        data.isEmpty = !data.labels.length;
        setChartData(data);
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <CumulativeSolutionHighlightChartPanelComponent
      isLoading={isLoading}
      data={chartData}
      lang={lang}
      isError={isError}
      handleReload={getData}
    />
  );
}
