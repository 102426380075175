import React from "react";
import {
  Backdrop,
  Dialog,
  makeStyles,
  Typography,
  Paper,
  Box,
  IconButton,
  Divider,
  //Button,
  Grid,
} from "@material-ui/core";
import {
  Close as CloseIcon,
  StarRounded as StarIcon,
} from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
//import ExportIcon from "assets/svg/export.svg";
import DefaultImg from "assets/img/img-default.png";
import DefaultPhoto from "assets/img/defaultPhoto.png";
import { getLang } from "app/feature/constants";
import moment from "moment/moment";
import "moment/min/locales.min";
import { surveyQuestionType } from "modules/survey/constants";

const useStyle = makeStyles((theme) => ({
  dialog: {
    maxWidth: "none",
  },
  root: {
    boxShadow: theme.shadows[5],
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    width: 600,
    borderRadius: 4,
    fontSize: 14,
  },
  title: {
    fontWeight: "bold",
    fontSize: 17,
    marginBottom: 10,
  },
  detailsWrapper: {
    padding: theme.spacing(3),
  },
  header: {
    paddingLeft: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  profile: {
    width: 50,
    height: 50,
    borderRadius: "50%",
  },
}));

export default function ViewRespondentDialogComponent({
  handleClose,
  isOpen,
  respondent,
  isLoading,
  lang,
  language,
}) {
  const classes = useStyle();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  function displayAnswer(response) {
    let index = 0;

    if (
      response.selected_answer === "" ||
      response.selected_answer === "skip"
    ) {
      return getLang(lang, "label.SKIPPED");
    }

    if (response.type === surveyQuestionType.text) {
      return response.selected_answer;
    }

    if (
      response.type === surveyQuestionType.selection ||
      response.type === surveyQuestionType.dropdownlist
    ) {
      let isOther = false;
      if (response.is_v2 && !response.selected_answer.choice_id) {
        isOther = true;
      }

      response.choices.forEach((c, i) => {
        if (response.is_v2) {
          if (c.id === response.selected_answer.choice_id) {
            index = i + 1;
          }
        } else {
          if (c === response.selected_answer) {
            index = i + 1;
          }
        }
      });

      if (isOther) {
        index = response.choices.length + 1;
      }

      return `${getLang(lang, "label.OPTION")} ${index} - ${
        isOther
          ? `${getLang(lang, "label.OTHERS")} (${
              response.is_v2
                ? response.selected_answer.value
                : response.selected_answer
            })`
          : `${
              response.is_v2
                ? response.selected_answer.value
                : response.selected_answer
            }`
      }`;
    }
    if (
      response.type === "multipleChoiceMultiSelect" &&
      response.selected_answer
    ) {
      return response.selected_answer;
    } else return "";
  }

  const displayQuestionType = (type) => {
    let questionType = "";

    switch (type) {
      case surveyQuestionType.text:
        questionType = getLang(lang, "label.TEXT_FIELD");
        break;
      case surveyQuestionType.selection:
        questionType = getLang(lang, "label.MULTIPLE_CHOICE");
        break;
      case surveyQuestionType.dropdownlist:
        questionType = getLang(lang, "label.DROPDOWN_LIST");
        break;
      case surveyQuestionType.multipleChoiceMultiSelect:
        questionType = getLang(lang, "label.MULTIPLE_CHOICE_MULTI_SELECT");
        break;
      case surveyQuestionType.multiStarRating:
        questionType = getLang(lang, "label.MULTI_STAR_RATING");
        break;
      case surveyQuestionType.starRating:
        questionType = getLang(lang, "label.STAR_RATING");
        break;
      default:
        break;
    }

    return questionType;
  };

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      classes={{
        paperWidthSm: classes.dialog,
      }}
    >
      <Paper elevation={0} className={classes.root}>
        <div className={classes.header}>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ fontWeight: "bold" }}
          >
            {getLang(lang, "label.RESPONDENT_DETAILS")}
          </Typography>
          <IconButton color="primary" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <Divider />
        <Box className={classes.detailsWrapper}>
          {/*haven't implement
     <Box display="flex" justifyContent="flex-end">
            <Button variant='outlined' style={{ textTransform: "none" }} startIcon={<img src={ExportIcon} alt="export icon"/>}>
              Export PDF
            </Button>
          </Box> */}
          <Box mt={1.5}>
            <Box mb={3}>
              <Box>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  style={{ fontWeight: "bold" }}
                >
                  {getLang(lang, "label.PERSONAL_INFO")}
                </Typography>
              </Box>
              <Grid container style={{ marginTop: 8, marginBottom: 16 }}>
                <Grid item xs={6} sm={6} md={6}>
                  <Typography variant="body2" color="textSecondary">
                    {getLang(lang, "label.RESPONDENT")}
                  </Typography>
                  <Box display="flex" style={{ marginTop: 8 }}>
                    {isLoading ? (
                      <Skeleton variant="circle" width={50} height={50} />
                    ) : (
                      <img
                        src={
                          respondent.profile_picture &&
                          respondent.profile_picture !== "-"
                            ? respondent.profile_picture
                            : DefaultPhoto
                        }
                        alt=""
                        className={classes.profile}
                      />
                    )}
                    <Box ml={1}>
                      <Typography variant="subtitle2" color="textPrimary">
                        {respondent.name}
                      </Typography>
                      <Typography variant="subtitle2" color="textPrimary">
                        {respondent.email}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                  <Typography variant="body2" color="textSecondary">
                    {getLang(lang, "label.DATE_OF_BIRTH")}
                  </Typography>
                  <Box style={{ marginTop: 8 }}>
                    <Typography variant="subtitle2" color="textPrimary">
                      {moment(respondent.dateofbirth).format("ll")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} sm={3} md={3}>
                  <Typography variant="body2" color="textSecondary">
                    {getLang(lang, "label.GENDER")}
                  </Typography>
                  <Box style={{ marginTop: 8 }}>
                    <Typography variant="subtitle2" color="textPrimary">
                      {respondent.gender}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Divider />
              <Grid container style={{ marginTop: 16, marginBottom: 16 }}>
                <Grid item xs={6} sm={6} md={6}>
                  <Typography variant="body2" color="textSecondary">
                    {getLang(lang, "label.PRODUCT_PURCHASED")}
                  </Typography>
                  <Box display="flex" style={{ marginTop: 8 }}>
                    <img
                      src={
                        respondent.product.image
                          ? respondent.product.image
                          : DefaultImg
                      }
                      alt=""
                      className={classes.profile}
                    />
                    <Box ml={1}>
                      <Typography variant="subtitle2" color="textPrimary">
                        {respondent.product.name}
                      </Typography>
                      <Typography variant="subtitle2" color="textPrimary">
                        {respondent.product.serialnumber}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Typography variant="body2" color="textSecondary">
                    {getLang(lang, "label.SUBMITTED_DATE_AND_TIME")}
                  </Typography>
                  <Box style={{ marginTop: 8 }}>
                    <Typography variant="subtitle2" color="textPrimary">
                      {moment(respondent.submit_date).format("lll")}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Divider />
            </Box>
            <Box mb={3}>
              <Box mb={1}>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  style={{ fontWeight: "bold" }}
                >
                  {getLang(lang, "label.QUESTIONS")}
                </Typography>
              </Box>
              <Box>
                {respondent.qna.map((response, index) => (
                  <Box
                    style={{
                      backgroundColor: "#F2F4F7",
                      padding: 16,
                      borderRadius: "6px",
                      marginBottom: 16,
                    }}
                    key={response + index}
                  >
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      style={{ marginBottom: 2 }}
                    >
                      {getLang(lang, "label.QUESTION")} {index + 1} |{" "}
                      {displayQuestionType(response.type)}
                    </Typography>
                    <Typography
                      variant="body1"
                      color="primary"
                      style={{ fontWeight: "bold", marginBottom: "12px" }}
                    >
                      {response.title}
                    </Typography>
                    {response.type ===
                    surveyQuestionType.multipleChoiceMultiSelect ? (
                      response.selected_answer ? (
                        <ul
                          style={{
                            margin: "0",
                            padding: "0",
                            paddingLeft: "16px",
                            listStyle: "square",
                          }}
                        >
                          {response.selected_answer.map((res) => {
                            return (
                              <li>
                                {" "}
                                <Typography
                                  variant="subtitle2"
                                  color="textPrimary"
                                >
                                  {response.is_v2
                                    ? !res.choice_id
                                      ? `${getLang(lang, "label.OTHERS")} (${
                                          res.value
                                        })`
                                      : res.value
                                    : res}{" "}
                                </Typography>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <Typography variant="subtitle2" color="textPrimary">
                          {getLang(lang, "label.SKIPPED")}
                        </Typography>
                      )
                    ) : response.type === surveyQuestionType.multiStarRating ? (
                      <>
                        <ul
                          style={{
                            margin: "0",
                            padding: "0",
                            paddingLeft: "16px",
                            listStyle: "square",
                          }}
                        >
                          {response.choices.map((choice) => {
                            let answer = response.selected_answer
                              ? response.selected_answer?.find(
                                  (a) => a.choice_id === choice.id
                                )
                              : null;
                            let rating = answer ? answer.value : 0;

                            return (
                              <li>
                                {" "}
                                <Box>
                                  <Typography
                                    variant="subtitle2"
                                    color="textPrimary"
                                  >
                                    {response.is_v2 ? choice.title : choice}{" "}
                                  </Typography>
                                  <Box
                                    style={{ gap: 4 }}
                                    display="flex"
                                    alignItems="center"
                                    mb={1}
                                  >
                                    {[...new Array(5)].map((_, starIndex) => (
                                      <StarIcon
                                        key={starIndex}
                                        color="primary"
                                        style={{
                                          color:
                                            starIndex + 1 <= rating
                                              ? "#E9BF3B"
                                              : "#AAAAAA",
                                          fontSize: 28,
                                        }}
                                      />
                                    ))}
                                  </Box>
                                </Box>
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    ) : response.type === surveyQuestionType.starRating ? (
                      <Box
                        display="flex"
                        alignItems="center"
                        style={{ gap: 8 }}
                      >
                        <Box
                          style={{ gap: 4 }}
                          display="flex"
                          alignItems="center"
                        >
                          {[...new Array(5)].map((_, starIndex) => (
                            <StarIcon
                              key={starIndex}
                              color="primary"
                              style={{
                                color:
                                  response.selected_answer &&
                                  starIndex + 1 <= response.selected_answer
                                    ? "#E9BF3B"
                                    : "#AAAAAA",
                                fontSize: 28,
                              }}
                            />
                          ))}
                        </Box>
                        <Typography variant="body2">
                          {getLang(
                            lang,
                            response.selected_answer === 1
                              ? "label.VERY_UNSATISFACTORY"
                              : response.selected_answer === 2
                              ? "label.UNSATISFACTORY"
                              : response.selected_answer === 3
                              ? "label.SATISFACTORY"
                              : response.selected_answer === 4
                              ? "label.GOOD"
                              : response.selected_answer === 5
                              ? "label.EXCELLENT"
                              : "label.NOT_RATED"
                          )}
                        </Typography>
                      </Box>
                    ) : (
                      <Typography variant="subtitle2" color="textPrimary">
                        {displayAnswer(response)}
                      </Typography>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
            <Box mb={1.5}>
              <Box>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  style={{ fontWeight: "bold" }}
                >
                  {getLang(lang, "label.OTHER_DETAILS")}
                </Typography>
              </Box>
              <Grid container style={{ marginTop: 8 }}>
                <Grid item xs={4} sm={4} md={4}>
                  <Typography variant="body2" color="textSecondary">
                    {getLang(lang, "label.LOCATION")}
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="column"
                    style={{ marginTop: 8 }}
                  >
                    <Typography variant="subtitle2" color="textPrimary">
                      {respondent.location_address}
                    </Typography>
                    <Typography variant="subtitle2" color="textPrimary">
                      ({respondent.longitude}, {respondent.longitude})
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <Typography variant="body2" color="textSecondary">
                    {getLang(lang, "label.DEVICE")}
                  </Typography>
                  <Box style={{ marginTop: 8 }}>
                    <Typography variant="subtitle2" color="textPrimary">
                      {respondent.scan_device}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <Typography variant="body2" color="textSecondary">
                    {getLang(lang, "label.BROWSER")}
                  </Typography>
                  <Box style={{ marginTop: 8 }}>
                    <Typography variant="subtitle2" color="textPrimary">
                      {respondent.browser}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
}
