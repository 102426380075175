/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { DashboardContext } from "modules/dashboard/pages/dashboard/dashboard.container";
import PackagingInterestChartPanelComponent from "./packagingInterestChartPanel.component";
import dashboardApi from "app/api/dashboard";
import moment from "moment";
import { addAlert } from "modules/notification";
import { getApiLang } from "app/feature/constants";

export default function PackagingInterestChartPanelContainer() {
  const { currentFilter, activeIndex } = useContext(DashboardContext);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.constant.languages);
  const language = useSelector((state) => state.profile.language);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    getData();
  }, [currentFilter]);

  const getData = () => {
    setIsLoading(true);
    setIsError(false);
    let param = {
      start_date: moment(currentFilter.dateRange[0]).format("YYYY-MM-DD"),
      end_date: moment(currentFilter.dateRange[1]).format("YYYY-MM-DD"),
      is_today: activeIndex === 1 ? 1 : 0,
      is_month: activeIndex === 2 || activeIndex === 3 ? 1 : 0,
    };

    dashboardApi
      .getInterestedAdoptingConnectedPackaging(param)
      .then((res) => {
        let data = res.data;
        if (data.pieEnLabels.length === 0 || data.pieThLabels.length === 0) {
          setChartData([]);
        } else {
          let chartData = {
            pieLabels: [],
            pieTotal: [],
          };
          // assume backend return fixed at 2 labels, only en and thai
          chartData.pieLabels = [
            language === "TH" ? data.pieThLabels[0] : data.pieThLabels[0],
            language === "TH" ? data.pieThLabels[1] : data.pieThLabels[1],
          ];
          chartData.pieTotal = data.pieTotal;
          chartData.filteredComparedDate = data.filteredComparedDate;
          setChartData(chartData);
        }
      })
      .catch((error) => {
        dispatch(
          addAlert({
            severity: "error",
            message: getApiLang(lang, error.code),
          })
        );
        setIsError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <PackagingInterestChartPanelComponent
      isLoading={isLoading}
      data={chartData}
      lang={lang}
      isError={isError}
      handleReload={getData}
    />
  );
}
