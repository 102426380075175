import axios from "axios";

const getCumulativePackagingFeatureScan = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        "/analytic/api/v1/scgp/cumulative-connected-packaging-feature-scans",
        {
          start_date: payload.start_date,
          end_date: payload.end_date,
          is_today: payload.is_today,
          is_month: payload.is_month,
        }
      )
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
};

export default getCumulativePackagingFeatureScan;
