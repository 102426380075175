import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import LoadingComponent from "components/loading";
import DashboardScanCountMap from "../../map/dashboardScanCountMap";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 4px 4px 0px rgb(0 0 0 / 10%)",
    backgroundColor: "white",
  },
}));

export default function ScanMapCardComponent({
  mapScans,
  isLoading,
  handleClusterClick,
  handleMarkerClick,
  revealProduct,
  updateRevealProductStatus,
  defaultGeoLocation,
  defaultZoom,
}) {
  const classes = useStyles();

  return (
    <Box classes={{ root: classes.card }}>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height={350}
        >
          <LoadingComponent />
        </Box>
      ) : (
        <Box>
          <DashboardScanCountMap
            mapScans={mapScans}
            handleClusterClick={handleClusterClick}
            handleMarkerClick={handleMarkerClick}
            revealProduct={revealProduct}
            updateRevealProductStatus={updateRevealProductStatus}
            defaultGeoLocation={defaultGeoLocation}
            defaultZoom={defaultZoom}
          />
        </Box>
      )}
    </Box>
  );
}
