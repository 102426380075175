import React from "react";
import { makeStyles, Box, Typography, Collapse } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import ScanMapCard from "../../card/scanMapCard";
import TcgTrendingSummaryCard from "../../card/tcgTrendingSummaryCard";
import {
  summaryCardType,
  trendingSummaryCardType,
} from "modules/dashboard/constants";
import CumulativeRegistrationByIndustryChartPanel from "../cumulativeRegistrationByIndustryChartPanel";
import CumulativeRegistrationByRoleChartPanel from "../cumulativeRegistrationByRoleChartPanel";
import CumulativeSolutionHighlightChartPanel from "../cumulativeSolutionHighlightChartPanel";
import SolutionHighlightRankedPanel from "../solutionHighlightRankedPanel";
import ConnectedPackagingRankedPanel from "../connectedPackagingRankedPanel";
import CumulativeConnectedPackagingChartPanel from "../cumulativeConnectedPackagingChartPanel";
import TcgSummaryCard from "../../card/tcgSummaryCard";
import MostInterestedZonePanel from "../mostInterestedZonePanel";
import PackagingDesignObjectivePanel from "../packagingDesignObjectivePanel";
import PackagingExpectationPanel from "../packagingExpectationPanel";
import PackagingInterestChartPanel from "../packagingInterestChartPanel";
import MostInterestedPackagingFeaturePanel from "../mostInterestedPackagingFeaturePanel";
import IisVisitingSatisfactionPanel from "../iisVisitingSatisfactionPanel";
import IndustryRankedPanel from "../industryRankedPanel";
import RoleInCompanyRankedPanel from "../roleRankedPanel";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#FFFFFF",
  },
  darkPanel: {
    background: "#9CA6A9",
    border: "1px solid #D0D5DD",
    borderRadius: 12,
    padding: 20,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  panel: {
    background: "white",
    border: "1px solid #D0D5DD",
    borderRadius: 12,
    padding: 20,
  },
  tabButton: {
    textTransform: "none",
  },
  twoColumn: {
    overflow: "hidden",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
      rowGap: theme.spacing(2),
    },
  },
  collapseButton: {
    backgroundColor: "#F9FAFB",
    border: "1px solid #D0D5DD",
    padding: "8px 20px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    cursor: "pointer",
  },
  datePicker: {
    "& .rs-picker-default .rs-picker-toggle.rs-btn": {
      borderRadius: 4,
    },
  },
  summaryCardContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    columnGap: theme.spacing(1.5),
    gridRowGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  splitContainer: {
    display: "grid",
    gridTemplateColumns: "1.5fr 2.5fr",
    columnGap: theme.spacing(1.5),
    gridRowGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  splitEvenContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    columnGap: theme.spacing(1.5),
    gridRowGap: theme.spacing(2),
    rowGap: theme.spacing(2),
    marginBottom: 16,
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
}));

export default function TcgDashboardPanelComponent({
  currentFilter,
  activeIndex,
  lang,
  expandRegistrationPanel,
  expandSolutionPanel,
  expandMockupPanel,
  expandSurveyPanel,
  handleExpandRegistrationPanel,
  handleExpandSolutionPanel,
  handleExpandMockupPanel,
  handleExpandSurveyPanel,
}) {
  const classes = useStyles();

  return (
    <Box>
      <Box
        className={classes.darkPanel}
        mt={2}
        onClick={handleExpandRegistrationPanel}
        style={{ cursor: "pointer" }}
      >
        <Typography variant="body1" className={classes.title}>
          {getLang(lang, "label.REGISTRATION")}
        </Typography>
        {expandRegistrationPanel ? (
          <ExpandLess style={{ color: "#FFFFFF" }} />
        ) : (
          <ExpandMore style={{ color: "#FFFFFF" }} />
        )}
      </Box>
      <Collapse in={expandRegistrationPanel} timeout="auto" unmountOnExit>
        <Box mt={2} className={classes.splitEvenContainer}>
          <TcgTrendingSummaryCard
            type={trendingSummaryCardType.totalRegistration}
          />
          <TcgTrendingSummaryCard
            type={trendingSummaryCardType.cumulativeRegistration}
          />
        </Box>
        <Box mt={2} className={classes.splitContainer}>
          <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
            <IndustryRankedPanel />
          </Box>
          <CumulativeRegistrationByIndustryChartPanel />
        </Box>
        <Box mt={2} className={classes.splitContainer}>
          <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
            <RoleInCompanyRankedPanel />
          </Box>
          <CumulativeRegistrationByRoleChartPanel />
        </Box>
      </Collapse>
      <Box
        className={classes.darkPanel}
        mt={2}
        onClick={handleExpandSolutionPanel}
        style={{ cursor: "pointer" }}
      >
        <Typography variant="body1" className={classes.title}>
          {getLang(lang, "label.SOLUTION_HIGHLIGHTS")}
        </Typography>
        {expandSolutionPanel ? (
          <ExpandLess style={{ color: "#FFFFFF" }} />
        ) : (
          <ExpandMore style={{ color: "#FFFFFF" }} />
        )}
      </Box>
      <Collapse in={expandSolutionPanel} timeout="auto" unmountOnExit>
        <Box mt={2} className={classes.splitContainer}>
          <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
            <TcgTrendingSummaryCard
              type={trendingSummaryCardType.totalScannedSolutionHighlight}
            />
            <SolutionHighlightRankedPanel />
          </Box>
          <CumulativeSolutionHighlightChartPanel />
        </Box>
      </Collapse>
      <Box
        className={classes.darkPanel}
        mt={2}
        onClick={handleExpandMockupPanel}
        style={{ cursor: "pointer" }}
      >
        <Typography variant="body1" className={classes.title}>
          {getLang(lang, "label.CONNECTED_PACKAGING_MOCKUPS")}
        </Typography>
        {expandMockupPanel ? (
          <ExpandLess style={{ color: "#FFFFFF" }} />
        ) : (
          <ExpandMore style={{ color: "#FFFFFF" }} />
        )}
      </Box>
      <Collapse in={expandMockupPanel} timeout="auto" unmountOnExit>
        <Box mt={2}>
          <Box display="flex" style={{ gap: 8 }}>
            <TcgTrendingSummaryCard
              type={trendingSummaryCardType.totalMockupScan}
            />
            <TcgTrendingSummaryCard
              type={trendingSummaryCardType.counterfeitReportReceived}
            />
          </Box>
          <Box className={classes.splitContainer} mt={2}>
            <Box>
              <ConnectedPackagingRankedPanel />
            </Box>
            <CumulativeConnectedPackagingChartPanel />
          </Box>
          <Box className={classes.summaryCardContainer}>
            <TcgSummaryCard type={summaryCardType.antiCounterfeitTotalScan} />
            <TcgSummaryCard type={summaryCardType.totalValidScan} />
            <TcgSummaryCard type={summaryCardType.totalSuspendedScan} />
          </Box>
          <Box className={classes.summaryCardContainer}>
            <TcgSummaryCard type={summaryCardType.totalWarrantyScan} />
            <TcgSummaryCard type={summaryCardType.totalLoyaltyPointsScan} />
            <TcgSummaryCard type={summaryCardType.totalLuckyDrawScan} />
          </Box>
        </Box>
        <Box className={classes.panel}>
          <Box mb={2}>
            <Typography variant="body1">
              <b>{getLang(lang, "label.CONSUMER_SCAN_GEOLOCATION")}</b>
            </Typography>
          </Box>
          <ScanMapCard
            lang={lang}
            startDate={currentFilter.dateRange[0]}
            endDate={currentFilter.dateRange[1]}
            defaultGeoLocation={{
              lat: 13.7248785,
              lng: 100.4683021,
            }}
            defaultZoom={10}
          />
          <Box id="container" />
        </Box>
      </Collapse>
      <Box
        className={classes.darkPanel}
        mt={2}
        onClick={handleExpandSurveyPanel}
        style={{ cursor: "pointer" }}
      >
        <Typography variant="body1" className={classes.title}>
          {getLang(lang, "label.POST_TOUR_SURVEY")}
        </Typography>
        {expandSurveyPanel ? (
          <ExpandLess style={{ color: "#FFFFFF" }} />
        ) : (
          <ExpandMore style={{ color: "#FFFFFF" }} />
        )}
      </Box>
      <Collapse in={expandSurveyPanel} timeout="auto" unmountOnExit>
        <Box mt={2} className={classes.splitContainer}>
          <Box display="flex" flexDirection="column" style={{ gap: 16 }}>
            <TcgSummaryCard type={summaryCardType.totalSurveyReceived} />
            <MostInterestedZonePanel />
            <PackagingInterestChartPanel />
          </Box>
          <Box display="flex" flexDirection="column" style={{ gap: 16 }}>
            <PackagingDesignObjectivePanel />
            <PackagingExpectationPanel />
          </Box>
        </Box>
        <Box mt={2}>
          <MostInterestedPackagingFeaturePanel />
        </Box>
        <Box mt={2}>
          <IisVisitingSatisfactionPanel />
        </Box>
      </Collapse>
    </Box>
  );
}