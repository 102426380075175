import React from "react";
import { makeStyles } from "@material-ui/core";
import ReactPlayer from "react-player";

const useStyle = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 2),
    borderRadius: 10,
    overflow: "hidden",
    minWidth: "fit-content",
    "& iframe": {
      border: "none",
      overflow: "hidden"
    },
    maxHeight: 640,
    backgroundColor: "black",
  }
}));

export default function WebVideoComponent({ url, enableAutoplay }) {
  const classes = useStyle();

  return (
    <div className={classes.root}>
      <ReactPlayer
        url={url}
        playing={enableAutoplay}
        controls
        width="100%"
        loop={true}
        volume={1}
        muted={true}
      />
    </div>
  );
}