import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import Chart from "react-apexcharts";

import { getLang } from "app/feature/constants";
import { industries, industryColors } from "modules/dashboard/constants";
import { setIndustryChartColors } from "modules/dashboard/utils/helper";
import moment from "moment";
import "moment/min/locales.min";
import RefreshTable from "components/table/refreshTable";

const useStyles = makeStyles((theme) => ({
  panel: {
    background: "white",
    border: "1px solid #D0D5DD",
    borderRadius: 12,
    padding: 20,

    "& .apexcharts-legend-marker": {
      borderRadius: "50% !important",
      marginRight: 5,
      marginBottom: -2,
      marginLeft: -17,
    },

    "& .apexcharts-legend-series": {
      width: 180,
      paddingLeft: 17,
    },

    "& .apexcharts-xaxis, .apexcharts-yaxis-title, .apexcharts-yaxis": {
      "& text": {
        fill: "#7C8DB5",
      },
    },
  },
}));

export default function CumulativeConnectedPackagingChartPanelComponent({
  isLoading,
  data,
  lang,
  isError,
  handleReload,
}) {
  const classes = useStyles();

  // apexchart configuration & function
  const chartID = "solution-highlight-chart";

  const displayData = (ref) => {
    if (!ref || Object.keys(ref).length === 0) {
      return null;
    }
    const colorSet = setIndustryChartColors(ref.datasets, industryColors);

    const options = {
      chart: {
        stacked: true,
        id: chartID,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          dataLabels: {
            style: {
              fontSize: 16,
              fontFamily: "Roboto",
              fontWeight: "bold",
              colors: ["#f00", "#fff"],
            },
            enabled: false,
            total: {
              enabled: false,
              offsetX: 10,
              offsetY: 0,
              style: {
                color: "#373d3f",
                fontSize: "12px",
                fontFamily: undefined,
                fontWeight: 600,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return parseInt(val);
          },
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: ref.labels,
        labels: {
          // hideOverlappingLabels: true,
          // rotateAlways: true,
          // rotate: -45,
          style: {
            fontFamily: "Roboto",
          },
        },
      },
      yaxis: {
        show: true,
        title: {
          text: getLang(lang, "label.SCAN_COUNT"),
          style: {
            fontFamily: "Roboto",
            fontSize: "12px",
            fontWeight: 400,
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "right",
        itemMargin: {
          horizontal: 16,
          vertical: 4,
        },
        markers: {
          shape: ["diamond"],
          size: 7,
          fillColor: "#fff",
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
      markers: {
        shape: ["diamond"],
      },
      colors: colorSet,
    };

    const chartSeries = () => {
      let series = JSON.stringify(ref);
      series = JSON.parse(series);

      series.datasets.forEach((e) => {
        let industry = industries.find((i) => i.id === e.industry);
        e.name = getLang(lang, industry?.name);
      });

      return series;
    };

    return (
      <Box>
        <Chart
          options={options}
          series={chartSeries().datasets}
          type="bar"
          height="375px"
        ></Chart>
      </Box>
    );
  };

  let formattedStartDate = ""; // Declare variables outside the if block
  let formattedEndDate = "";
  if (data && data.filteredComparedDate) {
    const [startDateStr, endDateStr] = data.filteredComparedDate?.split(" - ");
    formattedStartDate = moment(startDateStr).format("ll");
    formattedEndDate = endDateStr ? moment(endDateStr).format("ll") : "";
  }

  return (
    <Box id="campaign-conversion" className={classes.panel}>
      {isLoading ? (
        <Box
          height={375}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : isError ? (
        <RefreshTable handleReload={handleReload} />
      ) : (
        <>
          <Box
            marginBottom="4px"
            display="flex"
            alignItems="center"
            style={{ gap: 16 }}
          >
            <Typography variant="body1">
              <b>
                {getLang(
                  lang,
                  "label.CUMULATIVE_CONNECTED_PACKAGING_FEATURE_SCAN"
                )}
              </b>
            </Typography>
            <Typography variant="body2" style={{ color: "#7C8DB5" }}>
              {formattedStartDate} - {formattedEndDate}
            </Typography>
          </Box>
          {data?.isEmpty ? (
            <Box
              height={375}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography variant="body1" align="center">
                <b>{getLang(lang, "label.DATA_NOT_FOUND")}</b>
              </Typography>
              <Typography variant="body2" align="center">
                {getLang(
                  lang,
                  "message.info.YOU_DONT_HAVE_DATA_SELECTED_FILTER"
                )}
              </Typography>
            </Box>
          ) : (
            displayData(data)
          )}
        </>
      )}
    </Box>
  );
}
