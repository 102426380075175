import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import Chart from "react-apexcharts";
import { getLang } from "app/feature/constants";
// import { industries } from "modules/dashboard/constants";
import moment from "moment";
import "moment/min/locales.min";
import RefreshTable from "components/table/refreshTable";

const useStyles = makeStyles((theme) => ({
  panel: {
    background: "white",
    border: "1px solid #D0D5DD",
    borderRadius: 12,
    padding: 20,

    "& .apexcharts-legend-marker": {
      borderRadius: "50% !important",
      marginRight: 5,
      marginBottom: -2,
      marginLeft: -17,
    },

    "& .apexcharts-legend-series": {
      width: 180,
      paddingLeft: 17,
    },

    "& .apexcharts-xaxis, .apexcharts-yaxis-title, .apexcharts-yaxis": {
      "& text": {
        fill: "#7C8DB5",
      },
    },

    "& .pieTooltipContainer": {
      backgroundColor: "#FFFFFF",
      border: "1px solid #F2F4F7",
    },

    "& .pieTooltipHeader": {
      backgroundColor: "#ECEFF1",
      padding: "4px 8px",
    },

    "& .pieTooltipTitle": {
      fontFamily: "Roboto",
      color: "black",
      fontSize: 12,
    },

    "& .pieTooltipContent": {
      padding: 8,
      display: "flex",
      alignItems: "center",
      gap: 8,
    },

    "& .pieTooltipMarker": {
      width: 13,
      height: 13,
      borderRadius: "50%",
    },

    "& .pieTooltipLabel": {
      fontFamily: "Roboto",
      color: "black",
      fontSize: 12,
    },
  },
}));

export default function PackagingInterestChartPanelComponent({
  isLoading,
  data,
  lang,
  isError,
  handleReload,
}) {
  const classes = useStyles();

  // apexchart configuration & function
  const chartID = "pie-chart";

  let formattedStartDate = ""; // Declare variables outside the if block
  let formattedEndDate = "";
  if (data && data.filteredComparedDate) {
    const [startDateStr, endDateStr] = data.filteredComparedDate?.split(" - ");
    formattedStartDate = moment(startDateStr).format("ll");
    formattedEndDate = endDateStr ? moment(endDateStr).format("ll") : "";
  }

  const displayData = (ref) => {
    if (!ref || Object.keys(ref).length === 0) {
      return null;
    }
    let colors = {
      yes: "#6184FF",
      no: "#F97066",
    };

    let colorPayload = [];

    // fixed color order
    colorPayload.push(colors["yes"]);
    colorPayload.push(colors["no"]);
    if (colorPayload.length === 0) colorPayload.push(colors["yes"]);

    const colorSet = colorPayload;

    const options = {
      chart: {
        id: chartID,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      colors: colorSet,
      tooltip: {
        followCursor: true,
        formatter: function (val) {
          return parseInt(val);
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          return `
              <div class="pieTooltipContainer">
                <div class="pieTooltipHeader">
                  <span class="pieTooltipTitle">${formattedStartDate} - ${formattedEndDate}</span>
                </div>
                <div class="pieTooltipContent">
                  <div style="background-color:${w.config.colors[seriesIndex]}" class="pieTooltipMarker"></div>
                  <div class="pieTooltipLabel">${w.config.labels[seriesIndex]}: <b>${series[seriesIndex]}</b></div>
                </div>
              </div>
            `;
        },
      },
      plotOptions: {
        pie: {
          donut: {
            size: "50%",
          },
        },
      },
      xaxis: {
        categories: ref.pieLabels,
      },
      legend: {
        position: "right",
        itemMargin: {
          horizontal: 16,
          vertical: 4,
        },
        markers: {
          shape: ["diamond"],
          size: 7,
          fillColor: "#fff",
        },
        onItemClick: {
          toggleDataSeries: false,
        },
      },
      labels: ref.pieLabels,
    };

    // const chartSeries = () => {
    //   let series = JSON.stringify(ref);
    //   series = JSON.parse(series);

    //   series.datasets.forEach((e) => {
    //     let industry = industries.find((i) => i.id === e.industry);
    //     e.name = getLang(lang, industry?.name);
    //   });

    //   return series;
    // };

    return (
      <Box mt={2}>
        <Chart
          options={options}
          series={ref.pieTotal}
          type="donut"
          height="170px"
        ></Chart>
      </Box>
    );
  };

  return (
    <Box id="campaign-conversion" className={classes.panel}>
      {isLoading ? (
        <Box
          height={270}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : isError ? (
        <RefreshTable handleReload={handleReload} />
      ) : (
        <>
          <Box
            marginBottom="4px"
            display="flex"
            alignItems="center"
            style={{ gap: 16 }}
          >
            <Typography variant="body1">
              <b>
                {getLang(
                  lang,
                  "label.INTERESTED_IN_ADOPTING_CONNECTED_PACKAGING"
                )}
              </b>
            </Typography>
          </Box>
          {data?.length === 0 ? (
            <Box
              height={270}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Typography variant="body1" align="center">
                <b>{getLang(lang, "label.DATA_NOT_FOUND")}</b>
              </Typography>
              <Typography variant="body2" align="center">
                {getLang(
                  lang,
                  "message.info.YOU_DONT_HAVE_DATA_SELECTED_FILTER"
                )}
              </Typography>
            </Box>
          ) : (
            displayData(data)
          )}
        </>
      )}
    </Box>
  );
}
