import React from "react";
import { Formik, ErrorMessage } from "formik";
import {
  Box,
  Paper,
  Button,
  makeStyles,
  Typography,
  Divider,
  MenuItem,
  RadioGroup,
  InputAdornment,
  FormControlLabel,
  Radio,
  Tooltip,
} from "@material-ui/core";
import * as Yup from "yup";
import InputTextField from "components/input/inputTextField";
import classnames from "classnames";
import AntSwitch from "components/switch";
import clsx from "clsx";
import CountrySelect from "components/select/countrySelect";
import countries from "lib/constants/countries"
import { getLang } from "app/feature/constants";
import languageType from "lib/constants/languages";
import EditorComponent from "components/editor/editor.container";
import InputHelper from "lib/InputHelper";
import LoadingComponent from "components/loading";
import LanguageSelect from "../../select/languageSelect";
import HelpOutlinedIcon from "@material-ui/icons/HelpOutlined";

const useStyles = makeStyles((theme) => ({
  text: {
    display: "flex",
    alignItems: "center"
  },
  bool: {
    display: "flex",
    alignItems: "center",
    placeContent: "center"
  },
  button: {
    display: "flex",
    justifyContent: "flex-end"
  },
  form: {
    width: "80%"
  },
  formInside: {
    width: "60%",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  socialMediaName: {
    width: "20%",
    [theme.breakpoints.down("xs")]: {
      width: "30%"
    }
  },
  socialMediaTextField: {
    width: "60%",
    [theme.breakpoints.down("xs")]: {
      width: "70%"
    }
  },
  switchSocialMedia: {
    width: "60%",
    padding: "4px 0px 8px 0px",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  description: {
    color: "#818D91",
  },
  subField: {
    marginLeft: 20,
    padding: "5px 5px 5px 16px",
    borderLeft: "0.1rem solid #D0D5DD",
    boxSizing: "border-box"
  }
}));

export default function ConsumerPageSettingPanelComponent({
  hasCompanyEditAccessRight,
  hasCompanyEditProductProfileAccessRight,
  handleSubmit,
  settings,
  isChanging,
  lang,
  isLoading
}) {
  const classes = useStyles();
  return (isLoading ? <LoadingComponent height={200} />
    : (
      <Paper elevation={0} style={{ width: "100%" }}>
        <Formik
          enableReinitialize
          initialValues={{
            enableCounterfeitReport: settings.enableCounterfeitReport,
            counterfeitReportEmail: settings.counterfeitReportEmail,
            enableConsumerLogin: settings.enableConsumerLogin,
            isLoginRequired: settings.isLoginRequired,
            enableLocationTracking: settings.enableLocationTracking,
            enableContactNumber: settings.enableContactNumber,
            contactNumber: settings.contactNumber,
            contactCode: countries.find(({ phone }) => phone === settings.contactCode),
            enableContactEmail: settings.enableContactEmail,
            contactEmail: settings.contactEmail,
            defaultLanguage: languageType.findIndex(
              (lg) => lg.code === settings.defaultLanguage
            ),
            enableFacebook: settings.enableFacebook,
            facebookUrl: settings.facebookUrl,
            enableWhatsapp: settings.enableWhatsapp,
            whatsappUrl: settings.whatsappUrl,
            enableYoutube: settings.enableYoutube,
            youtubeUrl: settings.youtubeUrl,
            enableWebsite: settings.enableWebsite,
            websiteUrl: settings.websiteUrl,
            enablePrivacyPolicy: settings.enablePrivacyPolicy || 0,
            privacyPolicyTitle: settings.privacyPolicyTitle || "",
            privacyPolicyMessage: settings.privacyPolicyMessage || "",
            privacyPolicyUrl: settings.privacyPolicyUrl || "",
            disagreeCheck: parseInt(settings.disagreeCheck) || 1,
            disagreeUrl: settings.disagreeUrl || "",
            testCheck: 1,
            enableScanCookieSkipCount: settings.enableScanCookieSkipCount ?? true,
            scanCookieRetainHour: settings.scanCookieRetainHour ?? 24,
            language: 'EN',
            enableCustomSuspendMessage: settings.enableCustomSuspendMessage,
            customSuspendMessageValues: {EN: "This product has been suspended", ...settings.customSuspendMessageValues},
            defaultDisplayingCompanyProfile: settings.defaultDisplayingCompanyProfile ?? 1
          }}
          validationSchema={Yup.object({
            counterfeitReportEmail: Yup.string().when("enableCounterfeitReport", {
              is: true,
              then: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
                .email(getLang(lang, "message.error.FORM_VALIDATE_EMAIL"))
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })),
              otherwise: Yup.string()
                .email(getLang(lang, "message.error.FORM_VALIDATE_EMAIL"))
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })),
            }),
            contactNumber: Yup.string().when("enableContactNumber", {
              is: true,
              then: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
                .trim()
                .min(6, getLang(lang, "message.error.FORM_VALIDATE_MIN", { value: getLang(lang, "message.error.NUMBERS_6") }))
                .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$/, getLang(lang, "message.error.FORM_VALIDATE_PHONE")),
              otherwise: Yup.string().trim()
                .min(6, getLang(lang, "message.error.FORM_VALIDATE_MIN", { value: getLang(lang, "message.error.NUMBERS_6") }))
                .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$/, getLang(lang, "message.error.FORM_VALIDATE_PHONE")),
            }),
            contactEmail: Yup.string().when("enableContactEmail", {
              is: true,
              then: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
                .email(getLang(lang, "message.error.FORM_VALIDATE_EMAIL"))
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })),
              otherwise: Yup.string()
                .email(getLang(lang, "message.error.FORM_VALIDATE_EMAIL"))
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })),
            }),
            defaultLanguage: Yup.number().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED")),
            facebookUrl: Yup.string().when("enableFacebook", {
              is: true,
              then: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
                .trim()
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })),
              otherwise: Yup.string().trim()
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })),
            }),
            whatsappUrl: Yup.string().when("enableWhatsapp", {
              is: true,
              then: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
                .trim()
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })),
              otherwise: Yup.string().trim()
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })),
            }),
            youtubeUrl: Yup.string().when("enableYoutube", {
              is: true,
              then: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
                .trim()
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })),
              otherwise: Yup.string().trim()
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })),
            }),
            websiteUrl: Yup.string().when("enableWebsite", {
              is: true,
              then: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
                .trim()
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })),
              otherwise: Yup.string().trim()
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })),
            }),
            privacyPolicyTitle: Yup.string().when("enablePrivacyPolicy", {
              is: true,
              then: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
                .trim()
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })),
              otherwise: Yup.string().trim()
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })),
            }),
            privacyPolicyMessage: Yup.string().when("enablePrivacyPolicy", {
              is: true,
              then: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
                .trim()
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })),
              otherwise: Yup.string().trim()
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })),
            }),
            privacyPolicyUrl: Yup.string().when("enablePrivacyPolicy", {
              is: true,
              then: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
                .trim()
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 }))
                // eslint-disable-next-line
                .matches(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/, getLang(lang, "message.error.INVALID_URL")),
              otherwise: Yup.string().trim()
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })),
            }),
            disagreeUrl: Yup.string().when("disagreeCheck", {
              is: 3,
              then: Yup.string().required(getLang(lang, "message.error.FORM_VALIDATE_REQUIRED"))
                .trim()
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 }))
                // eslint-disable-next-line
                .matches(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/, getLang(lang, "message.error.INVALID_URL")),
              otherwise: Yup.string().trim()
                .max(255, getLang(lang, "message.error.FORM_VALIDATE_MAX", { value: 255 })),
            }),
            customSuspendMessageValues: Yup.mixed()
              .test(
                "empty",
                {
                  error: 'empty',
                  label: getLang(lang, 'message.error.FORM_VALIDATE_REQUIRED'),
                },
                (value) => {
                  for (const key of Object.keys(value)) {
                    if (!value[key]) {
                      return false
                    }
                  }

                  return true;
                }
              ),
          })}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              {[
                { title: getLang(lang, "label.GENERAL") },
                { title: getLang(lang, "label.COMPANY_CONTACT") },
                { title: getLang(lang, "label.LANGUAGE") },
                { title: getLang(lang, "label.COMPANY_SOCIAL_MEDIA") },
                { title: getLang(lang, "label.PRIVACY_POLICY") },
              ].map((field, index, { length }) => {

                return (
                  <div key={index}>
                    <Box p={3} pt={0}>
                      <Typography
                        variant="body1"
                        color="primary"
                        style={{ fontWeight: "500" }}
                      >
                        {field.title}
                      </Typography>
                    </Box>

                    <Box style={{ display: "flex", placeContent: "center" }}>
                      {field.title === getLang(lang, "label.GENERAL") && (
                        <Box className={classes.form}>
                          {[
                            {
                              label: getLang(lang, "label.ENABLE_COUNTERFEIT_REPORT"),
                              name: "enableCounterfeitReport",
                              type: "bool",
                              disabled: !hasCompanyEditAccessRight
                            },
                            {
                              label: getLang(lang, "label.COUNTERFEIT_REPORT_CORRESPONDANCE_EMAIL"),
                              name: "counterfeitReportEmail",
                              type: "text",
                              disabled: !hasCompanyEditAccessRight
                            },
                            {
                              label: getLang(lang, "label.ENABLE_CONSUMER_LOGIN"),
                              description: getLang(lang, "paragraph.ALLOW_CONSUMER_LOGIN"),
                              name: "enableConsumerLogin",
                              type: "bool",
                              disabled: !hasCompanyEditAccessRight
                            },
                            {
                              label: getLang(lang, "label.ENABLE_CONSUMER_LOGIN_REQUIRED"),
                              description: getLang(lang, "paragraph.CONSUMER_LOGIN_REQUIRED"),
                              name: "isLoginRequired",
                              type: "bool",
                              disabled: !hasCompanyEditAccessRight,
                              children: true
                            },
                            {
                              label: getLang(lang, "label.ENABLE_CONSUMER_LOCATION_TRACKING"),
                              name: "enableLocationTracking",
                              type: "bool",
                              disabled: !hasCompanyEditAccessRight
                            },
                            {
                              label: getLang(lang, "label.ENABLE_SKIP_COOKIE"),
                              description: getLang(lang, "paragraph.ENABLE_SKIP_COOKIE"),
                              name: "enableScanCookieSkipCount",
                              type: "bool",
                              disabled: !hasCompanyEditAccessRight,
                            },
                            {
                              label: getLang(lang, "label.COOKIE_RETAIN_HOUR"),
                              description: null,
                              name: "scanCookieRetainHour",
                              type: "dropdown",
                              option: [
                                {
                                  value: 1,
                                  label: getLang(lang, "label.HOUR", {hour: 1}),
                                },
                                {
                                  value: 3,
                                  label: getLang(lang, "label.HOURS", {hour: 3}),
                                },
                                {
                                  value: 6,
                                  label: getLang(lang, "label.HOURS", {hour: 6}),
                                },
                                {
                                  value: 12,
                                  label: getLang(lang, "label.HOURS", {hour: 12}),
                                },
                                {
                                  value: 24,
                                  label: getLang(lang, "label.DAY", {day: 1}),
                                },
                                {
                                  value: 48,
                                  label: getLang(lang, "label.DAYS", {day: 2}),
                                },
                                {
                                  value: 72,
                                  label: getLang(lang, "label.DAYS", {day: 3}),
                                },
                              ],
                              disabled: !hasCompanyEditAccessRight,
                              children: true
                            },
                            {
                              label: getLang(lang, "label.CUSTOM_SUSPEND_MESSAGE"),
                              description: getLang(lang, "paragraph.CUSTOM_SUSPEND_MESSAGE"),
                              type: "custom_suspend_message",
                              disabled: !hasCompanyEditAccessRight,
                            },
                            {
                              label: getLang(lang, "label.DEFAULT_DISPLAYING_COMPANY_PROFILE"),
                              description: getLang(lang, "paragraph.DEFAULT_DISPLAYING_COMPANY_PROFILE"),
                              type: 'displaying_company_profile',
                              disabled: !hasCompanyEditAccessRight,
                              name: 'defaultDisplayingCompanyProfile',
                            }
                          ].map((field, index) => {
                            return (
                              <Box
                                key={index}
                                mt={field.type === "text" ? 0 : 0}
                                mb={1}
                              >
                                <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                                  {field.type === "bool" && (
                                    <Box className={clsx(classes.formInside, {
                                      [classes.subField]: field.children
                                    })}>
                                      <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <Typography variant="subtitle1">
                                          {field.label}
                                        </Typography>
                                        <AntSwitch
                                          checked={formik.values[field.name]}
                                          onChange={(ev) => {
                                            formik.setFieldValue(
                                              field.name,
                                              ev.target.checked
                                            );
                                            if (
                                              field.name ===
                                              "enableConsumerLogin" &&
                                              !ev.target.checked
                                            ) {
                                              formik.setFieldValue(
                                                "isLoginRequired",
                                                false
                                              );
                                            }
                                            if (
                                              field.name === "isLoginRequired" &&
                                              ev.target.checked &&
                                              !formik.getFieldProps(
                                                "enableConsumerLogin"
                                              ).value
                                            ) {
                                              formik.setFieldValue(
                                                "enableConsumerLogin",
                                                true
                                              );
                                            }
                                          }}
                                          disabled={
                                            field.disabled || formik.isSubmitting
                                          }
                                        />
                                      </Box>
                                      {field.description && (
                                        <Typography variant="body2" className={classes.description}>{field.description}</Typography>
                                      )}
                                    </Box>
                                  )}

                                  {field.type === "text" && (
                                    <Box className={classes.formInside}>
                                      <InputTextField
                                        name={field.name}
                                        placeholder={
                                          formik.getFieldProps(field.name).value
                                            ? null
                                            : field.label
                                        }
                                        label=""
                                        hiddenLabel
                                        variant="filled"
                                        size="small"
                                        fullWidth
                                        margin="dense"
                                        {...formik.getFieldProps(field.name)}
                                        disabled={formik.isSubmitting}
                                        error={
                                          formik.touched[field.name] &&
                                            formik.errors[field.name]
                                            ? true
                                            : false
                                        }
                                        helperText={ErrorMessage({
                                          name: field.name
                                        })}
                                      />
                                    </Box>
                                  )}

                                  {field.type === "dropdown" && (
                                    <Box className={clsx(classes.formInside, {
                                      [classes.subField]: field.children
                                    })}>
                                      <Typography variant="subtitle1">
                                        {field.label}
                                      </Typography>
                                      <InputTextField
                                        select
                                        name={field.name}
                                        hiddenLabel
                                        variant="filled"
                                        size="small"
                                        fullWidth
                                        margin="dense"
                                        disabled={field.disabled ? true : formik.isSubmitting}
                                        error={!!(formik.touched[field.name] && formik.errors[field.name])}
                                        helperText={ErrorMessage({
                                          name: field.name
                                        })}
                                        {...formik.getFieldProps(field.name)}
                                      >
                                        {field.option.map((select, index) => (
                                          <MenuItem value={select.value} key={index}>
                                            {select.label}
                                          </MenuItem>
                                        ))}
                                      </InputTextField>
                                      {field.description && (
                                        <Typography variant="body2" className={classes.description}>{field.description}</Typography>
                                      )}
                                    </Box>
                                  )}

                                  {field.type === 'custom_suspend_message' && (
                                    <Box className={clsx(classes.formInside, {
                                      [classes.subField]: field.children
                                    })}>
                                      <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <Typography variant="subtitle1">
                                          {field.label}
                                        </Typography>
                                        <AntSwitch
                                          checked={formik.values['enableCustomSuspendMessage']}
                                          onChange={(ev) => {
                                            formik.setFieldValue(
                                              'enableCustomSuspendMessage',
                                              ev.target.checked
                                            );
                                          }}
                                          disabled={
                                            field.disabled || formik.isSubmitting
                                          }
                                        />
                                      </Box>
                                      {field.description && (
                                        <Typography variant="body2" className={classes.description}>{field.description}</Typography>
                                      )}
                                      <Box pt={1}>
                                        <LanguageSelect
                                          handleAddLang={(code) => {
                                            formik.setFieldValue('customSuspendMessageValues', {
                                              ...formik.values.customSuspendMessageValues,
                                              [code]: {
                                                EN: "This product has been suspended",
                                                VI: "Sản phẩm này đã bị đình chỉ",
                                                TH: "ผลิตภัณฑ์นี้ถูกระงับ",
                                                ZH: "该产品已被停用",
                                                "ZH-TW": "該產品已被停用",
                                                MS: "Produk ini telah digantung",
                                                ID: "Produk ini telah ditangguhkan",
                                                TL: "Ang produkto na ito ay suspendido",
                                              }[code],
                                            });
                                            formik.setFieldValue('language', code);
                                          }}
                                          handleChangeLang={(code) => {
                                            formik.setFieldValue('language', code);
                                          }}
                                          handleDeleteLang={async (code) => {
                                            const {[code]: _, ...temp} = formik.values.customSuspendMessageValues;
                                            await formik.setFieldValue('customSuspendMessageValues', temp);
                                            await formik.setFieldValue('language', 'EN');
                                            await formik.setFieldValue('customSuspendMessageValues.EN', formik.values.customSuspendMessageValues.EN)
                                          }}
                                          value={formik.values.language}
                                          languageValue={formik.values.customSuspendMessageValues}
                                          disabled={field.disabled ? true : formik.isSubmitting || !formik.values.enableCustomSuspendMessage}
                                          anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "left",
                                          }}
                                          transformOrigin={{
                                            vertical: "top",
                                            horizontal: "left",
                                          }}
                                        />
                                      </Box>
                                      <Box>
                                        <InputTextField
                                          name="customSuspendMessageValues"
                                          placeholder={getLang(lang, "placeholder.PLEASE_ENTER")}
                                          hiddenLabel
                                          variant="filled"
                                          size="small"
                                          fullWidth
                                          margin="dense"
                                          rows={4}
                                          multiline
                                          inputProps={{
                                            maxlength: 200
                                          }}
                                          value={formik.values.customSuspendMessageValues[formik.values.language]}
                                          disabled={field.disabled ? true : formik.isSubmitting || !formik.values.enableCustomSuspendMessage}
                                          onChange={(event) => {
                                            formik.setFieldValue(`customSuspendMessageValues.${formik.values.language}`, event.target.value.replace('\n', ''))
                                          }}
                                          error={!!formik.errors['customSuspendMessageValues']}
                                          helperText={ErrorMessage({
                                            name: 'customSuspendMessageValues',
                                            children: !!formik.errors['customSuspendMessageValues'] ? !!formik.errors['customSuspendMessageValues'].label : '',
                                          })}
                                        />
                                      </Box>
                                    </Box>
                                  )}

                                  {field.type === 'displaying_company_profile' && hasCompanyEditProductProfileAccessRight && (
                                    <Box className={clsx(classes.formInside, {
                                      [classes.subField]: field.children
                                    })}>
                                      <Typography variant="subtitle1">
                                        {field.label}
                                      </Typography>
                                      {field.description && (
                                        <Typography variant="body2" className={classes.description}>{field.description}</Typography>
                                      )}
                                      <Box>
                                        <Box>
                                          <FormControlLabel
                                            label={getLang(lang, 'label.COMPANY_INFO')}
                                            control={(
                                              <Radio
                                                checked={formik.values.defaultDisplayingCompanyProfile === 1}
                                                onClick={() => formik.setFieldValue(field.name, 1)}
                                              />
                                            )}
                                          />
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                          <FormControlLabel
                                            label={getLang(lang, 'label.BRANCH_INFO')}
                                            control={(
                                              <Radio
                                                checked={formik.values.defaultDisplayingCompanyProfile === 2}
                                                onClick={() => formik.setFieldValue(field.name, 2)}
                                              />
                                            )}
                                          />
                                          <Box pb="2px" display="flex" alignItems="center">
                                            <Tooltip
                                              title={getLang(
                                                lang,
                                                "paragraph.PRODUCT_PROFILE_BRANCH_BRAND_TOOLTIP"
                                              )}
                                              arrow
                                            >
                                              <HelpOutlinedIcon color="primary" fontSize="small" />
                                            </Tooltip>
                                          </Box>
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                          <FormControlLabel
                                            label={getLang(lang, 'label.BRAND_INFO')}
                                            control={(
                                              <Radio
                                                checked={formik.values.defaultDisplayingCompanyProfile === 3}
                                                onClick={() => formik.setFieldValue(field.name, 3)}
                                              />
                                            )}
                                          />
                                          <Box pb="2px" display="flex" alignItems="center">
                                            <Tooltip
                                              title={getLang(
                                                lang,
                                                "paragraph.PRODUCT_PROFILE_BRAND_ICON_TOOLTIP"
                                              )}
                                              arrow
                                            >
                                              <HelpOutlinedIcon color="primary" fontSize="small" />
                                            </Tooltip>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      )}

                      {field.title === getLang(lang, "label.COMPANY_CONTACT") && (
                        <Box style={{ width: "80%" }}>
                          {[
                            {
                              label: getLang(lang, "label.SHOW_ON_PRODUCT_LANDING_PAGE"),
                              name: "enableContactEmail",
                              type: "bool",
                              disabled: !hasCompanyEditAccessRight
                            },
                            {
                              label: getLang(lang, "label.ENTER"),
                              nameContext: getLang(lang, "label.EMAIL"),
                              name: "contactEmail",
                              type: "text",
                              disabled: !hasCompanyEditAccessRight
                            },
                            {
                              label: getLang(lang, "label.SHOW_ON_PRODUCT_LANDING_PAGE"),
                              name: "enableContactNumber",
                              type: "bool",
                              disabled: !hasCompanyEditAccessRight
                            },
                            {
                              label: getLang(lang, "label.ENTER"),
                              nameContext: getLang(lang, "label.CONTACT"),
                              name: "contactNumber",
                              type: "text",
                              disabled: !hasCompanyEditAccessRight
                            },
                            {
                              label: getLang(lang, "label.SHOW_ON_PRODUCT_LANDING_PAGE"),
                              name: "enableWebsite",
                              type: "bool",
                              disabled: !hasCompanyEditAccessRight
                            },
                            {
                              label: getLang(lang, "label.ENTER_URL"),
                              nameContext: getLang(lang, "label.WEBSITE"),
                              name: "websiteUrl",
                              type: "text",
                              disabled: !hasCompanyEditAccessRight
                            },
                          ].map((field, index) => {
                            return (
                              <Box
                                key={index}
                                className={classnames({
                                  [classes.text]: field.type === "text",
                                  [classes.bool]: field.type === "bool"
                                })}
                              >
                                {field.type === "text" && (
                                  <>
                                    <Box className={classes.socialMediaName}>
                                      <Typography
                                        variant="body1"
                                        color="primary"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {field.nameContext}
                                      </Typography>
                                    </Box>

                                    <Box className={classes.socialMediaTextField}>
                                      <Box display="flex" gridColumnGap={12}>
                                        {field.name === "contactNumber" &&
                                          <CountrySelect
                                            type="phone"
                                            textFieldProps={{
                                              style: { width: 140 },
                                              variant: "filled",
                                              size: "small",
                                              margin: "dense",
                                              error: formik.errors.contactCode && formik.touched.contactCode ? true : false,
                                              helperText: ErrorMessage({ name: "contactCode" }),
                                            }}
                                            formProps={{
                                              ...formik.getFieldProps("contactCode"),
                                              value: formik.values.contactCode,
                                              onChange: (e, newValue) => {
                                                formik.setFieldValue("contactCode", newValue);
                                              },
                                              disabled: formik.isSubmitting
                                            }}
                                          />
                                        }
                                        <InputTextField
                                          name={field.name}
                                          placeholder={
                                            formik.getFieldProps(field.name).value
                                              ? null
                                              : field.label
                                          }
                                          label=""
                                          hiddenLabel
                                          variant="filled"
                                          size="small"
                                          fullWidth
                                          margin="dense"
                                          disabled={field.disabled ? true : formik.isSubmitting}
                                          error={
                                            formik.touched[field.name] &&
                                              formik.errors[field.name]
                                              ? true
                                              : false
                                          }
                                          helperText={ErrorMessage({
                                            name: field.name
                                          })}
                                          {...formik.getFieldProps(field.name)}
                                        />
                                      </Box>
                                    </Box>
                                  </>
                                )}

                                {field.type === "bool" && (
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    className={classes.switchSocialMedia}
                                  >
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {field.label}
                                    </Typography>
                                    <AntSwitch
                                      checked={formik.values[field.name]}
                                      onChange={(ev) => {
                                        formik.setFieldValue(
                                          field.name,
                                          ev.target.checked
                                        );
                                      }}
                                      disabled={field.disabled || formik.isSubmitting}
                                    />
                                  </Box>
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      )}

                      {field.title === getLang(lang, "label.LANGUAGE") && (
                        <Box style={{ width: "80%" }}>
                          {[
                            {
                              label: getLang(lang, "label.DEFAULT_LANGUAGE"),
                              name: "defaultLanguage",
                              type: "dropdown",
                              selects: languageType,
                              disabled: !hasCompanyEditAccessRight
                            }
                          ].map((field, index) => {
                            return (
                              <Box
                                key={index}
                                className={classnames({
                                  [classes.text]: field.type === "text",
                                  [classes.text]: field.type === "dropdown",
                                  [classes.bool]: field.type === "bool"
                                })}
                              >
                                {field.type === "dropdown" && (
                                  <>
                                    <Box className={classes.socialMediaName}>
                                      <Typography
                                        variant="body1"
                                        color="primary"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {field.label}
                                      </Typography>
                                    </Box>

                                    <Box className={classes.socialMediaTextField}>
                                      <Box display="flex" gridColumnGap={12}>
                                        <InputTextField
                                          select
                                          name={field.name}
                                          hiddenLabel
                                          variant="filled"
                                          size="small"
                                          fullWidth
                                          margin="dense"
                                          disabled={field.disabled ? true : formik.isSubmitting}
                                          error={
                                            formik.touched[field.name] &&
                                              formik.errors[field.name]
                                              ? true
                                              : false
                                          }
                                          helperText={ErrorMessage({
                                            name: field.name
                                          })}
                                          {...formik.getFieldProps(field.name)}
                                        >
                                          {field.selects.map((select, index) => (
                                            <MenuItem value={index} key={index}>
                                              {field.name === "defaultLanguage" &&
                                                `${select.label}`}
                                            </MenuItem>
                                          ))}
                                        </InputTextField>
                                      </Box>
                                    </Box>
                                  </>
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      )}

                      {field.title === getLang(lang, "label.COMPANY_SOCIAL_MEDIA") && (
                        <Box style={{ width: "80%" }}>
                          {[
                            // {
                            //   label: getLang(lang, "label.SHOW_ON_PRODUCT_LANDING_PAGE"),
                            //   name: "enableWebsite",
                            //   type: "bool",
                            //   disabled: !hasCompanyEditAccessRight
                            // },
                            // {
                            //   label: getLang(lang, "label.ENTER_URL"),
                            //   nameContext: getLang(lang, "label.WEBSITE"),
                            //   name: "websiteUrl",
                            //   type: "text",
                            //   disabled: !hasCompanyEditAccessRight
                            // },
                            {
                              label: getLang(lang, "label.SHOW_ON_PRODUCT_LANDING_PAGE"),
                              name: "enableFacebook",
                              type: "bool",
                              disabled: !hasCompanyEditAccessRight
                            },
                            {
                              label: getLang(lang, "label.ENTER_URL"),
                              nameContext: getLang(lang, "label.FACEBOOK"),
                              name: "facebookUrl",
                              type: "text",
                              disabled: !hasCompanyEditAccessRight
                            },
                            {
                              label: getLang(lang, "label.SHOW_ON_PRODUCT_LANDING_PAGE"),
                              name: "enableWhatsapp",
                              type: "bool",
                              disabled: !hasCompanyEditAccessRight
                            },
                            {
                              label: getLang(lang, "label.ENTER_URL"),
                              nameContext: getLang(lang, "label.WHATSAPP"),
                              name: "whatsappUrl",
                              type: "text",
                              disabled: !hasCompanyEditAccessRight
                            },
                            {
                              label: getLang(lang, "label.SHOW_ON_PRODUCT_LANDING_PAGE"),
                              name: "enableYoutube",
                              type: "bool",
                              disabled: !hasCompanyEditAccessRight
                            },
                            {
                              label: getLang(lang, "label.ENTER_URL"),
                              nameContext: getLang(lang, "label.YOUTUBE"),
                              name: "youtubeUrl",
                              type: "text",
                              disabled: !hasCompanyEditAccessRight
                            },
                          ].map((field, index) => {
                            return (
                              <Box
                                key={index}
                                className={classnames({
                                  [classes.text]: field.type === "text",
                                  [classes.bool]: field.type === "bool"
                                })}
                              >
                                {field.type === "text" && (
                                  <>
                                    <Box className={classes.socialMediaName}>
                                      <Typography
                                        variant="body1"
                                        color="primary"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {field.nameContext}
                                      </Typography>
                                    </Box>

                                    <Box className={classes.socialMediaTextField}>
                                      <InputTextField
                                        name={field.name}
                                        placeholder={
                                          formik.getFieldProps(field.name).value
                                            ? null
                                            : field.label
                                        }
                                        label=""
                                        hiddenLabel
                                        variant="filled"
                                        size="small"
                                        fullWidth
                                        margin="dense"
                                        disabled={
                                          field.disabled
                                            ? true
                                            : formik.isSubmitting
                                        }
                                        error={
                                          formik.touched[field.name] &&
                                            formik.errors[field.name]
                                            ? true
                                            : false
                                        }
                                        helperText={ErrorMessage({
                                          name: field.name
                                        })}
                                        {...formik.getFieldProps(field.name)}
                                      />
                                    </Box>
                                  </>
                                )}

                                {field.type === "bool" && (
                                  <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    className={classes.switchSocialMedia}
                                  >
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                    >
                                      {field.label}
                                    </Typography>
                                    <AntSwitch
                                      checked={formik.values[field.name]}
                                      onChange={(ev) => {
                                        formik.setFieldValue(
                                          field.name,
                                          ev.target.checked
                                        );
                                      }}
                                      disabled={field.disabled || formik.isSubmitting}
                                    />
                                  </Box>
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      )}

                      {field.title === getLang(lang, "label.PRIVACY_POLICY") && (
                        <Box style={{ width: "80%" }}>
                          {[
                            {
                              label: getLang(lang, "label.ENABLE_PRIVACY_POLICY"),
                              description: getLang(lang, 'paragraph.GRANT_PERMISSION_FOR_KOOD_ASIA'),
                              name: "enablePrivacyPolicy",
                              type: "bool",
                              disabled: !hasCompanyEditAccessRight
                            },
                            {
                              label: getLang(lang, 'placeholder.ENTER_PRIVACY_POLICY_TITLE'),
                              nameContext: getLang(lang, "label.PRIVACY_POLICY_TITLE"),
                              name: "privacyPolicyTitle",
                              type: "text",
                              disabled: !hasCompanyEditAccessRight
                            },
                            {
                              title: getLang(lang, "label.PRIVACY_POLICY_MESSAGE"),
                              type: "textBox",
                              disabled: !hasCompanyEditAccessRight
                            },
                            {
                              label: getLang(lang, 'placeholder.ENTER_PRIVACY_POLICY_URL_LINK'),
                              nameContext: getLang(lang, "label.PRIVACY_POLICY_URL_LINK"),
                              name: "privacyPolicyUrl",
                              type: "text",
                              disabled: !hasCompanyEditAccessRight
                            },
                            // {
                            //   label: "If consumer 'Agree' redirect to",
                            //   type: "parent",
                            //   fieldName: 'testCheck',
                            //   valuesType: 'number',
                            //   values: [
                            //     {
                            //       label: "Login Page",
                            //       value: 1,
                            //     }
                            //   ],
                            //   disabled: !hasCompanyEditAccessRight,
                            //   children: true
                            // },
                            {
                              label: getLang(lang, "label.CONSUMER_DISAGREE"),
                              type: 'parent',
                              fieldName: 'disagreeCheck',
                              valuesType: 'number',
                              values: [
                                {
                                  label: getLang(lang, "label.LOGIN_PAGE"),
                                  value: 1,
                                }, {
                                  label: getLang(lang, "label.SKIP_LOGIN_TO_LANDING_PAGE"),
                                  value: 2,
                                }, {
                                  label: getLang(lang, "label.URL_LINK"),
                                  value: 3,
                                  textName: "disagreeUrl"
                                }
                              ],
                              children: true
                            },
                          ].map((field, index) => {
                            return (
                              <Box
                                key={index}
                                className={classnames({
                                  [classes.text]: field.type === "text",
                                  // [classes.bool]: field.type === "bool"
                                })}
                              >
                                {field.type === "text" && (
                                  <>
                                    <Box className={classes.socialMediaName}>
                                      <Typography
                                        variant="body1"
                                        color="primary"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {field.nameContext} &nbsp;<Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                                      </Typography>
                                    </Box>

                                    <Box className={classes.socialMediaTextField}>
                                      <InputTextField
                                        name={field.name}
                                        placeholder={
                                          formik.getFieldProps(field.name).value
                                            ? null
                                            : field.label
                                        }
                                        label=""
                                        hiddenLabel
                                        variant="filled"
                                        size="small"
                                        fullWidth
                                        margin="dense"
                                        disabled={
                                          field.disabled
                                            ? true
                                            : formik.isSubmitting
                                        }
                                        error={
                                          formik.touched[field.name] &&
                                            formik.errors[field.name]
                                            ? true
                                            : false
                                        }
                                        helperText={ErrorMessage({
                                          name: field.name
                                        })}
                                        {...formik.getFieldProps(field.name)}
                                      />
                                    </Box>
                                  </>
                                )}

                                {field.type === "bool" && (
                                  <Box style={{ display: "flex" }}>
                                    {field.description && (
                                      <Box className={classes.socialMediaName}>
                                        <Typography
                                          variant="body1"
                                          color="primary"
                                          style={{ fontWeight: "bold" }}
                                        >{field.label}</Typography>
                                      </Box>
                                    )}
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      alignItems="center"
                                      className={classes.switchSocialMedia}
                                    >
                                      <Typography
                                        variant="body2" className={classes.description}
                                      >
                                        {field.description}
                                      </Typography>
                                      <AntSwitch
                                        checked={formik.values[field.name]}
                                        onChange={(ev) => {
                                          formik.setFieldValue(
                                            field.name,
                                            ev.target.checked
                                          );
                                        }}
                                        disabled={field.disabled || formik.isSubmitting}
                                      />
                                    </Box>

                                  </Box>
                                )}
                                {field.type === "textBox" && (
                                  <>
                                    <Box className={classes.socialMediaName}>
                                      <Typography
                                        variant="body1"
                                        color="primary"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {field.title} <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                                      </Typography>

                                    </Box>
                                    <EditorComponent
                                      type={"edit"}
                                      initialValues={
                                        formik.getFieldProps("privacyPolicyMessage").value
                                      }
                                      handleChange={(value) => {
                                        formik.setFieldValue("privacyPolicyMessage", value);
                                      }}
                                    />
                                  </>
                                )}

                                {field.type === "parent" && (
                                  <>
                                    {field.fieldName === "disagreeCheck" && <Box className={classes.socialMediaName}>
                                      <Typography
                                        variant="body1"
                                        color="primary"
                                        style={{ fontWeight: "bold" }}
                                      >
                                        {getLang(lang, 'label.CONDITION')}
                                        &nbsp; <Typography style={{ color: "#DE350B", display: 'inline-block' }}>*</Typography>
                                      </Typography>
                                    </Box>
                                    }

                                    <Box className={classes.subField}
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        flexDirection="column"
                                      >
                                        <Typography variant="subtitle1">
                                          {field.label}
                                        </Typography>
                                        <RadioGroup
                                          name={field.fieldName}
                                          defaultValue={formik.values[field.fieldName]}
                                          value={formik.values[field.fieldName]}
                                          onChange={(e) => formik.setFieldValue(field.fieldName, field.valuesType === 'number' ? parseInt(e.target.value) : e.target.value)}
                                        >
                                          {
                                            field.values.map(
                                              (value) => (
                                                <>
                                                  <FormControlLabel
                                                    label={value.label}
                                                    value={value.value}
                                                    control={<Radio />}
                                                  />
                                                  {
                                                    value.enableCustomField &&
                                                    <Box paddingLeft={4}>
                                                      <InputTextField
                                                        // disabled={!isEditable || formik.values[field.fieldName] !== value.value}
                                                        value={formik.values[value.customFieldFieldName]}
                                                        type={value.customFieldType}
                                                        onPaste={(e) => InputHelper.preventCopyEDecimalAndNegetiveInNumberInput(e)}
                                                        onKeyDown={(e) => InputHelper.preventTypeEDecimalAndNegetiveInNumberInput(e)}
                                                        onChange={(e) => formik.setFieldValue(value.customFieldFieldName, e.target.value)}
                                                        InputProps={{

                                                          endAdornment: value.customFieldEndAdornment && (
                                                            <InputAdornment position="end">
                                                              <Box
                                                                px={1}
                                                                borderLeft="1px solid rgba(0, 0, 0, 0.1)"
                                                              >
                                                                <Typography className={classes.inputEndormentLabel}>
                                                                  {value.customFieldEndAdornment}
                                                                </Typography>
                                                              </Box>
                                                            </InputAdornment>
                                                          ),
                                                        }}
                                                        inputProps={{
                                                          min: 1
                                                        }}
                                                      />
                                                    </Box>
                                                  }
                                                  {value.textName ?
                                                    <Box className={classes.socialMediaTextField}>
                                                      <InputTextField
                                                        name={value.textName}
                                                        placeholder={
                                                          formik.getFieldProps(value.textName).value
                                                            ? null
                                                            : getLang(lang, 'placeholder.ENTER_URL_LINK')
                                                        }
                                                        label=""
                                                        hiddenLabel
                                                        variant="filled"
                                                        size="small"
                                                        fullWidth
                                                        margin="dense"
                                                        disabled={
                                                          field.disabled
                                                            ? true
                                                            : formik.isSubmitting
                                                        }
                                                        error={
                                                          formik.touched[value.textName] &&
                                                            formik.errors[value.textName]
                                                            ? true
                                                            : false
                                                        }
                                                        helperText={ErrorMessage({
                                                          name: value.textName
                                                        })}
                                                        {...formik.getFieldProps(value.textName)}
                                                      />
                                                    </Box>
                                                    : null}
                                                </>
                                              )
                                            )
                                          }
                                        </RadioGroup>
                                      </Box>
                                      {field.fieldName === "disagreeCheck" &&
                                        <Box style={{
                                          border: "1px solid #EB8D00",
                                          flexDirection: "column",
                                          borderRadius: "2px",
                                          alignItems: "start",
                                          padding: "10px 12px",
                                          gap: "4px",
                                          background: "#FFFAEB",
                                          width: "80%"
                                        }}>
                                          <Typography style={{
                                            width: '100%',
                                            height: '20px',
                                            fontFamily: 'Roboto',
                                            fontStyle: 'normal',
                                            fontWeight: 700,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#FDB022',
                                          }}>
                                            {getLang(lang, 'label.DISAGREE_PRIVACY_POLICY')}
                                          </Typography>
                                          <Typography
                                            style={{
                                              width: '100%',
                                              height: '20px',
                                              fontFamily: 'Roboto',
                                              fontStyle: 'normal',
                                              fontWeight: 400,
                                              fontSize: '14px',
                                              lineHeight: '20px',
                                              color: '#FDB022',
                                              display: 'inline-block',
                                            }}>
                                            {getLang(lang, 'paragraph.CONSUMER_DISAGREE_PRIVACY_POLICY')}
                                          </Typography>
                                        </Box>
                                      }

                                    </Box>
                                  </>
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                    </Box>
                    <Box mt={3} mb={3}>
                      <Divider hidden={length === index + 1} />
                    </Box>
                  </div>
                );
              })}
              {
                hasCompanyEditAccessRight
                  ?
                  <Box mt={3} mb={3} pr={3} className={classes.button}>
                    <Button
                      color="primary"
                      variant="contained"
                      disableElevation
                      type="submit"
                      disabled={formik.isSubmitting || isChanging}
                    >
                      {getLang(lang, "label.UPDATE")}
                    </Button>
                  </Box>
                  : null
              }
            </form>
          )}
        </Formik>
      </Paper>
    ));
}
