import { createSlice } from "@reduxjs/toolkit";
import { SURVEY, surveyQuestionType } from "modules/survey/constants";
import {
  fetchSurveyListing,
  getSurvey,
  fetchRespondentListing,
  fetchRespondentDetails,
  fetchRespondentQuestionListing,
  fetchRespondentAnswerListing,
  fetchTotalResponse,
  fetchTotalVisit,
  fetchSurveyResponseCount,
  fetchSurveyByProduct,
  fetchSurveyByGender,
  fetchSurveyByAge,
  fetchSurveyByDevice,
  fetchSurveyGeoCount,
  fetchResponseByChoiceCount,
  getAvailableSurveys,
} from "../action";

const initialState = {
  surveyList: {
    totalRecords: 0,
    data: [],
  },
  respondentList: {
    totalRecords: 0,
    data: [],
  },
  survey: {
    id: "",
    name: "",
    description: "",
    startDate: "",
    endDate: "",
    questions: [],
    createdAt: "",
    status: "",
    productIds: [],
  },
  respondent: {
    id: "",
    profile_picture: "",
    name: "",
    email: "",
    dateofbirth: "",
    age: "",
    gender: "",
    product: {
      name: "",
      serialnumber: "",
      image: "",
    },
    submit_date: "",
    qna: [],
    location_address: null,
    longitude: null,
    latitude: null,
    scan_device: null,
    browser: null,
  },
  isLoadingSurveyError: false,
  isLoadingRespondentError: false,
  questionList: [],
  selectedQuestion: {
    uuid: "",
    question: "",
    type: "",
    index: 1,
  },
  answerList: {
    recordTotal: 0,
    recordsFiltered: 0,
    recordsAnswer: 0,
    recordsSkip: 0,
    data: [],
  },
  surveyAnalytic: {
    totalResponse: {
      surveyDate: [],
      surveyTotalCount: [],
    },
    totalVisit: {
      value: 0,
      percentage: 0,
      type: "",
    },
    response: {
      value: 0,
      percentage: 0,
      type: "",
    },
    ageCount: [],
    product: {
      label: [],
      data: [],
    },
    gender: {
      label: [],
      data: [],
    },
    device: [],
    totalDevice: 0,
    geo: [],
    choicesCount: {
      label: [],
      data: [],
      countResult: [],
    },
  },
  availableSurveys: {
    list: [],
    currentTotal: 0,
    totalFiltered: 0,
    totalRecords: 0,
    isError: false,
    hasNextPage: true,
  },
};

const surveySlice = createSlice({
  name: SURVEY,
  initialState,
  reducers: {
    setSelectedQuestion(state, action) {
      const { payload } = action;

      state.answerList = initialState.answerList;
      state.selectedQuestion.uuid = payload.id;
      state.selectedQuestion.question = payload.title;
      state.selectedQuestion.type = payload.type;
      state.selectedQuestion.index = payload.index;
      state.selectedQuestion.choices = payload.choices;
    },
    setDeleteSurveyQuestion(state, action) {
      const { payload } = action;
      state.survey.questions.splice(payload, 1);
    },
    setAddSurveyQuestion(state, action) {
      const { payload } = action;
      state.survey.questions.push(payload);
    },
    resetRespondentState(state, action) {
      state.respondent = initialState.respondent;
    },
    resetAvailableSurveys(state) {
      state.availableSurveys = initialState.availableSurveys;
    },
    resetAnswerListing(state) {
      state.answerList = initialState.answerList;
    },
  },
  extraReducers: {
    [fetchSurveyListing.fulfilled]: (state, action) => {
      const { payload } = action;
      const tempArray = [];

      state.surveyList.totalRecords = payload.recordsFiltered;

      payload.data.forEach((item) => {
        tempArray.push({
          surveyId: item.id,
          surveyName: item.survey_name,
          startDate: item.start_date,
          endDate: item.end_date,
          status: item.status,
          createdAt: item.created_at,
          productCount: item.product_count,
          products: item.products,
          respondentCount: item.survey_respondent,
        });
      });

      state.surveyList.data = tempArray;
      state.isLoadingSurveyError = false;
    },
    [fetchSurveyListing.rejected]: (state) => {
      state.isLoadingSurveyError = true;
    },
    [getSurvey.fulfilled]: (state, action) => {
      const { payload } = action;
      const { survey } = state;

      survey.id = payload.survey_id;
      survey.name = payload.survey_name;
      survey.description = payload.survey_description;
      survey.startDate = payload.start_date;
      survey.endDate = payload.end_date;
      survey.questions = payload.survey_questions;
      survey.createdAt = payload.created_at;
      survey.status = payload.status;

      const products = [];
      payload.product.forEach((product) => {
        const id = {
          id: product.product_id,
          name: product.name,
          picture: product.picture,
        };
        products.push(id);
      });
      survey.productIds = products;
    },
    [fetchRespondentListing.fulfilled]: (state, action) => {
      const { payload } = action;
      const tempArray = [];

      state.respondentList.totalRecords = payload.recordsFiltered;

      payload.data.forEach((respondent) => {
        tempArray.push({
          id: respondent.id,
          name: respondent.full_name,
          email: respondent.email,
          product_name: respondent.product_name,
          serial_number: respondent.serial_number,
          created_at: respondent.created_at,
        });
      });

      state.respondentList.data = tempArray;
      state.isLoadingRespondentError = false;
    },
    [fetchRespondentListing.rejected]: (state) => {
      state.isLoadingRespondentError = true;
    },
    [fetchRespondentDetails.fulfilled]: (state, action) => {
      const { payload } = action;
      const { respondent } = state;

      respondent.id = payload.respondent_id;
      respondent.profile_picture = payload.consumer_image;
      respondent.name = payload.consumer_name;
      respondent.email = payload.consumer_email;
      respondent.dateofbirth = payload.consumer_dateofbirth;
      respondent.age = payload.consumer_age;
      respondent.gender = payload.consumer_gender;
      respondent.product.serial_number = payload.product__serialnumber;
      respondent.product.name = payload.product_name;
      respondent.product.image = payload.product_image;
      respondent.submit_date = payload.submit_date;
      respondent.qna = payload.questions_answers;
      respondent.location_address = payload.location_address;
      respondent.longitude = payload.longitude;
      respondent.latitude = payload.latitude;
      respondent.scan_device = payload.scan_device;
      respondent.browser = payload.browser;
    },
    [fetchRespondentQuestionListing.fulfilled]: (state, action) => {
      const { payload } = action;
      const tempArray = [];

      payload.data.questions_answers.forEach((question) => {
        tempArray.push(question);
      });

      state.selectedQuestion.uuid = tempArray[0].id;
      state.selectedQuestion.question = tempArray[0].title;
      state.selectedQuestion.type = tempArray[0].type;
      state.selectedQuestion.choices = tempArray[0].choices;
      state.selectedQuestion.isV2 = tempArray[0].is_v2 ?? false;
      state.selectedQuestion.index = 1;

      state.questionList = tempArray;
    },
    [fetchRespondentAnswerListing.fulfilled]: (state, action) => {
      const { meta, payload } = action;

      const tempArray = [];

      state.answerList.recordTotal = payload.recordsTotal;
      state.answerList.recordsFiltered = payload.recordsFiltered;
      state.answerList.recordsAnswer = payload.recordsAnswer;
      state.answerList.recordsSkip = payload.recordsSkip;

      payload.data.forEach((answer) => {
        tempArray.push({
          choices: answer.choices,
          createdAt: answer.created_at,
          email: answer.email,
          image: answer.image,
          name: answer.name,
          productName: answer.product_name,
          selectedAnswer: answer.selected_answer,
          serialNumber: answer.serial_number,
        });
      });

      if (meta.arg.start === 0) {
        state.answerList.data = tempArray;
      } else {
        let data = state.answerList.data;
        state.answerList.data = data.concat(tempArray);
      }
    },
    [fetchTotalResponse.fulfilled]: (state, action) => {
      const { payload } = action;
      const totalResponse = state.surveyAnalytic.totalResponse;

      let labelArray = [];
      let valueArray = [];

      payload.data.forEach((response) => {
        labelArray.push(response.survey_date);
        valueArray.push(response.survey_total_count);
      });

      totalResponse.surveyDate = labelArray;
      totalResponse.surveyTotalCount = valueArray;
    },
    [fetchTotalVisit.fulfilled]: (state, action) => {
      const { payload } = action;
      const { totalVisit } = state.surveyAnalytic;

      totalVisit.value = payload.data.totalVisit;
      totalVisit.percentage = payload.data.percentage;
      totalVisit.type = payload.data.type;
    },
    [fetchSurveyResponseCount.fulfilled]: (state, action) => {
      const { payload } = action;
      const { response } = state.surveyAnalytic;

      response.value = payload.data.totalResponse;
      response.percentage = payload.data.percentage;
      response.type = payload.data.type;
    },
    [fetchSurveyByAge.fulfilled]: (state, action) => {
      const { payload } = action;

      let tempArray = [];

      payload.data.forEach((response) => {
        tempArray.push({
          label: response.range,
          value: response.scan_count,
        });
      });

      state.surveyAnalytic.ageCount = tempArray;
    },
    [fetchSurveyByProduct.fulfilled]: (state, action) => {
      const { payload } = action;
      const productCount = state.surveyAnalytic.product;

      let labelArray = [];
      let valueArray = [];

      payload.data.forEach((response) => {
        labelArray.push(response.product_name);
        valueArray.push(response.survey_count);
      });

      productCount.label = labelArray;
      productCount.data = valueArray;
    },
    [fetchSurveyByGender.fulfilled]: (state, action) => {
      const { payload } = action;
      const genderCount = state.surveyAnalytic.gender;

      let labelArray = [];
      let valueArray = [];

      payload.data.forEach((response) => {
        labelArray.push(response.gender);
        valueArray.push(response.survey_count);
      });

      genderCount.label = labelArray;
      genderCount.data = valueArray;
    },
    [fetchSurveyByDevice.fulfilled]: (state, action) => {
      const { payload } = action;

      let tempArray = [];
      let count = 0;

      payload.data.forEach((response) => {
        tempArray.push(response);
        count = response.survey_count;
      });

      state.surveyAnalytic.device = tempArray;
      state.surveyAnalytic.totalDevice = count;
    },
    [fetchSurveyGeoCount.fulfilled]: (state, action) => {
      const { payload } = action;

      let countryArray = [];
      payload.data.forEach((response) => {
        let stateArray = [];
        response.state.forEach((row) => {
          stateArray.push({
            name: row.name,
            scanCount: row.scan_count,
          });
        });

        countryArray.push({
          country: response.country,
          scanCount: response.scan_count,
          state: stateArray,
        });
      });

      state.surveyAnalytic.geo = countryArray;
    },
    [fetchResponseByChoiceCount.fulfilled]: (state, action) => {
      const { payload } = action;
      const choicesCount = state.surveyAnalytic.choicesCount;

      let labelArray = [];
      let valueArray = [];

      if (state.selectedQuestion.type === surveyQuestionType.multiStarRating) {
        choicesCount.countResult = payload.data;
      } else {
        payload.data.forEach((response) => {
          labelArray.push(response.label);
          valueArray.push(response.quantity);
        });

        choicesCount.countResult = payload.data;
        choicesCount.label = labelArray;
        choicesCount.data = valueArray;
      }
    },
    [getAvailableSurveys.pending]: (state) => {
      state.availableSurveys.isError = false;
    },
    [getAvailableSurveys.fulfilled]: (state, action) => {
      const { payload } = action;
      if (state.availableSurveys.hasNextPage) {
        state.availableSurveys.list = [
          ...state.availableSurveys.list,
          ...payload.data,
        ];
        state.availableSurveys.currentTotal += payload.data.length;
      }
      state.availableSurveys.totalRecords = payload.recordsTotal;
      state.availableSurveys.totalFiltered = payload.recordsFiltered;
      state.availableSurveys.hasNextPage =
        state.availableSurveys.list.length <
        state.availableSurveys.totalFiltered;
    },
    [getAvailableSurveys.rejected]: (state) => {
      state.availableSurveys.isError = true;
    },
  },
});

export const {
  setSelectedQuestion,
  setDeleteSurveyQuestion,
  setAddSurveyQuestion,
  resetRespondentState,
  resetAvailableSurveys,
  resetAnswerListing,
} = surveySlice.actions;

export const surveyReducer = surveySlice.reducer;
