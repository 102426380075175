import React from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Box,
  Button,
  Typography
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { StyledTableCell, StyledTableRow, StickyRightTableCell, StickyLeftTableCell } from "components/table";
import { SkeletonTableRow } from "components/skeleton/skeleton.component";
import { ActionButton } from "components/button";
import { TablePaginationActions } from "components/tablePagination";
import { ACTIVE, PENDING, SUSPEND } from "modules/employee/constant";
import clsx from "clsx";
import RefreshTable from "components/table/refreshTable";
import { getLang } from "app/feature/constants";
import moment from "moment";
import "moment/min/locales.min";
import BranchIcon from "../../../../../components/icon/branchIcon";

const style = (theme) => ({
  table: {
    tableLayout: "auto",
    whiteSpace: "nowrap",
    borderCollapse: "separate",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      minWidth: 0,
    },
    "& th,td": {
      fontSize: ".75rem",
      borderRight: "1px solid rgba(58, 77, 84, 0.1)",
      padding: theme.spacing(1, 2, 1, 2),
    },
    "& th": {
      color: theme.palette.text.secondary,
      fontWeight: theme.typography.fontWeightBold,
    },
    "& th:last-child, td:last-child": {
      width: 37,
      borderRight: "0px",
    },
    "& th th": {
      borderBottom: "none !important",
    },
    "& td td": {
      borderBottom: "none !important",
    },
  },
  tableContainer: {
    maxHeight: 500,
    overflowY: "auto"
  },
  noEmployeeContainer: {
    backgroundColor: theme.palette.background.paper,
    textAlign: "center"
  },
  button: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    minWidth: 0,
    marginLeft: theme.spacing(1)
  },
  pendingColor: {
    color: "#FFBF00"
  },
  activeColor: {
    color: "#4BCB0F"
  },
  suspendColor: {
    color: "#FF1D1D"
  }
});

const useStyles = makeStyles(style);

export default function EmployeeTableComponent({
  currentUserUuid,
  hasEmployeeAddAccessRight,
  hasEmployeeEditAccessRight,
  hasEmployeeActivateOrDeactivateAccessRight,
  hasEmployeeInvitationCancelAccessRight,
  rows,
  language,
  isFetching,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleCopyInviteUrl,
  totalRecords,
  handleCreate,
  handleViewProfile,
  search,
  handleEdit,
  handleResendEmail,
  handleDeleteEmployee,
  handleStatusChange,
  isLoadingEmployeeError,
  handleReload,
  paginationOptions,
  lang
}) {
  const classes = useStyles();
  const filter = !search.name && !search.branch && !search.userGroupId ? false : true;
  
  if(language==="ZH")
  {
    moment.locale('zh-cn')
  }else if(language==="TL")
  {
    moment.locale('tl-ph')
  }else
  {
    moment.locale(language)
  }


  return (
    <>
      {rows.length > 0 || isFetching ? (
        <TableContainer>
          <Table
            className={classes.table}
            aria-label="product table"
            stickyHeader
          >
            <TableHead>
              <TableRow>
                <StickyLeftTableCell>
                  <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                </StickyLeftTableCell>
                <StyledTableCell>{getLang(lang, "label.NAME")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.EMAIL")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.BRANCH")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.ROLE")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.DATE_JOINED")}</StyledTableCell>
                <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                <StickyRightTableCell>
                  <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
                </StickyRightTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching
                ? [...new Array(5)].map((v, index) => (
                  <SkeletonTableRow key={index} columnCount={8} />
                ))
                : rows.map((row, index) => {
                  const actionItems = [];
                  if (
                    hasEmployeeEditAccessRight &&
                    currentUserUuid !== row.employeeId
                  ) {
                    actionItems.push({
                      label: getLang(lang, "label.EDIT_DETAIL"),
                      action: () => handleEdit(row.employeeId),
                      disabled: row.id === currentUserUuid,
                    });
                  }
                  if (row.state === PENDING) {
                    actionItems.push({
                      label: getLang(lang, "label.RESEND_EMAIL"),
                      action: () => handleResendEmail(row.employeeId),
                    });
                    actionItems.push({
                      label: getLang(lang, "label.COPY_LINK"),
                      action: () => handleCopyInviteUrl(row.inviteUrl),
                    });
                    if (hasEmployeeInvitationCancelAccessRight) {
                      actionItems.push({
                        label: getLang(lang, "label.CANCEL_INVITE"),
                        action: () => handleDeleteEmployee(row.employeeId),
                        errorText: true,
                      });
                    }
                  } else {
                    actionItems.push({
                      label: getLang(lang, "label.VIEW_PROFILE"),
                      action: () => handleViewProfile(row.employeeId),
                    });
                    if (
                      hasEmployeeActivateOrDeactivateAccessRight &&
                      currentUserUuid !== row.employeeId
                    ) {
                      if (row.state === SUSPEND) {
                        actionItems.push({
                          label: getLang(lang, "label.REACTIVATE"),
                          action: () =>
                            handleStatusChange({
                              currentStatus: row.state,
                              id: row.employeeId,
                              employeeName: row.employeeName
                            }),
                        });
                      } else {
                        actionItems.push({
                          label: getLang(lang, "label.DEACTIVATE"),
                          action: () =>
                            handleStatusChange({
                              currentStatus: row.state,
                              id: row.employeeId,
                              employeeName: row.employeeName
                            }),
                          errorText: true,
                        });
                      }
                    }
                  }

                  return (
                    <StyledTableRow key={index} hover tabIndex={-1}>
                      <StickyLeftTableCell>
                        <StyledTableCell component="th" scope="row" style={{ borderBottom: "0px" }}>
                          {index + 1 + rowsPerPage * page}
                        </StyledTableCell>
                      </StickyLeftTableCell>
                      <StyledTableCell>
                        {row.employeeName}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.employeeEmail}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Box display="flex" alignItems="center">
                          <Box>
                            <BranchIcon url={row.nodeImageUrl[0]}/>
                          </Box>
                          <Box p={1}>
                            {row.nodeName.length > 1
                              ? row.nodeName[0] +
                              " + " +
                              (row.nodeName.length - 1).toFixed(0)
                              : row.nodeName[0]}
                          </Box>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>{row.userGroup}</StyledTableCell>
                      <StyledTableCell>
                        {row.state === "pending" ? (
                          <Typography variant="caption">-</Typography>
                        ) : (
                          moment(row.joinedAt).format('ll')
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography
                          variant="caption"
                          className={clsx({
                            [classes.pendingColor]: row.state === PENDING,
                            [classes.activeColor]: row.state === ACTIVE,
                            [classes.suspendColor]: row.state === SUSPEND,
                          })}
                        >
                          {row.state === PENDING
                            ? getLang(lang, "label.PENDING")
                            : row.state === ACTIVE
                              ? getLang(lang, "label.ACTIVE")
                              : getLang(lang, "label.DEACTIVATED")}
                          {/* QUICK FIX FOR LANGUAGES TRANSLATION */}
                        </Typography>
                      </StyledTableCell>
                      <StickyRightTableCell>
                        <StyledTableCell>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <ActionButton actionItems={actionItems} popperStyle={{ zIndex: 999 }} />
                          </div>
                        </StyledTableCell>
                      </StickyRightTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        !filter &&
        !isLoadingEmployeeError && (
          <Box>
            <Table
              className={classes.table}
              aria-label="product table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell>{getLang(lang, "label.NUM")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.NAME")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.BRANCH")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.ROLE")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.DATE_JOINED")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.STATUS")}</StyledTableCell>
                  <StyledTableCell>{getLang(lang, "label.ACTION")}</StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Box py={9} className={classes.noEmployeeContainer}>


              <Typography variant="body1">
                {getLang(lang, "paragraph.NO_EMPLOYEE")}
              </Typography>
              {hasEmployeeAddAccessRight ? (
                <Box mt={2}>
                  <Button
                    onClick={handleCreate}
                    variant="contained"
                    disableElevation
                    color="primary"
                  >
                    {getLang(lang, "label.ADD_EMPLOYEE")}
                  </Button>
                </Box>
              ) : null}
            </Box></Box>

        )
      )}

      {!isFetching && rows.length === 0 && filter && (
        <Alert severity="info">{getLang(lang, "message.info.NO_EMPLOYEE")}</Alert>
      )}

      {!isFetching && isLoadingEmployeeError && (
        <RefreshTable handleReload={handleReload} />
      )}

      {!isFetching && rows.length > 0 && (
        <TablePagination
          style={{
            backgroundColor: "#fff",
            boxShadow: "0px -1px 0px rgba(58, 77, 84, 0.15)",
          }}
          ActionsComponent={TablePaginationActions}
          rowsPerPageOptions={paginationOptions}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={getLang(lang, 'label.ROWS_PER_PAGE')}
          labelDisplayedRows={({ from, to, count }) => `${from.toLocaleString("en-US")}-${to.toLocaleString("en-US")} ${getLang(lang, 'label.OF')} ${count.toLocaleString("en-US")}`}
        />
      )}
    </>
  );
}
