import React from "react";
import {
  Box,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import clsx from "clsx";
import { getLang } from "app/feature/constants";
import {
  HelpOutlined as HelpOutlinedIcon,
  ArrowForwardIosRounded as ArrowForwardIosRoundedIcon,
} from "@material-ui/icons";
import { useHistory } from "react-router";
import moment from "moment";
import ConsumerScanSummaryIcon from "assets/img/consumerScanSummary.png";
import SuspendedSummaryIcon from "assets/img/suspendedSummary.png";
import CounterfeitSummaryIcon from "assets/img/counterfeitSummary.png";
import ConsumerIcon from "assets/img/newConsumerSummary.png";
import "moment/min/locales.min";
import { ROOT as REPORTING_ROOT } from "modules/reporting";
import { ROOT as COUNTERFEIT_ROOT } from "modules/counterfeit-report";
import { trendingSummaryCardType } from "modules/dashboard/constants";
import FirstScanSummaryIcon from "assets/img/firstScanSummary.png";
import ScanIcon from "assets/img/scanIcon.png";

const useStyles = makeStyles((_) => ({
  card: {
    borderRadius: 12,
    background: "white",
    border: "1px solid #D0D5DD",
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  icon: {
    height: 40,
    width: 40,
    objectFit: "contain",
    marginRight: 5,
  },
  infoLabel: {
    color: "#32D583",
  },
  warningLabel: {
    color: "#F97066",
  },
}));

export default function TcgTrendingSummaryCardComponent({
  data,
  language,
  loading,
  lang,
  startDate,
  endDate,
  type,
}) {
  const classes = useStyles();
  const history = useHistory();

  if (language === "ZH") {
    moment.locale("zh-cn");
  } else if (language === "TL") {
    moment.locale("tl-ph");
  } else {
    moment.locale(language);
  }

  const summaryList = {
    [trendingSummaryCardType.consumerScanned]: {
      title: (
        <div style={{ display: "flex", alignItems: "center" }}>
          {getLang(lang, "label.CONSUMER_SCANNED")}
          <Tooltip
            title={getLang(lang, "tooltips.TOTAL_SCANNED_TOOLTIP")}
            arrow
          >
            <HelpOutlinedIcon
              color="primary"
              fontSize="inherit"
              style={{ marginLeft: 6, fontsize: 12 }}
            />
          </Tooltip>
        </div>
      ),
      icon: ConsumerScanSummaryIcon,
      positive: true,
      url: `${REPORTING_ROOT}?page=qr&period=daily&start=${startDate}&end=${endDate}`,
      showDate: true,
      align: "space-between",
    },
    [trendingSummaryCardType.suspendSn]: {
      title: getLang(lang, "label.SUSPENDED_SERIAL_NUMBER"),
      icon: SuspendedSummaryIcon,
      positive: false,
      url: `${REPORTING_ROOT}?page=qr&period=daily&start=${startDate}&end=${endDate}`,
      showDate: true,
      align: "space-between",
    },
    [trendingSummaryCardType.counterfeitReport]: {
      title: getLang(lang, "label.COUNTERFEIT_REPORT_RECEIVED"),
      icon: CounterfeitSummaryIcon,
      positive: false,
      url: `${COUNTERFEIT_ROOT}?start=${startDate}&end=${endDate}`,
      showDate: true,
      align: "space-between",
    },
    [trendingSummaryCardType.totalRegistration]: {
      title: getLang(lang, "label.TOTAL_REGISTRATION"),
      icon: ConsumerIcon,
      positive: false,
      url: null,
      showDate: false,
      align: "center",
    },
    [trendingSummaryCardType.cumulativeRegistration]: {
      title: getLang(lang, "label.CUMULATIVE_REGISTRATION"),
      icon: ConsumerIcon,
      positive: false,
      url: null,
      showDate: false,
      align: "center",
    },
    [trendingSummaryCardType.totalScannedSolutionHighlight]: {
      title: getLang(lang, "label.TOTAL_SCANNED_SOLUTION_HIGHLIGHT"),
      icon: FirstScanSummaryIcon,
      positive: false,
      url: null,
      showDate: false,
      align: "center",
    },
    [trendingSummaryCardType.totalMockupScan]: {
      title: getLang(lang, "label.TOTAL_MOCKUP_SCAN"),
      icon: ScanIcon,
      positive: false,
      url: null,
      showDate: false,
      align: "center",
    },
    [trendingSummaryCardType.counterfeitReportReceived]: {
      title: getLang(lang, "label.COUNTERFEIT_REPORT_RECEIVED"),
      icon: CounterfeitSummaryIcon,
      positive: false,
      url: null,
      showDate: false,
      align: "center",
    },
  };

  let formattedStartDate = ""; // Declare variables outside the if block
  let formattedEndDate = "";
  if (data && data.trendingComparedDate) {
    const [startDateStr, endDateStr] = data.trendingComparedDate.split(" - ");
    formattedStartDate = moment(startDateStr).format("ll");
    formattedEndDate = endDateStr ? moment(endDateStr).format("ll") : "";
  }

  return (
    summaryList[type] && (
      <Box
        className={classes.card}
        style={{ justifyContent: summaryList[type].align, flex: 1 }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" mr={1}>
            <img
              src={summaryList[type].icon}
              alt="icon"
              className={classes.icon}
            />
            <Typography variant="body1">{summaryList[type].title}</Typography>
          </Box>
          {!!summaryList[type].url && (
            <IconButton
              style={{ padding: 8 }}
              onClick={() => {
                history.push(summaryList[type].url);
              }}
            >
              <ArrowForwardIosRoundedIcon style={{ fontSize: 20 }} />
            </IconButton>
          )}
        </Box>
        <Box mt={0.5} mb={1} ml={0.5} display="flex" alignItems="flex-end">
          {loading ? (
            <Skeleton
              variant="text"
              animation="wave"
              width="100%"
              height={30}
            />
          ) : (
            <>
              <Typography variant="h5" style={{ marginRight: 4 }}>
                <b>{data?.totalCount?.toLocaleString("en-US") || 0}</b>
              </Typography>
              {!!data?.realTrendingPercentage && (
                <Typography
                  variant="caption"
                  className={clsx({
                    [classes.infoLabel]:
                      (data.realTrendingPercentage > 0 &&
                        summaryList[type].positive) ||
                      (data.realTrendingPercentage < 0 &&
                        !summaryList[type].positive),
                    [classes.warningLabel]:
                      (data.realTrendingPercentage > 0 &&
                        !summaryList[type].positive) ||
                      (data.realTrendingPercentage < 0 &&
                        summaryList[type].positive),
                  })}
                  style={{ marginBottom: 2 }}
                >
                  <b>({data.displayTrendingPercentage || 0})</b>
                </Typography>
              )}
            </>
          )}
        </Box>
        {summaryList[type].showDate && (
          <Box ml={0.5}>
            <Typography style={{ color: "#7C8DB5" }} variant="body2">
              {getLang(lang, "label.COMPARE_WITH")}{" "}
              {formattedStartDate +
                (formattedEndDate ? ` - ${formattedEndDate}` : "")}
            </Typography>
          </Box>
        )}
      </Box>
    )
  );
}
