import { createSlice } from "@reduxjs/toolkit";
import { SETTING } from "../../constants";

const initialState = {
  counterfeitReportEmail: "",
  contactNumber: "",
  contactCode: "",
  facebookUrl: "",
  whatsappUrl: "",
  youtubeUrl: "",
  websiteUrl: "",
  enableCounterfeitReport: true,
  enableConsumerLogin: true,
  isLoginRequired: true,
  enableLocationTracking: true,
  enableContactNumber: true,
  enableContactEmail: true,
  enableFacebook: true,
  enableWhatsapp: true,
  enableYoutube: true,
  enableWebsite: false,
  contactEmail: "",
  enableScanCookieSkipCount: true,
  scanCookieRetainHour: 24,
  enableCustomSuspendMessage: false,
  customSuspendMessageValues: {

  },
  defaultDisplayingCompanyProfile: 1,
  uiSetting: {
    bgType: "Solid color",
    bgImage: "",
    bannerImage: "",
    bgColor: "#3A4D54",
    colorPrimary: "#3A4D54",
    colorSecondary: "#6AAF68",
    bgImageError: {
      value: false,
      message: ""
    }
  },
  // will modify when api done
  theme: "",
  customColor: {
    primary: "#3C4D54",
    secondary: "#6AAF68",
  },
  enablePrivacyPolicy: true,
  privacyPolicyTitle: "",
  privacyPolicyMessage: "",
  privacyPolicyUrl: "",
  disagreeCheck: "1",
  disagreeUrl: ""
};

const settingSlice = createSlice({
  name: SETTING,
  initialState,
  reducers: {
    setAppSetting(state, action) {
    },

    setAppearance(state, action) {
      const { payload } = action;

      // state.theme = payload.theme;
      state.customColor = payload.color;
    },

    resetSetting(state) {
      state.uiSetting = initialState.uiSetting;
    },

    setConsumerPortalSetting(state, action) {
      const { payload } = action;

      state.counterfeitReportEmail = payload.counterfeit_report_email;
      state.contactNumber = payload.contact_number;
      state.contactCode = payload.contact_code;
      state.enableCounterfeitReport = Boolean(parseInt(payload.enable_counterfeit_report));
      state.enableConsumerLogin = Boolean(parseInt(payload.enable_consumer_login));
      state.isLoginRequired = Boolean(parseInt(payload.enable_login_required));
      state.enableLocationTracking = Boolean(parseInt(payload.enable_location_tracking));
      state.enableContactNumber = Boolean(parseInt(payload.enable_contact_number));
      state.enableContactEmail = Boolean(parseInt(payload.enable_contact_email));
      state.contactEmail = payload.contact_email;
      state.defaultLanguage = payload.default_language ?? "EN";
      state.facebookUrl = payload.facebook_url;
      state.whatsappUrl = payload.whatsapp_url;
      state.youtubeUrl = payload.youtube_url;
      state.websiteUrl = payload.website_url;
      state.enableFacebook = Boolean(parseInt(payload.enable_facebook));
      state.enableWhatsapp = Boolean(parseInt(payload.enable_whatsapp));
      state.enableYoutube = Boolean(parseInt(payload.enable_youtube));
      state.enableWebsite = Boolean(parseInt(payload.enable_website_url));
      state.enablePrivacyPolicy = Boolean(parseInt(payload.enable_privacy_policy));
      state.privacyPolicyTitle = payload.privacy_policy_title;
      state.privacyPolicyMessage = payload.privacy_policy_msg;
      state.privacyPolicyUrl = payload.privacy_policy_url;
      state.disagreeCheck = payload.disagree_check;
      state.disagreeUrl = payload.disagree_url;
      state.enableScanCookieSkipCount = {'0': false, '1': true}[payload.enable_scan_cookie_skip_count] ?? null;
      state.scanCookieRetainHour = payload.scan_cookie_retain_hour;
      state.enableCustomSuspendMessage = {'0': false, '1': true}[payload.enable_custom_suspend_message] ?? false;
      state.customSuspendMessageValues = payload.custom_suspend_message_values ? JSON.parse(payload.custom_suspend_message_values) : null;
      state.defaultDisplayingCompanyProfile = payload.displaying_profile_settings ? parseInt(payload.displaying_profile_settings) : null;

      if (payload.themes) {
        const uiSettingPayload = JSON.parse(payload.themes);

        state.uiSetting.bgType = uiSettingPayload.bgType;
        state.uiSetting.bgImage = uiSettingPayload.bgImage;
        state.uiSetting.bannerImage = uiSettingPayload.bannerImage ? uiSettingPayload.bannerImage : "";
        state.uiSetting.bgColor = uiSettingPayload.bgColor;
        state.uiSetting.colorPrimary = uiSettingPayload.colorPrimary;
        state.uiSetting.colorSecondary = uiSettingPayload.colorSecondary;
      }

    },

    setThemeSetting(state, action) {
      const { payload } = action

      switch (payload.name) {
        case "bgType":
          state.uiSetting.bgType = payload.value;
          break;
        case "bgColor":
          state.uiSetting.bgColor = payload.value;
          break;
        case "bgImage":
          state.uiSetting.bgImage = payload.value;
          break;
        case "colorPrimary":
          state.uiSetting.colorPrimary = payload.value;
          break;
        case "colorSecondary":
          state.uiSetting.colorSecondary = payload.value;
          break;
        case "bannerImage":
          state.uiSetting.bannerImage = payload.value;
          break;
        default:
          break;
      }

    },

    setBgImageError(state, action) {
      const { payload } = action

      state.uiSetting.bgImageError.value = payload.value;
      state.uiSetting.bgImageError.message = payload.message;
    },
    setPrivacyPolicyTNC(state, action) {
      const { payload } = action;
      state.privacyPolicyMessage = payload;
    },
  },
  extraReducers: {},
});

export const {
  setAppSetting,
  setConsumerPortalSetting,
  setAppearance,
  setSetting,
  resetSetting,
  setThemeSetting,
  setBgImageError,
  setPrivacyPolicyTNC
} = settingSlice.actions;
export const settingReducer = settingSlice.reducer;
