import axios from "axios";

const endpoint_url = "/account/api/v1/company-setting";

const put = ({
  counterfeitReportEmail,
  contactNumber,
  contactCode,
  defaultLanguage,
  facebookUrl,
  whatsappUrl,
  youtubeUrl,
  websiteUrl,
  enableCounterfeitReport,
  enableConsumerLogin,
  isLoginRequired,
  enableLocationTracking,
  enableContactNumber,
  enableContactEmail,
  enableFacebook,
  enableWhatsapp,
  enableYoutube,
  enableWebsite,
  contactEmail,
  uiSetting,
  enablePrivacyPolicy,
  privacyPolicyTitle,
  privacyPolicyMessage,
  privacyPolicyUrl,
  disagreeCheck,
  disagreeUrl,
  isPrivacyPolicyMessageModified,
  enableScanCookieSkipCount,
  scanCookieRetainHour,
  enableCustomSuspendMessage,
  customSuspendMessageValues,
  defaultDisplayingCompanyProfile,
}) => {
  const body = {
    counterfeit_report_email: counterfeitReportEmail,
    contact_number: contactNumber,
    contact_code: contactCode,
    contact_email: contactEmail,
    default_language: defaultLanguage,
    facebook_url: facebookUrl,
    whatsapp_url: whatsappUrl,
    youtube_url: youtubeUrl,
    website_url: websiteUrl,
    enable_counterfeit_report: enableCounterfeitReport,
    enable_consumer_login: enableConsumerLogin,
    enable_login_required: isLoginRequired,
    enable_location_tracking: enableLocationTracking,
    enable_contact_number: enableContactNumber,
    enable_contact_email: enableContactEmail,
    enable_facebook: enableFacebook,
    enable_whatsapp: enableWhatsapp,
    enable_youtube: enableYoutube,
    enable_website_url: enableWebsite,
    themes: uiSetting,
    enable_privacy_policy: enablePrivacyPolicy,
    privacy_policy_title: privacyPolicyTitle,
    privacy_policy_msg: privacyPolicyMessage,
    privacy_policy_url: privacyPolicyUrl,
    disagree_check: disagreeCheck,
    disagree_url: disagreeUrl,
    isPrivacyPolicyMessageModified: isPrivacyPolicyMessageModified,
    enable_scan_cookie_skip_count: enableScanCookieSkipCount,
    scan_cookie_retain_hour: scanCookieRetainHour,
    enable_custom_suspend_message: enableCustomSuspendMessage,
    custom_suspend_message_values: customSuspendMessageValues,
    displaying_profile_settings: defaultDisplayingCompanyProfile,
  };

  return new Promise((resolve, reject) => {
    axios
      .put(endpoint_url, body)
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

const get = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(endpoint_url)
      .then(response => resolve(response.data))
      .catch(error => {
        return reject(error.response.data);
      });
  });
};

const companySetting = {
  put,
  get
};

export default companySetting;
