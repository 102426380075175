export const FolderOwner = {
  account: "account",
  company: "company",
  consumer: "consumer",
  product: "product",
  productImage: "productImage",
  productPageComponent: "productPageComponent",
  warrantyConsumer: "warrantyConsumer",
  warranty: "warranty",
  warrantyReimbursement: "warrantyReimbursement",
  luckyDraw: "luckyDraw",
  instantLuckyDraw: "instantLuckyDraw",
  redemptionCenter: "redemptionCenter",
  stockflowReward: "stockflowReward",
  branch: 'branch',
  messageBlasting: 'message_blasting',
};

export const FolderType = {
  profilePicture: "profile_picture",
  warrantyPicture: "warrantyPicture",
  logo: "logo",
  privacyPolicy: "privacy_policy",
  endUserAgreement: "end_user_agreement",
  articleAssociation: "article_association",
  directorIdentification: "director_identification",
  backgroundImageSetting: "background_image_setting",
  consumerBanner: "banner_image_setting",
  termAndCondition: "term_and_condition",
  gameRules: "game_rules",
  luckyDrawImage: "lucky_draw_image",
  luckyDrawprizeImage: "lucky_draw_prize_image",
  instantLuckyDrawImage: "instant_lucky_draw_image",
  instantprizeImage: "instant_prize_image",
  giftImage: "gift_image",
  stockflowRewardImage: "stockflow_reward_image",
  warrantyReimbursement: "warranty_reimbursement_media",
  branchProfile: "branch_profile",
  brandImage: "brand_image",
  productUiTemplate: 'product_ui_template',
  messageBlasting: 'message_blasting',
};

export const UploadType = {
  userProfilePrivacyPolicySignature: "userProfilePrivacyPolicySignature",
  userProfileEndUserAgreementSignature: "userProfileEndUserAgreementSignature",
  companyArticleAssociation: "companyArticleAssociation",
  companyDirectorIdentification: "companyDirectorIdentification",
  companyLogo: "companyLogo",
  productPageUi: "productPageUi",
  productPageUiTemplate: "productPageUiTemplate",
  productImage: "productImage",
  productPageComponent: "productPageComponent",
  warrantyTermAndCondition: "warrantyTermAndCondition",
  luckyDrawTermAndCondition: "luckyDrawTermAndCondition",
  luckyDrawRules: "luckyDrawRules",
  instantLuckyDrawTermAndCondition: "instantLuckyDrawTermAndCondition",
  giftCodeExcel: "giftCodeExcel",
  stockflowRewardTermAndCondition: "stockflowRewardTermAndCondition",
  privacyPolicyTermAndCondition: "privacyPolicyTermAndCondition",
  messageBlasting: 'message_blasting',
};

export const allowedFileType = [
  "application/msword",
  "application/pdf",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.oasis.opendocument.spreadsheet",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/xml",
  "audio/aac",
  "audio/midi",
  "audio/mpeg",
  "audio/ogg",
  "audio/wav",
  "audio/x-midi",
  "image/gif",
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/tiff",
  "text/csv",
  "text/plain",
  "video/mp4",
  "video/mpeg",
  "video/ogg",
  "video/x-msvideo",
]
