import React from "react";
import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { getLang } from "app/feature/constants";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((_) => ({
  panel: {
    borderRadius: 12,
    background: "white",
    border: "1px solid #D0D5DD",
    padding: 16,
    paddingRight: 0,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  listContainer: {
    height: 210,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "0.3em",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "rgba(255,255,255,1)",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 4,
      backgroundColor: "rgba(0, 0, 0, .5)",
    },
  },
}));

export default function PackagingDesignObjectivePanelComponent({
  rankList,
  isLoading,
  lang,
  isError,
  handleReload,
  language,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.panel}>
      <Box display="flex" justifyContent="space-between" pr={2} mb={1.5}>
        <Typography vairiant="body1">
          <b>{getLang(lang, "label.MAIN_OBJECTIVE_FOR_PACKAGING_DESIGN")}</b>
        </Typography>
        <Typography vairiant="body1">
          <b>{getLang(lang, "label.TOTAL_CHOSEN")}</b>
        </Typography>
      </Box>
      <Box className={classes.listContainer} pr={2}>
        {isLoading ? (
          <>
            <Skeleton variant="text" animation="wave" height={35} />
            <Skeleton variant="text" animation="wave" height={35} />
            <Skeleton variant="text" animation="wave" height={35} />
            <Skeleton variant="text" animation="wave" height={35} />
          </>
        ) : isError ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            height="100%"
          >
            <Typography
              variant="body2"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              {getLang(lang, "label.NO_DATA_RECEIVED")}
            </Typography>
            <Typography
              variant="body2"
              style={{ color: "#9CA6A9", paddingBottom: "16px" }}
            >
              {getLang(lang, "label.SOMETHING_WENT_WRONG")}
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleReload}
              disableElevation
            >
              {getLang(lang, "label.REFRESH")}
            </Button>
          </Box>
        ) : rankList.length ? (
          rankList.map((item, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={index ? 1.5 : 0}
              style={{ gap: 16 }}
            >
              <Box display="flex" alignItems="center">
                <Typography vairiant="body2">{(language === "TH" ? item.name[1] : item.name[0]) || "-"}</Typography>
              </Box>
              <Typography vairiant="body2">
                {item.scan_count}
              </Typography>
            </Box>
          ))
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            height="100%"
          >
            <Typography
              variant="body2"
              color="primary"
              style={{ fontWeight: "bold" }}
            >
              {getLang(lang, "label.NO_DATA_AVAILABLE")}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
