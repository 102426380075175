import accountApi from "app/api/account";
import { COLOR_PRIMARY, COLOR_SECONDARY, ACCOUNT } from "../../constants";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getConsumerPageSetting = createAsyncThunk(
  `${ACCOUNT}/getConsumerPageSetting`,
  async (payload, thunkApi) => {
    return accountApi.companySetting.get()
      .then(response => response.data)
      .catch(error => thunkApi.rejectWithValue(error));
  }
);

export const updateAppearance = createAsyncThunk(
  `${ACCOUNT}/updateAppearance`,
  async (payload, thunkApi) => {
    const {
      setting: { timeZone_Description, timeZone_Adjustment }
    } = thunkApi.getState();

    const { theme, color, customize } = payload;

    return accountApi.updateAccountSetting({
      timeZone_Description,
      timeZone_Adjustment: timeZone_Adjustment.toString(),
      theme,
      customization: customize
        ? `${color.primary},${color.secondary}`
        : `${COLOR_PRIMARY},${COLOR_SECONDARY}`
    }).then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));

  }
);

export const fetchJSONURL = createAsyncThunk(
  `${ACCOUNT}/fetchJSONURL`,
  async (payload, thunkApi) => {
    return fetch(payload)
      .then((res) => res.json())
      .then((out) => {
        return out;
      })
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);
export const updateConsumerPageSetting = createAsyncThunk(
  `${ACCOUNT}/updateConsumerPageSetting`,
  async (payload, thunkApi) => {
    let {
      counterfeitReportEmail,
      contactNumber,
      contactCode,
      contactEmail,
      defaultLanguage,
      facebookUrl,
      whatsappUrl,
      youtubeUrl,
      websiteUrl,
      enableCounterfeitReport,
      enableConsumerLogin,
      isLoginRequired,
      enableLocationTracking,
      enableContactNumber,
      enableContactEmail,
      enableFacebook,
      enableWhatsapp,
      enableYoutube,
      enableWebsite,
      uiSetting,
      enablePrivacyPolicy,
      privacyPolicyTitle,
      privacyPolicyMessage,
      privacyPolicyUrl,
      disagreeCheck,
      disagreeUrl,
      isPrivacyPolicyMessageModified,
      enableScanCookieSkipCount,
      scanCookieRetainHour,
      enableCustomSuspendMessage,
      customSuspendMessageValues,
      defaultDisplayingCompanyProfile,
    } = payload;
    contactCode = contactCode ? contactCode.phone : "";
    enableConsumerLogin = enableConsumerLogin ? "1" : "0";
    enableCounterfeitReport = enableCounterfeitReport ? "1" : "0";
    isLoginRequired = isLoginRequired ? "1" : "0";
    enableLocationTracking = enableLocationTracking ? "1" : "0";
    enableContactNumber = enableContactNumber ? "1" : "0";
    enableContactEmail = enableContactEmail ? "1" : "0";
    enableFacebook = enableFacebook ? "1" : "0";
    enableYoutube = enableYoutube ? "1" : "0";
    enableWhatsapp = enableWhatsapp ? "1" : "0";
    enableWebsite = enableWebsite ? "1" : "0";
    enablePrivacyPolicy = enablePrivacyPolicy ? "1" : "0";
    enableScanCookieSkipCount = enableScanCookieSkipCount ? "1" : "0";
    enableCustomSuspendMessage = enableCustomSuspendMessage ? "1" : "0";

    return accountApi.companySetting.put({
      counterfeitReportEmail,
      contactNumber,
      contactCode,
      contactEmail,
      defaultLanguage,
      facebookUrl,
      whatsappUrl,
      youtubeUrl,
      websiteUrl,
      enableCounterfeitReport,
      enableConsumerLogin,
      isLoginRequired,
      enableLocationTracking,
      enableContactNumber,
      enableContactEmail,
      enableFacebook,
      enableWhatsapp,
      enableYoutube,
      enableWebsite,
      uiSetting,
      enablePrivacyPolicy,
      privacyPolicyTitle,
      privacyPolicyMessage,
      privacyPolicyUrl,
      disagreeCheck,
      disagreeUrl,
      isPrivacyPolicyMessageModified,
      enableScanCookieSkipCount,
      scanCookieRetainHour,
      enableCustomSuspendMessage,
      customSuspendMessageValues,
      defaultDisplayingCompanyProfile,
    }).then((response) => response)
      .catch((error) => thunkApi.rejectWithValue(error));
  }
);
