import React from "react";

export const ArrowImg = () => (
  <svg
    width="6"
    height="12"
    viewBox="0 0 6 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L5 6L1 11"
      stroke="#9CA6A9"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LockImg = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6061 5.83758V3.60606C10.6061 1.61636 8.9897 0 7 0C5.01031 0 3.39394 1.61636 3.39394 3.60606V5.83758C2.60485 6.70303 2.12122 7.85697 2.12122 9.12121C2.12122 11.8109 4.31031 14 7 14C9.6897 14 11.8788 11.8109 11.8788 9.12121C11.8788 7.85697 11.3952 6.70727 10.6061 5.83758ZM7.86122 9.40545V10.5509C7.86122 11.0261 7.47516 11.4121 7 11.4121C6.52485 11.4121 6.13879 11.0261 6.13879 10.5509V9.40545C5.79091 9.14242 5.56606 8.72667 5.56606 8.26C5.56606 7.47091 6.20667 6.82606 7 6.82606C7.79334 6.82606 8.43394 7.46667 8.43394 8.26C8.43394 8.73091 8.20485 9.14667 7.86122 9.40545ZM9.33334 4.83636C8.64182 4.45879 7.84425 4.24242 7 4.24242C6.15576 4.24242 5.35819 4.45879 4.66667 4.83636V3.60606C4.66667 2.32061 5.71455 1.27273 7 1.27273C8.28546 1.27273 9.33334 2.32061 9.33334 3.60606V4.83636Z"
      fill="#3A4D54"
    />
  </svg>
);

export const PDFImg = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="20" fill="#0095E1" />
    <path
      d="M10.0332 21.4932V25H7.98242V15.0469H11.8652C12.6126 15.0469 13.2689 15.1836 13.834 15.457C14.4036 15.7305 14.8411 16.1201 15.1465 16.626C15.4518 17.1273 15.6045 17.6992 15.6045 18.3418C15.6045 19.3171 15.2695 20.0872 14.5996 20.6523C13.9342 21.2129 13.0114 21.4932 11.8311 21.4932H10.0332ZM10.0332 19.832H11.8652C12.4076 19.832 12.82 19.7044 13.1025 19.4492C13.3896 19.194 13.5332 18.8294 13.5332 18.3555C13.5332 17.8678 13.3896 17.4736 13.1025 17.1729C12.8154 16.8721 12.4189 16.7171 11.9131 16.708H10.0332V19.832ZM17.0195 25V15.0469H20.082C20.957 15.0469 21.7386 15.2451 22.4268 15.6416C23.1195 16.0335 23.6595 16.5941 24.0469 17.3232C24.4342 18.0479 24.6279 18.8727 24.6279 19.7979V20.2559C24.6279 21.181 24.4365 22.0036 24.0537 22.7236C23.6755 23.4437 23.14 24.002 22.4473 24.3984C21.7546 24.7949 20.973 24.9954 20.1025 25H17.0195ZM19.0703 16.708V23.3525H20.0615C20.8636 23.3525 21.4766 23.0905 21.9004 22.5664C22.3242 22.0423 22.5407 21.2926 22.5498 20.3174V19.791C22.5498 18.7793 22.3402 18.0137 21.9209 17.4941C21.5016 16.9701 20.8887 16.708 20.082 16.708H19.0703ZM32.1133 20.9326H28.1758V25H26.125V15.0469H32.6055V16.708H28.1758V19.2783H32.1133V20.9326Z"
      fill="white"
    />
  </svg>
);

export const CameraImg = () => (
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 6.97266C9.4999 6.97266 6.65234 9.82021 6.65234 13.3203C6.65234 16.8204 9.4999 19.668 13 19.668C16.5001 19.668 19.3477 16.8204 19.3477 13.3203C19.3477 9.82021 16.5001 6.97266 13 6.97266ZM13 17.6367C10.6199 17.6367 8.68359 15.7004 8.68359 13.3203C8.68359 10.9402 10.6199 9.00391 13 9.00391C15.3801 9.00391 17.3164 10.9402 17.3164 13.3203C17.3164 15.7004 15.3801 17.6367 13 17.6367Z"
      fill="white"
    />
    <path
      d="M22.9531 3.92578H19.2169C19.0986 3.92578 18.992 3.86124 18.9372 3.75683L17.9035 1.58477C17.9007 1.57882 17.8979 1.57293 17.8949 1.56704C17.4947 0.76673 16.6903 0.269531 15.7955 0.269531H10.2769C9.38209 0.269531 8.57761 0.76673 8.17751 1.56709C8.17451 1.57298 8.17167 1.57888 8.16882 1.58482L7.13512 3.75698C7.08028 3.86124 6.97374 3.92578 6.85547 3.92578H3.04688C1.36683 3.92578 0 5.29261 0 6.97266V20.6836C0 22.3636 1.36683 23.7305 3.04688 23.7305H22.9531C24.6332 23.7305 26 22.3636 26 20.6836V6.97266C26 5.29261 24.6332 3.92578 22.9531 3.92578ZM23.9688 20.6835C23.9688 21.2436 23.5131 21.6992 22.9531 21.6992H3.04688C2.48686 21.6992 2.03125 21.2436 2.03125 20.6835V6.97266C2.03125 6.41264 2.48686 5.95703 3.04688 5.95703H6.85547C7.75023 5.95703 8.55471 5.45988 8.95487 4.65957C8.95786 4.65368 8.96071 4.64774 8.9636 4.6418L9.9973 2.46963C10.0521 2.36532 10.1586 2.30078 10.2769 2.30078H15.7955C15.9137 2.30078 16.0203 2.36532 16.0751 2.46958L17.1088 4.64175C17.1117 4.64769 17.1145 4.65363 17.1175 4.65952C17.5177 5.45983 18.3221 5.95698 19.2169 5.95698H22.9531C23.5131 5.95698 23.9688 6.41259 23.9688 6.97261V20.6835Z"
      fill="white"
    />
    <path d="M21.9375 7.98828H19.9062V10.0195H21.9375V7.98828Z" fill="white" />
  </svg>
);

export const UploadImg = () => (
  <svg
    width="44"
    height="30"
    viewBox="0 0 44 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.5131 29.2602H19.0657C18.0634 29.2602 17.2499 28.4745 17.2499 27.5102V18.7602H12.7105C12.3437 18.7602 12.0114 18.5467 11.8716 18.2195C11.7318 17.8922 11.8098 17.516 12.0677 17.2657L21.1466 8.5157C21.5007 8.17445 22.0763 8.17445 22.4304 8.5157L31.5093 17.2657C31.769 17.516 31.8471 17.8922 31.7055 18.2195C31.5638 18.5467 31.2352 18.7602 30.8684 18.7602H26.3289V27.5102C26.3289 28.4745 25.5154 29.2602 24.5131 29.2602Z"
      fill="white"
    />
    <path
      d="M8.17287 27.9475C8.13837 27.9475 8.10568 27.9458 8.06937 27.9422C3.46997 27.4505 0 23.7125 0 19.25C0 14.6265 3.73871 10.8308 8.45432 10.521C9.71084 4.473 15.3126 0 21.7895 0C26.8755 0 31.5602 2.7615 33.8699 7.077C39.3572 7.63175 43.5789 12.1135 43.5789 17.5C43.5789 22.19 40.3178 26.3375 35.6512 27.5835C35.1646 27.7113 34.6671 27.4383 34.5345 26.9728C34.4001 26.5073 34.6834 26.0243 35.1664 25.8965C39.0504 24.8605 41.7632 21.406 41.7632 17.5C41.7632 12.8678 38.0172 9.03875 33.2344 8.78325C32.8985 8.76575 32.6025 8.5715 32.4609 8.27925C30.5398 4.31375 26.3526 1.75 21.7895 1.75C15.9517 1.75 10.9365 5.94125 10.123 11.4975C10.0595 11.928 9.67634 12.25 9.22421 12.25C5.07332 12.25 1.81579 15.3895 1.81579 19.25C1.81579 22.82 4.59032 25.809 8.27274 26.2028C8.77026 26.257 9.12979 26.6893 9.0735 27.1688C9.02266 27.6168 8.62863 27.9475 8.17287 27.9475Z"
      fill="white"
    />
  </svg>
);

export const LightGreenUploadImg = () => (
  <svg
    width="44"
    height="30"
    viewBox="0 0 44 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.5131 29.2602H19.0657C18.0634 29.2602 17.2499 28.4745 17.2499 27.5102V18.7602H12.7105C12.3437 18.7602 12.0114 18.5467 11.8716 18.2195C11.7318 17.8922 11.8098 17.516 12.0677 17.2657L21.1466 8.5157C21.5007 8.17445 22.0763 8.17445 22.4304 8.5157L31.5093 17.2657C31.769 17.516 31.8471 17.8922 31.7055 18.2195C31.5638 18.5467 31.2352 18.7602 30.8684 18.7602H26.3289V27.5102C26.3289 28.4745 25.5154 29.2602 24.5131 29.2602Z"
      fill="#92C892"
    />
    <path
      d="M8.17287 27.9475C8.13837 27.9475 8.10568 27.9458 8.06937 27.9422C3.46997 27.4505 0 23.7125 0 19.25C0 14.6265 3.73871 10.8308 8.45432 10.521C9.71084 4.473 15.3126 0 21.7895 0C26.8755 0 31.5602 2.7615 33.8699 7.077C39.3572 7.63175 43.5789 12.1135 43.5789 17.5C43.5789 22.19 40.3178 26.3375 35.6512 27.5835C35.1646 27.7113 34.6671 27.4383 34.5345 26.9728C34.4001 26.5073 34.6834 26.0243 35.1664 25.8965C39.0504 24.8605 41.7632 21.406 41.7632 17.5C41.7632 12.8678 38.0172 9.03875 33.2344 8.78325C32.8985 8.76575 32.6025 8.5715 32.4609 8.27925C30.5398 4.31375 26.3526 1.75 21.7895 1.75C15.9517 1.75 10.9365 5.94125 10.123 11.4975C10.0595 11.928 9.67634 12.25 9.22421 12.25C5.07332 12.25 1.81579 15.3895 1.81579 19.25C1.81579 22.82 4.59032 25.809 8.27274 26.2028C8.77026 26.257 9.12979 26.6893 9.0735 27.1688C9.02266 27.6168 8.62863 27.9475 8.17287 27.9475Z"
      fill="#B4B4B4"
    />
  </svg>
);

export const TimeImg = () => (
  <svg
    width="28"
    height="12"
    viewBox="0 0 28 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.98578 7.11035H7.9975V8.8877H6.98578V11H4.68207V8.8877H0.833435L0.696716 7.47949L4.68207 1.06738V1.04688H6.98578V7.11035ZM2.89105 7.11035H4.68207V4.04785L4.53851 4.28027L2.89105 7.11035Z"
      fill="#9CA6A9"
    />
    <path
      d="M10.1989 8.76465C10.5635 8.76465 10.8666 8.87402 11.1081 9.09277C11.3497 9.30697 11.4704 9.58268 11.4704 9.91992C11.4704 10.2572 11.3497 10.5352 11.1081 10.7539C10.8666 10.9681 10.5635 11.0752 10.1989 11.0752C9.8298 11.0752 9.52446 10.9658 9.28293 10.7471C9.04595 10.5283 8.92746 10.2526 8.92746 9.91992C8.92746 9.58724 9.04595 9.31152 9.28293 9.09277C9.52446 8.87402 9.8298 8.76465 10.1989 8.76465ZM10.1989 3.30957C10.5635 3.30957 10.8666 3.41895 11.1081 3.6377C11.3497 3.85189 11.4704 4.1276 11.4704 4.46484C11.4704 4.80208 11.3497 5.08008 11.1081 5.29883C10.8666 5.51302 10.5635 5.62012 10.1989 5.62012C9.8298 5.62012 9.52446 5.51074 9.28293 5.29199C9.04595 5.07324 8.92746 4.79753 8.92746 4.46484C8.92746 4.13216 9.04595 3.85645 9.28293 3.6377C9.52446 3.41895 9.8298 3.30957 10.1989 3.30957Z"
      fill="#9CA6A9"
    />
    <path
      d="M19.5713 6.93262C19.5713 8.27702 19.2682 9.3138 18.6621 10.043C18.0605 10.7721 17.2106 11.1367 16.1123 11.1367C15.0049 11.1367 14.1481 10.7699 13.542 10.0361C12.9359 9.30241 12.6328 8.2679 12.6328 6.93262V5.11426C12.6328 3.76986 12.9336 2.73307 13.5352 2.00391C14.1413 1.27474 14.9958 0.910156 16.0986 0.910156C17.2015 0.910156 18.056 1.27702 18.6621 2.01074C19.2682 2.74447 19.5713 3.78125 19.5713 5.12109V6.93262ZM17.2676 4.82031C17.2676 4.10482 17.1742 3.57161 16.9873 3.2207C16.8005 2.86523 16.5042 2.6875 16.0986 2.6875C15.7021 2.6875 15.4128 2.85156 15.2305 3.17969C15.0527 3.50781 14.957 4.00456 14.9434 4.66992V7.21289C14.9434 7.95573 15.0368 8.50033 15.2236 8.84668C15.4105 9.18848 15.7067 9.35938 16.1123 9.35938C16.5042 9.35938 16.7936 9.19303 16.9805 8.86035C17.1673 8.52311 17.263 7.99674 17.2676 7.28125V4.82031Z"
      fill="#9CA6A9"
    />
    <path
      d="M20.6243 6.13281L21.2327 1.04688H27.0501V2.83789H23.1057L22.8802 4.81348C23.0442 4.71777 23.2584 4.63346 23.5227 4.56055C23.7916 4.48763 24.0537 4.45117 24.3089 4.45117C25.2978 4.45117 26.0566 4.74512 26.5852 5.33301C27.1184 5.91634 27.385 6.73665 27.385 7.79395C27.385 8.43197 27.2415 9.01074 26.9544 9.53027C26.6718 10.0452 26.2731 10.4417 25.7581 10.7197C25.2431 10.9977 24.6347 11.1367 23.9329 11.1367C23.3085 11.1367 22.7229 11.0091 22.1761 10.7539C21.6292 10.4941 21.2008 10.141 20.8909 9.69434C20.581 9.24316 20.4283 8.73503 20.4329 8.16992H22.7434C22.7662 8.53451 22.8824 8.82389 23.0921 9.03809C23.3017 9.25228 23.5774 9.35938 23.9192 9.35938C24.694 9.35938 25.0813 8.78516 25.0813 7.63672C25.0813 6.57487 24.6074 6.04395 23.6595 6.04395C23.1217 6.04395 22.7206 6.21712 22.4563 6.56348L20.6243 6.13281Z"
      fill="#9CA6A9"
    />
  </svg>
);

export const BatteryImg = () => (
  <svg
    width="67"
    height="12"
    viewBox="0 0 67 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.6667 1.33301H45C44.0796 1.33301 43.3334 2.0792 43.3334 2.99967V8.99967C43.3334 9.92015 44.0796 10.6663 45 10.6663H61.6667C62.5872 10.6663 63.3334 9.92015 63.3334 8.99968V2.99967C63.3334 2.0792 62.5872 1.33301 61.6667 1.33301ZM45 0.333008C43.5273 0.333008 42.3334 1.52691 42.3334 2.99967V8.99967C42.3334 10.4724 43.5273 11.6663 45 11.6663H61.6667C63.1395 11.6663 64.3334 10.4724 64.3334 8.99968V2.99967C64.3334 1.52691 63.1395 0.333008 61.6667 0.333008H45Z"
      fill="#9CA6A9"
    />
    <path
      d="M65.3334 3.99951V7.99951C66.1381 7.66074 66.6614 6.87264 66.6614 5.99951C66.6614 5.12638 66.1381 4.33829 65.3334 3.99951Z"
      fill="#9CA6A9"
    />
    <path
      d="M44.3334 3.66634C44.3334 2.92996 44.9303 2.33301 45.6667 2.33301H61C61.7364 2.33301 62.3334 2.92996 62.3334 3.66634V8.33301C62.3334 9.06939 61.7364 9.66634 61 9.66634H45.6667C44.9303 9.66634 44.3334 9.06939 44.3334 8.33301V3.66634Z"
      fill="#9CA6A9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.6637 2.60789C31.8796 2.60799 34.0108 3.45943 35.6167 4.98623C35.7376 5.1041 35.9309 5.10261 36.05 4.98289L37.206 3.81623C37.2663 3.7555 37.3 3.67325 37.2995 3.58767C37.299 3.50209 37.2644 3.42024 37.2034 3.36023C32.9883 -0.67932 26.3385 -0.67932 22.1234 3.36023C22.0623 3.4202 22.0277 3.50202 22.0271 3.5876C22.0265 3.67318 22.0601 3.75546 22.1204 3.81623L23.2767 4.98289C23.3957 5.1028 23.5892 5.10428 23.71 4.98623C25.3162 3.45933 27.4476 2.60789 29.6637 2.60789ZM29.6637 6.40356C30.8812 6.40349 32.0552 6.85602 32.9577 7.67323C33.0798 7.78921 33.272 7.78669 33.391 7.66756L34.5457 6.50089C34.6065 6.4397 34.6403 6.35668 34.6394 6.27042C34.6385 6.18415 34.6031 6.10184 34.541 6.04189C31.7928 3.48551 27.5369 3.48551 24.7887 6.04189C24.7266 6.10184 24.6912 6.18419 24.6904 6.27049C24.6896 6.35678 24.7234 6.43979 24.7844 6.50089L25.9387 7.66756C26.0577 7.78669 26.25 7.78921 26.372 7.67323C27.2739 6.85656 28.447 6.40406 29.6637 6.40356ZM31.9767 8.95738C31.9785 9.04389 31.9445 9.12729 31.8827 9.18789L29.8854 11.2036C29.8268 11.2628 29.747 11.2961 29.6637 11.2961C29.5804 11.2961 29.5006 11.2628 29.442 11.2036L27.4444 9.18789C27.3827 9.12724 27.3487 9.04381 27.3505 8.95731C27.3524 8.8708 27.3898 8.78888 27.454 8.73089C28.7296 7.65201 30.5978 7.65201 31.8734 8.73089C31.9375 8.78893 31.9749 8.87087 31.9767 8.95738Z"
      fill="#9CA6A9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0.666992H15C14.4477 0.666992 14 1.11471 14 1.66699V10.3337C14 10.8859 14.4477 11.3337 15 11.3337H16C16.5523 11.3337 17 10.8859 17 10.3337V1.66699C17 1.11471 16.5523 0.666992 16 0.666992ZM10.3333 3.00033H11.3333C11.8856 3.00033 12.3333 3.44804 12.3333 4.00033V10.3337C12.3333 10.8859 11.8856 11.3337 11.3333 11.3337H10.3333C9.78105 11.3337 9.33333 10.8859 9.33333 10.3337V4.00033C9.33333 3.44804 9.78105 3.00033 10.3333 3.00033ZM6.66667 5.33366H5.66667C5.11438 5.33366 4.66667 5.78137 4.66667 6.33366V10.3337C4.66667 10.8859 5.11438 11.3337 5.66667 11.3337H6.66667C7.21895 11.3337 7.66667 10.8859 7.66667 10.3337V6.33366C7.66667 5.78137 7.21895 5.33366 6.66667 5.33366ZM2 7.33366H1C0.447715 7.33366 0 7.78137 0 8.33366V10.3337C0 10.8859 0.447715 11.3337 1 11.3337H2C2.55228 11.3337 3 10.8859 3 10.3337V8.33366C3 7.78137 2.55228 7.33366 2 7.33366Z"
      fill="#9CA6A9"
    />
  </svg>
);

export const EditImg = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5455 15.2728C14.5455 14.8711 14.2199 14.5455 13.8182 14.5455H0.727278C0.325613 14.5455 0 14.8711 0 15.2728C0 15.6745 0.325614 16.0001 0.727278 16.0001H13.8182C14.2199 16.0001 14.5455 15.6745 14.5455 15.2728Z"
      fill="#3A4D54"
    />
    <path
      d="M11.1362 0.707106C10.7457 0.316582 10.1126 0.316582 9.72204 0.707107L1.74749 8.68165C1.55996 8.86918 1.4546 9.12354 1.4546 9.38875V12.0909C1.4546 12.6432 1.90232 13.0909 2.4546 13.0909H5.15677C5.42198 13.0909 5.67634 12.9856 5.86387 12.798L13.8384 4.82348C14.2289 4.43296 14.2289 3.7998 13.8384 3.40927L11.1362 0.707106ZM5.26023 11.3435C5.07269 11.531 4.81833 11.6364 4.55312 11.6364H3.90916C3.35688 11.6364 2.90916 11.1886 2.90916 10.6364V9.9924C2.90916 9.72718 3.01452 9.47283 3.20205 9.28529L9.72204 2.76528C10.1126 2.37476 10.7457 2.37476 11.1363 2.76528L11.7802 3.40924C12.1707 3.79976 12.1707 4.43293 11.7802 4.82345L5.26023 11.3435Z"
      fill="#3A4D54"
    />
  </svg>
);
