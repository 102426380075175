import getScanMap from "./getScanMap.api";
import getClusterScanMap from "./getClusterScanMap.api";
import getClusterScanProductListing from "./getClusterScanProductListing.api";
import getClusterScanRecordListing from "./getClusterScanRecordListing.api";
import getClusterScanConsumerDropdown from "./getClusterScanConsumerDropdown.api";
import getClusterConsumerScannedSummary from "./getClusterConsumerScannedSummary.api";
import getClusterUniqueConsumerScannedSummary from "./getClusterUniqueConsumerScannedSummary.api";
import getClusterFirstScanSummary from "./getClusterFirstScanSummary.api";
import getClusterSuspendSnSummary from "./getClusterSuspendSnSummary.api";
import getClusterCounterfeitReportSummary from "./getClusterCounterfeitReportSummary.api";
import getScanDetail from "./getScanDetail.api";
import getConsumerScannedSummary from "./getConsumerScannedSummary.api";
import getSuspendSnSummary from "./getSuspendSnSummary.api";
import getCounterfeitReportSummary from "./getCounterfeitReportSummary.api";
import getFirstScanSummary from "./getFirstScanSummary.api";
import getUniqueConsumerScannedSummary from "./getUniqueConsumerScannedSummary.api";
import getConsumerRegisteredSummary from "./getConsumerRegisteredSummary.api";
import getScanAgainstSuspendedData from "./getScanAgainstSuspendedData.api";
import getTopScanCities from "./getTopScanCities.api";
import getTopScanProduct from "./getTopScanProduct.api";
import getSerialNumberFirstScanList from "./getSerialNumberFirstScanList.api";
import getNewConsumerRegisteredList from "./getNewConsumerRegisteredList.api";
import getUniqueConsumerScannedList from "./getUniqueConsumerScannedList.api";
import getTotalRegistration from "./scgp/getTotalRegistration.api";
import getCumulativeRegistration from "./scgp/getCumulativeRegistration.api";
import getCumulativeRegistrationByRoleChart from "./scgp/getCumulativeRegistrationByRoleChart.api";
import getCumulativeRegistrationByIndustryChart from "./scgp/getCumulativeRegistrationByIndustryChart.api";
import getTotalScannedSolutionHighlight from "./scgp/getTotalScannedSolutionHighlight.api";
import getSolutionHighlightRankedScanCount from "./scgp/getSolutionHighlightRankedScanCount.api";
import getCumulativeSolutionHighlightScanChart from "./scgp/getCumulativeSolutionHighlightScanChart.api";
import getConnectedPackagingFeature from "./scgp/getConnectedPackagingFeature.api";
import getTotalMockupScan from "./scgp/getTotalMockupScan.api";
import getAntiCounterfeitTotalScan from "./scgp/getAntiCounterfeitTotalScan.api";
import getTotalValidScan from "./scgp/getTotalValidScan.api";
import getTotalSuspendedScan from "./scgp/getTotalSuspendedScan.api";
import getTotalWarrantyScan from "./scgp/getTotalWarrantyScan.api";
import getTotalLoyaltyPointsScan from "./scgp/getTotalLoyaltyPointsScan.api";
import getTotalLuckyDrawScan from "./scgp/getTotalLuckyDrawScan.api";
import getCumulativePackagingFeatureScan from "./scgp/getCumulativePackagingFeatureScan.api";
import getTotalSurveyReceived from "./scgp/getTotalSurveyReceived.api";
import getMostInterestedZone from "./scgp/getMostInterestedZone.api";
import getMainObjectivePackagingDesign from "./scgp/getMainObjectivePackagingDesign.api";
import getExpectationsConnectedPackaging from "./scgp/getExpectationsConnectedPackaging.api";
import getInterestedAdoptingConnectedPackaging from "./scgp/getInterestedAdoptingConnectedPackaging.api";
import getMostInterestedPackagingFeature from "./scgp/getMostInterestedPackagingFeature.api";
import getIisVistingSatisfaction from "./scgp/getIisVistingSatisfaction.api";
import getIndustryRanked from "./scgp/getIndustryRanked.api";
import getRoleRanked from "./scgp/getRoleRanked.api";

const dashboardApi = {
  getScanMap,
  getClusterScanMap,
  getClusterScanProductListing,
  getScanDetail,
  getClusterScanRecordListing,
  getClusterScanConsumerDropdown,
  getClusterConsumerScannedSummary,
  getClusterUniqueConsumerScannedSummary,
  getClusterFirstScanSummary,
  getClusterSuspendSnSummary,
  getClusterCounterfeitReportSummary,
  getConsumerScannedSummary,
  getSuspendSnSummary,
  getCounterfeitReportSummary,
  getFirstScanSummary,
  getUniqueConsumerScannedSummary,
  getConsumerRegisteredSummary,
  getScanAgainstSuspendedData,
  getIndustryRanked,
  getRoleRanked,
  getTopScanCities,
  getTopScanProduct,
  getSerialNumberFirstScanList,
  getNewConsumerRegisteredList,
  getUniqueConsumerScannedList,
  getMostInterestedPackagingFeature,
  getIisVistingSatisfaction,
  getTotalRegistration,
  getCumulativeRegistration,
  getCumulativeRegistrationByRoleChart,
  getCumulativeRegistrationByIndustryChart,
  getTotalScannedSolutionHighlight,
  getSolutionHighlightRankedScanCount,
  getCumulativeSolutionHighlightScanChart,
  getConnectedPackagingFeature,
  getMostInterestedZone,
  getMainObjectivePackagingDesign,
  getExpectationsConnectedPackaging,
  getInterestedAdoptingConnectedPackaging,
  getTotalMockupScan,
  getAntiCounterfeitTotalScan,
  getTotalValidScan,
  getTotalSuspendedScan,
  getTotalWarrantyScan,
  getTotalLoyaltyPointsScan,
  getTotalLuckyDrawScan,
  getCumulativePackagingFeatureScan,
  getTotalSurveyReceived,
};

export default dashboardApi;
